// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';

// other
import TextEditor from 'components/BTDUI/fields/BTDTextEditor';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

const EmailTemplateForm = props => {

    const { errors, onClose, onSubmit, item, } = props;

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    const submit = () => {

		onSubmit(changedData);
	}

    // Load item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />
            
            <BTDTextField
                error={errors && errors.subject ? errors.subject : null} 
                label='Subject'
                onChange={(value) => {
                    setData({...data, subject: value});
                    setChangedData({...changedData, subject: value});
                }} 
                required={true}
                value={data.subject ?? ''} 
            />

            <TextEditor
                error={errors ? errors.body : null}
                label='Content'
                onChange={(e) => {
                    setData({...data, body: e});
                    setChangedData({...changedData, body: e});
                }}
                value={data.body ?? ''}
            />

            <BTDButton
                formButton={true}
                label='Save'
                onClick={submit} 
            ></BTDButton>

        </FormTemplate>

    )

}

EmailTemplateForm.propTypes = {
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

EmailTemplateForm.defaultProps = {
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    item: {},
}

export default EmailTemplateForm;