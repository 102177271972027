// Packages
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

const OrderViewLayout = ({ children, order, isLoading }) => {

    const { orderNumber } = useAppData();

    return (
		<>
      <TitleBar isLoading={isLoading} title={`${orderNumber(order.number)} - ${order.contact ? order.contact.firstname + ' ' + order.contact.lastname : ''} `}>

        {isLoading ? <BTDSkeleton style={{width: '150px'}}/> : dayjs(order.created_at).format('D MMM YYYY HH:mm')}

      </TitleBar>

      <Container>
        {children}
      </Container>
    </>
	)

}

OrderViewLayout.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  order: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

OrderViewLayout.defaultProps = {
  children: null,
  isLoading: false,
  order: {}, 
}

export default OrderViewLayout;