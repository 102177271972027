// Packages
import PropTypes from 'prop-types'

// Components
import EmailTemplateForm from 'components/EmailTemplate/Form';

// Other
import useApi from 'hooks/useApi';

const CreateEmailTemplateScreen = props => {

    const { onClose, onSubmit } = props;

    const { post: createItem, errors } = useApi('/emailtemplates', false);

	const submit = async (values) => {

        if( await createItem(values, 'Added email template!')) {

            onSubmit();
        }
    }

    return(
        <EmailTemplateForm errors={errors} onClose={onClose} onSubmit={submit} />
    )
}

CreateEmailTemplateScreen.propTypes = {
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

CreateEmailTemplateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
}

export default CreateEmailTemplateScreen;