// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import ImageUpload from 'components/BTDUI/fields/BTDImageUpload';
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';

const AccommodationForm = ({ errors, accommodation, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    const submit = () => {

		onSubmit(changedData);
	}

    // Load Accommodation values into form
	useEffect(() => {

		if ( accommodation )
		{
            setData(accommodation);
		}

	}, [accommodation]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create accommodation' : 'Edit accommodation'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Accommodation name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextareaField
                error={errors ? errors.address : null} 
                label="Address" 
                onChange={(value) => {
                    setData({...data, address: value});
                    setChangedData({...changedData, address: value});
                }} 
                value={data.address ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.website ? errors.website : null} 
                label='Website address'
                onChange={(value) => {
                    setData({...data, website: value});
                    setChangedData({...changedData, website: value});
                }} 
                value={data.website ?? ''} 
            />

            <ImageUpload
                image_max_height={800} 
                image_max_width={600} 
                image_quality={0.8} 
                image_url={data && data.image_upload ? data.image_upload.url : null} 
                label='Photo'
                onChange={(id) => {
                    setData({...data, image_upload_id: id});
                    setChangedData({...changedData, image_upload_id: id});
                }} 
            />   

            <BTDTextField 
                error={errors && errors.distance_from_venue ? errors.distance_from_venue : null} 
                label='Distance from venue (miles)'
                onChange={(value) => {
                    setData({...data, distance_from_venue: value});
                    setChangedData({...changedData, distance_from_venue: value});
                }} 
                value={data.distance_from_venue ?? ''} 
            />

            <BTDTextareaField
                error={errors ? errors.notes : null} 
                label="Notes" 
                onChange={(value) => {
                    setData({...data, notes: value});
                    setChangedData({...changedData, notes: value});
                }} 
                value={data.notes ?? ''} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save accommodation' : 'Update accommodation'}
                onClick={submit} 
            ></BTDButton>

        </FormTemplate>
    )
}

AccommodationForm.propTypes = {
	errors: PropTypes.object,
	accommodation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

AccommodationForm.defaultProps = {
    errors: {},
    accommodation: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default AccommodationForm;