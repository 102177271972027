// Packages
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from 'prop-types'

// other
import './styles.css';
import { Link } from "react-router-dom";

const SearchResult = props => {

    const { date, name, object_id, object_type, onClose } = props;

    const [url, setUrl] = useState('');
    const [bgColour, setBgColour] = useState('');
    const [buttonColour, setButtonColour] = useState('');

    useEffect(() => {

        if( object_type && object_id ) {

            switch (object_type) {
                case 'event':
                    setUrl('/events/' + object_id);
                    setBgColour('#EAEBF9');
                    setButtonColour('#666FD6');
                    break;
                case 'order':
                    setUrl('/orders/' + object_id);
                    setBgColour('#F4E3F0');
                    setButtonColour('#AE3591');
                    break;
            
                default:
                    setUrl('');
                    setBgColour('#F6F8FB');
                    setButtonColour('#ddd');
                    break;
            }
        }

    }, [object_type, object_id])


    return(
        <Link 
            className='SearchResult' 
            onClick={() => {onClose()}}
            to={url ?? ''} 
            style={{backgroundColor: bgColour}}
        >

            <div className='SearchResult__text'>

                <p className="SearchResult__text__name bold black">{name ?? ''}</p>

                <p className="SearchResult__text__date medium black">{date ? dayjs(date).format('D MMM YYYY') : ''}</p>

            </div>

            <div className='SearchResult__button bold white' style={{backgroundColor: buttonColour}}>{object_type ?? ''}</div>

        </Link>
    )
}

SearchResult.propTypes = {
    date: PropTypes.string,
    name: PropTypes.string,
    object_id: PropTypes.number,
    object_type: PropTypes.string,
    onClose: PropTypes.func
}

SearchResult.defaultProps = {
    date: '',
    name: '',
    object_id: null,
    object_type: '',
    onClose: () => {alert('onCose is not set!')}
}

export default SearchResult;