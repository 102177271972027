// Packages
import PropTypes from 'prop-types';

// Component
import AccommodationForm from 'components/Accommodation/Form';

// Other
import useApi from 'hooks/useApi';

const EditAccommodationScreen = ({ eventId, onClose, onSubmit, accommodationId }) => {

    const { data: accommodation, put: updateAccommodation, errors } = useApi('/events/' + eventId + '/accommodation/' + accommodationId);

    const submit = async (values) => {

        if( await updateAccommodation(values, 'Accommodation updated!')){

            onSubmit();
        }
    }

    return(
        <AccommodationForm errors={errors} accommodation={accommodation} onClose={onClose} onSubmit={submit} type='edit' />
    )
}

EditAccommodationScreen.propTypes = {
    accommodationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

EditAccommodationScreen.defaultProps = {
    accommodationId: null,
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
}

export default EditAccommodationScreen;