// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css';

const ContactListItem = ({ created_at, city, email, external_id, firstname, id, isLoading, lastname, postcode, telephone }) => {

	const { datetime } = useAppData();

	return (
		<div className='ContactListItem'>

			<div className='ContactListItem__value'>
				<Link className='semi-bold light-blue' to={'/contacts/' + id}>{isLoading ? <BTDSkeleton /> : firstname + ' ' + lastname}</Link>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : datetime(created_at)}</p>
			</div>
			<div className='ContactListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : city}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : postcode}</p>
			</div>
			<div className='ContactListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : telephone}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : email}</p>
			</div>
			<div className='ContactListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : external_id}</p>
			</div>

		</div>
	)
}

ContactListItem.propTypes = {
	created_at: PropTypes.string,
	contact: PropTypes.object,
	event: PropTypes.object,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	reference: PropTypes.string,
	status: PropTypes.string,
	total: PropTypes.string,
}

ContactListItem.defaultProps = {
	created_at: '',
	contact: {},
	event: {},
	id: null,
	isLoading: true,
	reference: '',
	status: '',
	total: ''
}

export default ContactListItem;