// Packages
import { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';
import EventEditScreen from 'screens/Events/Edit';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

const EventsViewLayout = ({ activeTab, children, event, isLoading, onSubmit }) => {

    const [formActive, setFormActive] = useState(false);

      let tabs = []
      let menuItems = []
      
      if ( event )
      {
      
          tabs = [
            {label: 'Overview', url: `/events/${event.id}`},
            {label: 'Tickets', url: `/events/${event.id}/tickets`},
            {label: 'Attendees', url: `/events/${event.id}/attendees`},
            {label: 'Microsite', url: `/events/${event.id}/site`},
          ];

          if(activeTab === `/events/${event.id}/waiting`) {
            tabs.push({label: 'Waiting List', url: `/events/${event.id}/waiting`});
          }
          if(activeTab === `/events/${event.id}/accommodation`) {
            tabs.push({label: 'Accommodation', url: `/events/${event.id}/accommodation`});
          }
          if(activeTab === `/events/${event.id}/speakers`) {
            tabs.push({label: 'Speakers', url: `/events/${event.id}/speakers`});
          }
          if(activeTab === `/events/${event.id}/settings`) {
            tabs.push({label: 'Settings', url: `/events/${event.id}/settings`});
          }


          if(activeTab !== `/events/${event.id}/waiting`) {
            menuItems.push({label: 'Waiting List', url: `/events/${event.id}/waiting`});
          }
          if(activeTab !== `/events/${event.id}/accommodation`) {
            menuItems.push({label: 'Accommodation', url: `/events/${event.id}/accommodation`});
          }
          if(activeTab !== `/events/${event.id}/speakers`) {
            menuItems.push({label: 'Speakers', url: `/events/${event.id}/speakers`});
          }
          if(activeTab !== `/events/${event.id}/settings`) {
            menuItems.push({label: 'Settings', url: `/events/${event.id}/settings`});
          }

      }

      let currentTab;

      if( event && activeTab ) {

        switch (activeTab) {
          case `/events/${event.id}`:
            currentTab = 'Overview';
            break;
          case `/events/${event.id}/tickets`:
            currentTab = 'Tickets';
            break;
          case `/events/${event.id}/site`:
            currentTab = 'Microsite';
            break;
          case `/events/${event.id}/waiting`:
            currentTab = 'Waiting List';
            break;
          case `/events/${event.id}/accommodation`:
            currentTab = 'Accommodation';
            break;
          case `/events/${event.id}/speakers`:
            currentTab = 'Speakers';
            break;
          case `/events/${event.id}/attendees`:
            currentTab = 'Attendees';
            break;
          case `/events/${event.id}/settings`:
            currentTab = 'Settings';
            break;
        
          default:
            currentTab = 'Summary'
            break;
        }
      }

    
    return (
		<>
      <TitleBar title={event.name} menuItems={menuItems} isLoading={isLoading} tabs={tabs} activeTab={currentTab} breadcrumb={'Events >'}>

        { isLoading ? <BTDSkeleton style={{width: '150px'}}/> : dayjs(event.start_at).format('ddd D MMMM YYYY') }

        <BTDButton
          onClick={() => {setFormActive(true)}} 
          type='rounded'
          label='Edit Event'
        />

      </TitleBar>

      <Container>
        {children}
      </Container>

      {formActive && 
        <EventEditScreen 
          eventId={event.id} 
          onClose={() => {setFormActive(false)}} 
          onSubmit={() => {
            setFormActive(false);
            onSubmit();
          }}
        />
      }
    </>
	)

}

EventsViewLayout.propTypes = {
  activeTab: PropTypes.string,
  event: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSubmit: PropTypes.func
}

EventsViewLayout.defaultProps = {
  activeTab: '/events', 
  event: {}, 
  onSubmit: () => {alert('onSubmit is not set!')}
}

export default EventsViewLayout