// Packages
import { useState } from 'react';
import { Link } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import AppSwitcher from 'components/AppSwitcher';
import Avatar from 'components/BTDUI/Avatar';
import Navigation from 'components/Navigation';
import Container from 'components/BTDUI/Container';
import SearchIcon from 'components/BTDUI/SearchButton';
import Search from 'components/BTDUI/Search';
import Overlay from 'components/BTDUI/Overlay';
import MobileMenu from 'components/MobileMenu';

// other
import './styles.css';
import logo from 'assets/oakora-logo.svg';

const Header = () => {

	const [overlayActive, setOverlayActive] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [appSwitcherActive, setAppSwitcherActive] = useState(false);
	const [menuActive, setMenuActive] = useState(false);
	const [avatarActive, setAvatarActive] = useState(false);
    const [hambergerActive, setHamburgerActive] = useState(false);

	const { user } = useAppData();

	return (
		<>
		<header className="Header">

			<Container>
				<Link to='/'><img className='Header__logo' src={logo} alt="Oakora Admin"/></Link>

				<div className='Header__navigation'>
					<Navigation items={[
						{ label: 'Dashboard', url: '/' },
						{ label: 'Events', url: '/events' },
						{ label: 'Orders', url: '/orders' },
						{ label: 'Contacts', url: '/contacts' },
						{ label: 'Reports', url: '/reports' }
					]}/>
				</div>

				<div className="Header__right">
					<div className="Header__Search" onClick={() => {
						if( ! searchActive ) {
							setOverlayActive(true);
							setAppSwitcherActive(false);
							setAvatarActive(false);
							setMenuActive(false);
							setHamburgerActive(false);
							setSearchActive(true);
						}
						else {
							setOverlayActive(false);
							setSearchActive(false);
						}
						}}>
						<SearchIcon />
					</div>
					<div className="Header__AppSwitcher">
						<AppSwitcher 
							active={appSwitcherActive} 
							toggleOverlay={() => {
							if( ! appSwitcherActive ) {
								setSearchActive(false);
								setOverlayActive(true);
								setAvatarActive(false);
								setMenuActive(false);
								setHamburgerActive(false);
								setAppSwitcherActive(true);
							}
							else{
								setOverlayActive(false);
								setAppSwitcherActive(false);
							}
							}} 
						/>
					</div>
					<div className="Header__Avatar">
						<Avatar 
							active={avatarActive}
							toggleOverlay={() => {
								if( ! avatarActive ) {
									setAppSwitcherActive(false);
									setOverlayActive(true);
									setSearchActive(false);
									setMenuActive(false);
									setHamburgerActive(false);
									setAvatarActive(true);
								}
								else {
									setOverlayActive(false);
									setAvatarActive(false);
								}
							}} 
							user={user ?? null}
						/>
					</div>
				</div>

				<button 
                    className={`hamburger hamburger--spin is-active-${hambergerActive}`} 
                    onClick={() => {
						if(! hambergerActive) {
							setHamburgerActive(true);
							setSearchActive(false);
							setAppSwitcherActive(false);
							setAvatarActive(false);
							setOverlayActive(true);
							setMenuActive(true);
						}
						else {
							setHamburgerActive(false);
							setMenuActive(false);
							setOverlayActive(false);
						}
                    }} 
                    type="button"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>

                {menuActive &&
					<MobileMenu onClick={() => {
						setMenuActive(false);
						setHamburgerActive(false);
						setOverlayActive(false);
					}} user={user ?? null} />
				}

			</Container>


		</header>

		{searchActive &&
		
			<Search onClose={() => {
				setSearchActive(false);
				setOverlayActive(false);
			}} />
		}

		{overlayActive &&
		
			<Overlay onClick={() => {
				setMenuActive(false);
				setAvatarActive(false);
				setAppSwitcherActive(false);
				setSearchActive(false);
				setOverlayActive(false);
				setHamburgerActive(false);
			}} />
		}

		</>
	)

}

export default Header;