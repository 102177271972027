// Packages
import PropTypes from 'prop-types';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const AccommodationListItem = props => {

	const { distance_from_venue, eventId, id, image_upload, isLoading, launchForm, name, onDelete, } = props;

	const { destroy } = useApi('/events/' + eventId + '/accommodation/' + id, false);

	const deleteAccommodation = async () => {

		await destroy('accommodation', 'Accommodation Deleted!');

		onDelete();
    }


	return (
		<div className="AccommodationListItem">

			<div className="AccommodationListItem__image" style={{backgroundImage: `url(${image_upload ? image_upload.url: ''})`}}></div>

			<h3 className='black'>{isLoading ? <BTDSkeleton height='20px' /> : name }</h3>

			<p className='black opacity'>{isLoading ? <BTDSkeleton /> : distance_from_venue } miles from venue</p>

			<BTDMenu icon={<EllipsisIcon height="18px" />} style={{position: 'absolute', bottom: '12px', right: '10px'}}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteAccommodation()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>

		</div>
	)
}

AccommodationListItem.propTypes = {
	distance_from_venue: PropTypes.string,
	eventId: PropTypes.string,
	id: PropTypes.number,
	image_upload: PropTypes.object,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func,
	name: PropTypes.string,
}

AccommodationListItem.defaultProps = {
	distance_from_venue: '',
	eventId: null,
	id: null,
	image_upload: {},
	launchForm: () => {alert('lauchForm is not set!')},
	onDelete: () => {'onDelete is not set!'},
	name: ''
}

export default AccommodationListItem;