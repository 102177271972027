// Packages
import PropTypes from 'prop-types'

// Other
import useApi from 'hooks/useApi';
import EventForm from 'components/Event/Form';

const EventCreateScreen = ({ onClose, onSubmit }) => {

    const { post: createEvent, errors } = useApi('/events',  false)

	const submit = async (values) => {

        if( await createEvent(values, 'Event created!') ) {
    
            onSubmit();
        }
    }


    return(
        <EventForm onClose={onClose} onSubmit={submit} type='create' errors={errors} />
    )
}

EventCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

EventCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default EventCreateScreen