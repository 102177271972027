// Packages
import VenuesLayout from "layouts/Venues";
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Venues/List'


const VenueRoutes = () => 
{
	return (
		<VenuesLayout>
			<Routes>
				<Route exact path="/*" element={<ListScreen/>}/>
			</Routes>
		</VenuesLayout>
	);
}


export default VenueRoutes;