// Packages
import PropTypes from 'prop-types';

// Component
import TicketForm from 'components/Order/TicketForm';

// Other
import useApi from 'hooks/useApi';

const EditOrderTicketScreen = ({ orderId, itemId, onClose, onSubmit }) => {

    const { data: ticket, isLoading, put: updateItem, errors } = useApi('/orders/' + orderId + '/items/' + itemId + '?with=ticket_type');

    const submit = async (values) => {

        if( await updateItem(values, 'Ticket updated!')){

            onSubmit();
        }
    }

    return(
        <TicketForm 
            errors={errors} 
            isLoading={isLoading}
            itemId={itemId} 
            onClose={onClose} 
            onSubmit={submit} type='edit'
            orderId={orderId} 
            ticket={ticket} 
        />
    )
}

EditOrderTicketScreen.propTypes = {
    isLoading: PropTypes.bool,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

EditOrderTicketScreen.defaultProps = {
    isLoading: false,
    itemId: null,
    orderId: '',
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default EditOrderTicketScreen;