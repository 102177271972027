// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons
import accommodationIcon from './add-accommodation.svg';
import countdownIcon from './add-countdown.svg';
import headerIcon from './add-header.svg';
import imageIcon from './add-image.svg';
import speakersIcon from './speakers.svg';
import textIcon from './add-text.svg';
import venueIcon from './venue.svg';

// Components
import CreateMicrositeSectionScreen from 'screens/Events/View-Site/CreateSection';
import BTDTooltip from 'components/BTDUI/BTDTooltip';

// other
import useApi from 'hooks/useApi';
import './styles.css';


const MicrositeToolbar = props => {

    const { eventId, onSubmit } = props;

    const { data: templates } = useApi('/microsites/sectiontemplates');

    const [icons, setIcons] = useState({});
    const [template, setTemplate] = useState({});
    const [formActive, setFormActive] = useState(false);

    useEffect(() => {

        if (templates) {

            for (let i = 0; i < templates.length; i++) {

                switch (templates[i].icon) {
                    case 'header':
                        setIcons((prevIcons) => ({ header: headerIcon, ...prevIcons }));
                        break;
                    case 'image':
                        setIcons((prevIcons) => ({ image: imageIcon, ...prevIcons }));
                        break;
                    case 'text':
                        setIcons((prevIcons) => ({ text: textIcon, ...prevIcons }));
                        break;
                    case 'countdown':
                        setIcons((prevIcons) => ({ countdown: countdownIcon, ...prevIcons }));
                        break;
                    case 'accommodation':
                        setIcons((prevIcons) => ({ accommodation: accommodationIcon, ...prevIcons }));
                        break;
                    case 'venue':
                        setIcons((prevIcons) => ({ venue: venueIcon, ...prevIcons }));
                        break;
                    case 'speakers':
                        setIcons((prevIcons) => ({ speakers: speakersIcon, ...prevIcons }));
                        break;
                    default:
                        break;
                }
            }
        }
    }, [templates]);

    return(
        <>
            <div className="MicrositeToolbar">
                {templates &&
                    templates.map((template) => {
                        
                        return(
                            <div key={template.id} className='MicrositeToolbar__item'>

                                <BTDTooltip text={template.name}>

                                    <img 
                                        alt={template.name}
                                        onClick={() => {
                                            setTemplate(template);
                                            setFormActive(true);
                                        }}
                                        src={icons[template.icon]} 
                                    />

                                </BTDTooltip>

                            </div>
                        )
                    })

                }
            </div>

            {formActive &&
                <CreateMicrositeSectionScreen 
                    eventId={eventId}
                    template={template} 
                    onClose={() => {setFormActive(false)}}
                    onSubmit={() => {
                        setFormActive(false);
                        onSubmit();
                    }}
                />
            }
        
        </>
    )
}

MicrositeToolbar.propTypes = {
    eventId: PropTypes.string,
    onSubmit: PropTypes.func
}

MicrositeToolbar.defaultProps = {
    eventId: '',
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default MicrositeToolbar;