// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ImageUpload from 'components/BTDUI/fields/BTDImageUpload';
import TextEditor from 'components/BTDUI/fields/BTDTextEditor';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';
import BTDSlider from 'components/BTDUI/fields/BTDSlider';
import BTDRadioField from 'components/BTDUI/fields/BTDRadioField';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import BTDProgress from 'components/BTDUI/BTDProgress';
import BTDCheckboxField from '../fields/BTDCheckboxField';
import BTDColourPicker from '../fields/BTDColourPicker';


const FieldSelector = ({ errors, fields, isLoading, onChange, values }) => {

    const [data, setData] = useState([])

    useEffect(() => {

		if ( values )
		{
            setData(values);
		}

	}, [values]);

    if( isLoading || (values && (data.length === 0)) ) {
        return(
            <div 
                style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
            >
                <BTDProgress type='circular' style={{height: '50px', width: '50px'}} />
            </div>
            );
    }

    return(
    <>
        {fields.map((item) => {

            let field;
            
            switch (item.field_type) {

                case 'checkbox':
                    field = <div key={item.name}>
                                <BTDCheckboxField
                                    checked={data[item.name] ?? false} 
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                />
                            </div>
                    break;

                case 'text':
                    field = <div key={item.name}>
                                <BTDTextField 
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'large'}
                                />
                            </div>
                    break;

                case 'number':
                    field = <div key={item.name}>
                                <BTDNumberField
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'small'}
                                />
                            </div>
                    break;

                case 'colour':
                    field = <div key={item.name}>
                                <BTDColourPicker
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'small'}
                                />
                            </div>
                    break;

                case 'textarea':
                    field = <div key={item.name}>
                                <BTDTextareaField
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'large'}
                                />
                            </div>
                    break;

                case 'image_upload':
                    field = <div key={item.name}>
                                <ImageUpload
                                    background_size={item.field_config && item.field_config.background_size ? item.field_config.background_size : null}
                                    description={item.description ?? null}
                                    image_max_height={item.field_config && item.field_config.image_max_height ? item.field_config.image_max_height : null} 
                                    image_max_width={item.field_config && item.field_config.image_max_width ? item.field_config.image_max_width : null} 
                                    image_quality={item.field_config && item.field_config.image_quality ? item.field_config.image_quality  : null} 
                                    image_url={data && data.image_upload ? data.image_upload.url : null} 
                                    label={item.label ?? null}
                                    onChange={(id) => {
                                        setData({...data, [item.name]: id});
                                        onChange({[item.name]: id});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                />   
                            </div>
                    break;
                case 'wysiwyg':
                        field = <div key={item.name}>
                                    <TextEditor
                                        description={item.description ?? null}
                                        error={errors ? errors.body : null}
                                        label={item.name ?? ''}
                                        onChange={(value) => {
                                            setData((prevData) => ({ ...prevData, [item.name]: value }));
                                            onChange({[item.name]: value});
                                        }}
                                        value={data ? data[item.name] : null}
                                    />
                                    
                                </div>
                    break;

                case 'slider':
                        field = <div key={item.name}>
                                    <BTDSlider
                                        description={item.description ?? null}
                                        error={errors ? errors[item.name] : false}
                                        label={item.label ?? ''}
                                        min={item.min}
                                        max={item.max}
                                        onChange={(value) => {
                                            setData({...data, [item.name]: value});
                                            onChange({[item.name]: value});
                                        }}
                                        required={item.required ?? false}
                                        step={item.step ? item.step : 50}
                                        value={data ? data[item.name] : item.default ? item.default : 0}
                                    />
                                </div>
                        
                    break;

                case 'switch':
                        field = <div key={item.name}>
                                    <BTDSwitch
                                        checked={data ? data[item.name] : false}
                                        description={item.description ?? null}
                                        error={errors ? errors[item.name] : false}
                                        label={item.label ?? ''}
                                        onChange={(value) => {
                                            setData({...data, [item.name]: value});
                                            onChange({[item.name]: value});
                                        }}
                                    />
                                </div>
                    break;

                case 'select':
                    field = <div key={item.name}>
                                <BTDSelectField
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    options={item.select_options}
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'large'}
                                />
                            </div>
                    break;

                case 'radio':
                    field = <div key={item.name}>
                                <BTDRadioField
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    options={item.field_options ?? {}}
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                />
                            </div>
                    break;

                case 'date' || 'datetime' || 'time':
                    field = <div key={item.name}>
                                <BTDDatepicker
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    required={item.field_config ? item.field_config.required : false}
                                    type={item.field_type}
                                    value={data[item.name] ?? ''} 
                                />
                            </div>
                    break;

                case 'autocomplete':
                    field = <div key={item.name}>
                                <BTDAutoCompleteField
                                    description={item.description ?? null}
                                    error={errors && errors[item.name] ? errors[item.name] : null} 
                                    label={item.label ?? ''}
                                    onChange={(value) => {
                                        setData({...data, [item.name]: value});
                                        onChange({[item.name]: value});
                                    }} 
                                    options={item.select_options}
                                    required={item.field_config ? item.field_config.required : false}
                                    value={data[item.name] ?? ''} 
                                    width={item.field_config ? item.field_config.size : 'large'}
                                />
                            </div>
                    break;

                default:
                    field = <p className='form__text red'>No field found for {item.field_type}</p>;
                    break;
            }
            

            return(
                field
            )
        })}
    </>
    )
}

FieldSelector.propTypes = {
    errors: PropTypes.object,
    fields: PropTypes.array,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

FieldSelector.defaultProps = {
    errors: {},
    fields: [],
    fullWidth: false,
    onChange: () => {alert('onChange is not set!')},
    values: [],
}

export default FieldSelector;