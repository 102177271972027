// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css';
import BTDTab from 'components/BTDUI/BTDTab';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';

const OrderRefundTicketListItem = ({ created_at, event, guest_name, id, isLoading, onSelect, status, ticketId, ticket_type, }) => {

	const { currency, date } = useAppData();

	const [checked, setChecked] = useState(parseInt(ticketId) === parseInt(id) ? true : false);

	useEffect(() => {

		if( parseInt(ticketId) === parseInt(id) ) {

			setChecked(true);
			onSelect({id: id, price: ticket_type ? parseFloat(ticket_type.price) : 0, value: true});
		}
	// eslint-disable-next-line
	}, [ticketId, id])

	return (
		<div className='OrderRefundTicketListItem'>

			<div className='OrderRefundTicketListItem__value'>
				<p className='semi-bold'>{isLoading ? <BTDSkeleton /> : ticket_type ? ticket_type.name : ''}</p>
				{guest_name ?
					<p className='medium'>{isLoading ? <BTDSkeleton /> : guest_name}</p>
					:
					<p className='red italic'>Not Provided</p>
				}
			</div>
			<div className='OrderRefundTicketListItem__value'>
				<p className='semi-bold'>{isLoading ? <BTDSkeleton /> : event ? event.name : ''}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : date(created_at)}</p>
			</div>
			<div className='OrderRefundTicketListItem__value'>
				<p className='medium centre'>{isLoading ? <BTDSkeleton /> : ticket_type ? currency(ticket_type.price): ''}</p>
			</div>

			<div className='OrderRefundTicketListItem__value'>
				<BTDTab type={status} style={{margin: '0 auto'}} />
			</div>
			<div className='OrderRefundTicketListItem__value OrderRefundTicketListItem__value-refund'>
				{status !== 'refunded' && 
					<BTDSwitch
						checked={checked}
						onChange={(value) => {
							setChecked(! checked);
							onSelect({id: id, price: ticket_type ? parseFloat(ticket_type.price) : 0, value: value});
						}}
						style={{margin: '0 auto', width: 'max-content'}}
					/>
				}
			</div>

		</div>
	)
}

OrderRefundTicketListItem.propTypes = {
	created_at: PropTypes.string,
	gateway_fee: PropTypes.string,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	onSelect: PropTypes.func,
	status: PropTypes.string,
	ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	ticket_type: PropTypes.object,
}

OrderRefundTicketListItem.defaultProps = {
	created_at: '',
	gateway_fee: '',
	id: null,
	isLoading: true,
	onSelect: () => {alert('onSelect is not set!')},
	status: '',
	ticketId: null,
	ticket_type: {},
}

export default OrderRefundTicketListItem;