// Packages
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Orders/List'
import ViewScreen from 'screens/Orders/View'
import RefundScreen from 'screens/Orders/Refunds/View'


const OrderRoutes = () => 
{
	return (
		<Routes>
			<Route exact path="/:orderId/refund/*" element={<RefundScreen/>}/>
			<Route exact path="/:orderId/*" element={<ViewScreen/>}/>
			<Route exact path="/*" element={<ListScreen/>}/>
		</Routes>
	);
}


export default OrderRoutes;