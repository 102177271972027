// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDSlider from 'components/BTDUI/fields/BTDSlider';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDButton from 'components/BTDUI/BTDButton';

const MicrositeSettingsForm = props => {

    const { errors, onClose, onSubmit, settings } = props;

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [tab, setTab] = useState('General');

    const submit = () => {

		onSubmit(changedData);
	}

    // Load settings values into form
	useEffect(() => {

		if ( settings )
		{
            setData(settings);
		}

	}, [settings]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">Microsite Settings</h3>

            <div className='form__tabs'>
                <div className={`form__tabs__tab ${tab === 'General' ? 'active' : ''}`} onClick={() => {setTab('General')}}>General</div>
                <div className={`form__tabs__tab ${tab === 'Advanced' ? 'active' : ''}`} onClick={() => {setTab('Advanced')}}>Advanced</div>
            </div>

            {tab === 'General' &&

                <BTDSlider
                    error={errors ? errors.max_width : false}
                    label="Site Width (px)"
                    min={1000}
                    max={2500}
                    onChange={(value) => {
                        setData({...data, max_width: value});
                        setChangedData({...changedData, max_width: value});
                    }}
                    step={500}
                    value={data.max_width ?? 2000}
                />
            }

            {tab === 'Advanced' &&

                <BTDTextareaField
                    description="This content will be inserted into the <head> section of the microsite"
                    error={errors ? errors.header_insert : null} 
                    label="<head> content" 
                    onChange={(value) => {
                        setData({...data, header_insert: value});
                        setChangedData({...changedData, header_insert: value});
                    }} 
                    rows={10}
                    value={data.header_insert ?? ''} 
                />
            }


            <BTDButton
                formButton={true}
                label='Update Settings' 
                onClick={submit}
            />

        </FormTemplate>

    )
}

MicrositeSettingsForm.propTypes = {
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    settings: PropTypes.object,
}

MicrositeSettingsForm.defaultProps = {
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    settings: {},
}

export default MicrositeSettingsForm;