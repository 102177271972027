// Packages
import PropTypes from 'prop-types';

// Component
import WaitingListForm from 'components/WaitingList/Form';

// Other
import useApi from 'hooks/useApi';

const EditWaitingListItemScreen = props => {

    const { eventId, itemId, onClose, onSubmit } = props;

    const { data: item, put: updateItem, errors } = useApi('/events/' + eventId + '/waitinglist/items/' + itemId);

    const submit = async (values) => {

        if( await updateItem(values, 'Waiting list updated!')){

            onSubmit();
        }
    }

    return(
        <WaitingListForm errors={errors} item={item} onClose={onClose} onSubmit={submit} />
    )
}

EditWaitingListItemScreen.propTypes = {
    eventId: PropTypes.string,
	onSubmit: PropTypes.func,
    ticketTypeId: PropTypes.string
}

EditWaitingListItemScreen.defaultProps = {
    eventId: '',
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
    ticketTypeId: ''
}

export default EditWaitingListItemScreen;