// Packages
import ListItem from 'components/Order/RefundTicketListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const OrderRefundTicketList = ({ isLoading, items, onSelect, ticketId }) => {


	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress type='circular' height={100} style={{margin: '20px auto'}} width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
			<h3 className='OrderRefundTicketList__message italic'>No tickets to show here</h3>
		)
	}
	else {
		return (
			<div className='OrderRefundTicketList'>

				<div className='OrderRefundTicketList__header'>
					<p className='OrderRefundTicketList__header__column medium'>Ticket</p>
					<p className='OrderRefundTicketList__header__column medium'>Event</p>
					<p className='OrderRefundTicketList__header__column medium centre'>Price</p>
					<p className='OrderRefundTicketList__header__column medium centre'>Status</p>
					<p className='OrderRefundTicketList__header__column OrderRefundTicketList__header__column-refund medium centre'>Refund</p>
				</div>

				{isLoading ? 
				
					<ListItem isLoading={true}/>
				:
					items.map((item) => {

						return (
								<ListItem key={item.id} {...item} isLoading={false} onSelect={(value) => { onSelect(value)}} ticketId={ticketId}/>
								)
					
					})
				}
			</div>
		)
	}
}

OrderRefundTicketList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onSelect: PropTypes.func,
	ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

OrderRefundTicketList.defaultProps = {
	isLoading: false,
	items: {},
	onSelect: () => {alert('onSelect is not set!')},
	ticketId: null
}

export default OrderRefundTicketList;