// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Context
import { useAppData } from 'context/AppData';

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import useApi from 'hooks/useApi';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';

const ContactForm = ({ contact, errors, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({});
	const [changedData, setChangedData] = useState({});

    const { settings } = useAppData();

    const { data: countries } = useApi('/countries');

    
    // Load contact values into form
	useEffect(() => {

		if ( contact )
		{
            setData(contact);
		}

	}, [contact]);

    const submit = () => {

		onSubmit(changedData);
	}



    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create contact' : 'Edit contact'}</h3>
        
            <div className='form__grid-2'>
                <BTDTextField
                    error={errors && errors.firstname ? errors.firstname : null} 
                    label='First name'
                    onChange={(value) => {
                        setData({...data, firstname: value});
                        setChangedData({...changedData, firstname: value});
                    }} 
                    required={true}
                    value={data.firstname ?? ''} 
                />

                <BTDTextField
                    error={errors && errors.lastname ? errors.lastname : null} 
                    label='Last name'
                    onChange={(value) => {
                        setData({...data, lastname: value});
                        setChangedData({...changedData, lastname: value});
                    }} 
                    required={true}
                    value={data.lastname ?? ''} 
                />
            </div>

            <BTDTextField
                error={errors && errors.organisation ? errors.organisation : null} 
                label='Organisation'
                onChange={(value) => {
                    setData({...data, organisation: value});
                    setChangedData({...changedData, organisation: value});
                }} 
                value={data.organisation ?? ''} 
            />

            <BTDTextField
                error={errors && errors.email ? errors.email : null} 
                label='Email address'
                onChange={(value) => {
                    setData({...data, email: value});
                    setChangedData({...changedData, email: value});
                }} 
                value={data.email ?? ''} 
            />

            <BTDTextField
                error={errors && errors.telephone ? errors.telephone : null} 
                label='Telephone'
                onChange={(value) => {
                    setData({...data, telephone: value});
                    setChangedData({...changedData, telephone: value});
                }} 
                value={data.telephone ?? ''} 
            />
            <div className='form__grid-2'>
                <BTDTextField
                    error={errors && errors.address1 ? errors.address1 : null} 
                    label='Address line 1'
                    onChange={(value) => {
                        setData({...data, address1: value});
                        setChangedData({...changedData, address1: value});
                    }} 
                    value={data.address1 ?? ''} 
                />

                <BTDTextField
                    error={errors && errors.address2 ? errors.address2 : null} 
                    label='Address line 2'
                    onChange={(value) => {
                        setData({...data, address2: value});
                        setChangedData({...changedData, address2: value});
                    }} 
                    value={data.address2 ?? ''} 
                />
            </div>

            <div className='form__grid-2'>
            <BTDTextField
                error={errors && errors.city ? errors.city : null} 
                label='City'
                onChange={(value) => {
                    setData({...data, city: value});
                    setChangedData({...changedData, city: value});
                }} 
                value={data.city ?? ''} 
            />

            <BTDTextField
                error={errors && errors.postcode ? errors.postcode : null} 
                label='Postcode'
                onChange={(value) => {
                    setData({...data, postcode: value});
                    setChangedData({...changedData, postcode: value});
                }} 
                value={data.postcode ?? ''} 
            />
            </div>

            <BTDAutoCompleteField
                    error={errors && errors.country_id ? errors.country_id : null} 
                    label="Country"
                    onChange={(value) => {
                        setData({...data, country_id: value});
                        setChangedData({...changedData, country_id: value});
                    }} 
                    options={countries}
                    value={data.country_id ?? ''} 
                /> 

            <BTDTextField
                error={errors && errors.external_id ? errors.external_id : null} 
                label={settings && settings.customer_external_id_label ? settings.customer_external_id_label : 'external_id'}
                onChange={(value) => {
                    setData({...data, external_id: value});
                    setChangedData({...changedData, external_id: value});
                }} 
                value={data.external_id ?? ''} 
            />
                
            <BTDButton
                formButton={true}
                label={type === 'create' ? 'Save contact' : 'Update contact'}
                onClick={submit} 
                style={{marginBottom: '10px'}}
            />

        </FormTemplate>

    )

}

ContactForm.propTypes = {
	contact: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),  
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string,
}

ContactForm.defaultProps = {
    contact: null,
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    type: 'create',
}

export default ContactForm;