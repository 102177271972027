// PackagesS
import { useParams } from 'react-router-dom';

// Context

// components
import Layout from 'layouts/OrderView';
import OrderTicketsList from 'components/Order/TicketList';
import OrderTransactionsList from 'components/Order/TransactionList';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const RefundViewScreen = () => {

	const { orderId } = useParams();


	const { data: order, isLoading } = useApi('/orders/' + orderId + '/?with=event');
	const { data: tickets, onChange: onTicketChange, ticketsLoading } = useApi('/orders/' + orderId + '/items?with=ticket_type,event');
	const { data: transactions, transactionsLoading } = useApi('/orders/' + orderId + '/transactions');

	
	return (
		<Layout order={order} isLoading={isLoading}>
			
			<div className='OrderView'>

				<div className='OrderView__main'>

					<h3 className='black'>Tickets</h3>
					
					<OrderTicketsList items={tickets} isLoading={ticketsLoading} onSubmit={() => {onTicketChange()}} />

					<h3 className='black'>Transactions</h3>

					<OrderTransactionsList items={transactions} isLoading={transactionsLoading} />

				</div>

				<div className='OrderView__sidebar'>
					
					<div className='OrderView__sidebar__block'>

						<div className='OrderView__sidebar__block__header'>
							Customer
						</div>

						<div className='OrderView__sidebar__block__main'>

							<p className='bold'>{isLoading ? <BTDSkeleton /> : order.firstname + ' ' + order.lastname}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.organisation}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.address1}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.address2}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.city}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.postcode}</p>
							<br/>
							<a href={'mailto:' + order.email} className='OrderView__sidebar__block__main__link black medium'>{isLoading ? <BTDSkeleton /> : order.email}</a>
							<br/>
							<p className='medium'>{isLoading ? <BTDSkeleton /> : order.telephone}</p>
							<br/>

						</div>

					</div>


					<div className='OrderView__sidebar__block'>

						<div className='OrderView__sidebar__block__header'>
							Actions
						</div>

						<div className='OrderView__sidebar__block__main'>

							<p className='OrderView__sidebar__block__main__link red'>Cancel & Refund Order</p>

						</div>

					</div>

				</div>

			</div>

		</Layout>
	)

}

export default RefundViewScreen;