// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const UpArrowIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="UpArrowIcon" onClick={onClick} style={style}>
            <svg fill={colour} height={height} viewBox="0 0 64 64" width={width} xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_47" dataName="Layer 47">
                    <path d="m61.18 49.08-28-36a1.55 1.55 0 0 0 -2.36 0l-28 36a1.49 1.49 0 0 0 -.17 1.58 1.51 1.51 0 0 0 1.35.84h56a1.51 1.51 0 0 0 1.35-.84 1.49 1.49 0 0 0 -.17-1.58z"/>
                </g>
            </svg>
        </div>
    )
}

UpArrowIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

UpArrowIcon.defaultProps = {
    colour: "currentColor",
    height: "17px",
    onClick: () => {},
    style: {},
    width: "17px", 
}

export default UpArrowIcon;