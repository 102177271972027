// Packages
import { useState } from 'react';
import PropTypes from 'prop-types'

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import CloseIcon from 'components/BTDUI/icons/CloseIcon';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import SearchIcon from 'components/BTDUI/icons/SearchIcon';

// other
import useApi from 'hooks/useApi';
import './styles.css';
import SearchResultsList from './SearchResultsList';

const Search = props => {

	const { onClose } = props;

	const [query, setQuery] = useState('');

	const { data: searchResults, post: submit, isLoading } = useApi('/search', false);

	return (
		<div className="Search">

            <CloseIcon onClick={onClose} style={{cursor: 'pointer', display: 'block', height: '35px', margin: '0 -5px 30px auto', opacity: '0.5', width: '35px' }}/>

			<form className='SearchBar' onSubmit={(e) => {
				e.preventDefault();
				submit({query: query}, false);
            }}>

            
            <BTDTextField
                label='Search'
                onChange={(value) => {setQuery(value)}}
                // placeholder='Search'
                startIcon={query ? <ClearIcon onClick={ () => {setQuery('')}} style={{cursor: 'pointer', height: '25px', width: '25px'}}/> : <SearchIcon colour='#666' height='24px' width='24px'/>}
                style={{
                    backgroundColor: '#F6F8FB',
                }}
                value={query}
            />

        </form>

		<div className='Search__results'>

			<SearchResultsList items={searchResults} isLoading={isLoading} onClose={onClose}/>

		</div>

	</div>
	)
}

Search.propTypes = {
	onClose: PropTypes.func
}

Search.defaultProps = {
	onClose: () => {alert('onClose is not set!')}
}

export default Search;