// Packages
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

// Other
import Config from 'other/config';
import './styles.css';

const GoogleMap = props => {

    const { address } = props

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {

        const script = document.createElement('script');
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + Config.MAPS_API_KEY + "&callback=initMap";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.initMap = () => {
            geocodeAddress();
        }

        return () => {
            delete window.initMap;
            document.body.removeChild(script);
        };
    // eslint-disable-next-line
    }, [address]);


    const geocodeAddress = () => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
        const location = results[0].geometry.location;
        setLatitude(location.lat());
        setLongitude(location.lng());
        } else {
            console.error('Geocode was not successful for the following reason: ' + status);
        }
    });
    };

    const showMap = () => {
    if (latitude !== null && longitude !== null) {
        const mapOptions = {
        center: { lat: latitude, lng: longitude },
        zoom: 15,
        fullscreenControl: true, 
        mapTypeControl: false, // (Map/Satellite buttons)
        streetViewControl: false, 
        };
        const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        });
    }
    };

    const openMapInNewTab = () => {
        if (latitude !== null && longitude !== null) {
            const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(url, '_blank');
        }
    };

    return (
    <div>

        <div id="map" style={{ height: '300px', marginTop: '20px', width: '100%' }} />

        <p className='GoogleMap__link light-blue' onClick={openMapInNewTab}>
            Open Google Map
            <span className='GoogleMap__icon'/>
        </p>
        {showMap()}

    </div>
    );
};

GoogleMap.propTypes = {
    address: PropTypes.string
}

GoogleMap.defaultProps = {
    address: ''
}

export default memo(GoogleMap);
