// Packages
import PropTypes from 'prop-types';

// Components
import VenueForm from 'components/Venue/Form';

// Other
import useApi from 'hooks/useApi'

const VenueCreateScreen = props => {

    const { onClose, onSubmit } = props;

    const { post: createVenue, errors } = useApi('/venues', false);

    const submit = async (values) => {

        if( await createVenue(values, 'Venue created!') ) {

            onSubmit();
        }
    }

    return(
        <VenueForm onClose={onClose} onSubmit={submit} type='create' errors={errors} />
    )
}

VenueCreateScreen.propTypes = {
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

VenueCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default VenueCreateScreen;