// Packages
import PropTypes from 'prop-types';

// Component
import CustomFieldForm from 'components/CustomField/Form';

// Other
import useApi from 'hooks/useApi';

const EditCustomFieldScreen = ({ itemId, onClose, onSubmit }) => {

    const { data: item, put: updateItem, errors } = useApi('/customfields/' + itemId);

    const submit = async (values) => {

        if( await updateItem(values, 'Custom field updated!')){

            onSubmit();
        }
    }

    return(
        <CustomFieldForm errors={errors} item={item} onClose={onClose} onSubmit={submit} type='edit' />
    )
}

EditCustomFieldScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditCustomFieldScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
    itemId: null
}

export default EditCustomFieldScreen;