// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const GuestIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="GuestIcon" onClick={onClick} style={style}>
            <svg height={height} viewBox="0 0 24 24" width={width}>
                <g id="Layer_2" dataname="Layer 2" fill={colour}>
                    <circle cx="12" cy="6" r="4.75"/>
                    <path d="m19.25 13v2a1.7584 1.7584 0 0 1 -1.75 1.75h-1a.24748.24748 0 0 0 -.25.25v4a1.7584 1.7584 0 0 1 -1.75 1.75h-5a1.7584 1.7584 0 0 1 -1.75-1.75v-4a.24748.24748 0 0 0 -.25-.25h-1a1.7584 1.7584 0 0 1 -1.75-1.75v-2a3.73883 3.73883 0 0 1 2.64014-3.58 5.72523 5.72523 0 0 0 9.21972 0 3.73883 3.73883 0 0 1 2.64014 3.58z"/>
                </g>
            </svg>
        </div>
    )
}

GuestIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

GuestIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default GuestIcon;