// Packages
import PropTypes from 'prop-types'

// Context
import { useAppData } from 'context/AppData';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const EmailTemplateItem = props => {

    const { id, isLoading, name, updated_at, launchForm, onDelete } = props;

	const { date } = useAppData();

    const { destroy: destroyItem } = useApi('/emailtemplates/' + id, false);

	const deleteItem = async () => {

		await destroyItem('email template', 'Email template deleted!');
		onDelete();
	}

    return(
        <div className='EmailTemplateItem'>

            <p className='medium'>{isLoading ? <BTDSkeleton /> : name }</p>

            <p className='opacity'>{isLoading ? <BTDSkeleton /> : date(updated_at) }</p>

            <BTDMenu icon={<EllipsisIcon height="18px" />}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>
        </div>
    )
}

EmailTemplateItem.propTypes = {
	id: PropTypes.number,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
	updated_at: PropTypes.string,
}

EmailTemplateItem.defaultProps = {
	id: null,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set!')},
	updated_at: '',
}

export default EmailTemplateItem;