// Packages
import { useState } from 'react'

// Components
import EventsLayout from "layouts/Events"
import EventList from 'components/Event/List';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import EventCreateScreen from 'screens/Events/Create';

// Other
import useApi from 'hooks/useApi';

const EventListScreen = () => {

	const [formActive, setFormActive] = useState(false);

	const { data: events, isLoading, onChange } = useApi('/events?with=venue');
	
	return (
		<EventsLayout activeTab="/events">

			<EventList 
				items={events} 
				isLoading={isLoading}
			/>

			<AddIcon onClick={() => {
				setFormActive(true);
			}} />

			{formActive &&
				<EventCreateScreen 
					onClose={() => {
						setFormActive(false);
					}} 
					onSubmit={() => {
						onChange();
						setFormActive(false);
					}} 
				/>
			}
	</EventsLayout>
	)

}

export default EventListScreen;