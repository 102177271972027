// Packages
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Contacts/List'
import ViewScreen from 'screens/Contacts/View'


const RefundRoutes = () => 
{
	return (
		<Routes>
			<Route exact path="/:contactId/*" element={<ViewScreen/>}/>
			<Route exact path="/*" element={<ListScreen/>}/>
		</Routes>
	);
}


export default RefundRoutes;