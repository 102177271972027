// Packages
import { useState } from 'react';

// Components
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import Layout from 'layouts/Settings';
import CreateTaxRateScreen from './Create';
import EditTaxRateScreen from './Edit';
import TaxRateList from 'components/TaxRate/List';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const SettingsTaxRateScreen = () => {

	const { data: items, isLoading, onChange } = useApi('/taxrates');

	const [createFormActive, setCreateFormActive] = useState(false);
	const [editFormActive, setEditFormActive] = useState(false);
	const [itemId, setItemId] = useState(null);
	
	return (
		<Layout activeTab='/settings/tax-rates'>

				<div className='TaxRates'>

					<TaxRateList
						items={items} 
						isLoading={isLoading}
						launchForm={(id) => {
							setItemId(id);
							setEditFormActive(true);
						}}
						onDelete={() => {
							setEditFormActive(false);
							onChange();
						}}
					/>
				</div>

			<AddIcon onClick={() => {setCreateFormActive(true)}} />

			{createFormActive && 

				<CreateTaxRateScreen
					onClose={() => {setCreateFormActive(false)}} 
					onSubmit={() => {
						setCreateFormActive(false);
						onChange();
					}}
				/>
			}
			{editFormActive && 

				<EditTaxRateScreen
					itemId={itemId}
					onClose={() => {setEditFormActive(false)}} 
					onSubmit={() => {
						setEditFormActive(false);
						onChange();
					}}
				/>
			}

		</Layout>
	)

}

export default SettingsTaxRateScreen;