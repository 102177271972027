import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';

const ContactsLayout = ({children}) => {

	return (
		<>
		<TitleBar title="Contacts">
            
        </TitleBar>
			<Container>
				{children}
			</Container>
        </>
	)
}

export default ContactsLayout