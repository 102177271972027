// Packages
import ListItem from 'components/WaitingList/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const WaitingList = ({ eventId, isLoading, items, launchForm, onDelete }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='WaitingList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No waiting list items to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<>

			{items.map((item) => {

				return (
					<ListItem 
						{...item} 
						eventId={eventId}
						key={item.id} 
						isLoading={false}
						launchForm={(id) => {launchForm(id)}}
						onDelete={onDelete} 
					/>
				)
				
			})}
	
		</>
	)
}

WaitingList.propTypes = {
	eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

WaitingList.defaultProps = {
	eventId: {},
	isLoading: false,
	items: [],
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')}
}

export default WaitingList;