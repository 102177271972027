// Packages
import PropTypes from 'prop-types';

// Context
import { useSnackbar } from 'context/Snackbar';

const DownloadPDF = ({ children, default_filename, text, url }) => {

    const { showSnackbar } = useSnackbar();

    const download = async () => {

		try {
			// Make an API call to fetch the PDF data
			const response = await fetch(url);
			const filenameHeader = response.headers.get('x-ticketfilename');
			const data = await response.blob(); // Convert the response to a Blob

			// Trigger the download
			const obrjectUrl = window.URL.createObjectURL(data);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = obrjectUrl;
			a.download = filenameHeader ?? default_filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(obrjectUrl);

		} catch (error) {
			showSnackbar(error.message, 'error');
		}
	} 

    return(
        <div onClick={download}>{children}{text}</div>
    )
}

DownloadPDF.propTypes = {
    children: PropTypes.node,
    default_filename: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
}

DownloadPDF.defaultProps = {
    children: null,
    default_filename: 'file',
    text: '',
    url: ''
}

export default DownloadPDF;