// Packages
import ListItem from 'components/AppSwitcher/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const AppSwitcherList = props => {

	const { isLoading, items, onClick } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='AppSwitcherList'>
					<ListItem/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No apps to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='AppSwitcherList'>

			{items.map((item) => {

				return (
					<ListItem key={item.name} {...item} onClick={onClick}/>
				)
				
			})}
	
		</div>
	)

}

AppSwitcherList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onClick: PropTypes.func
}

AppSwitcherList.defaultProps = {
	isLoading: false,
	items: [],
	onClick: () => {alert('onClick is not Set!')}

}

export default AppSwitcherList