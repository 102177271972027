// Packages
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css'

const EventListItem = props => {

	const { available, capacity, id, isLoading, name, sold, start_at, venue } = props;

	const navigate = useNavigate();
	const { date } = useAppData();

	return (
		<div className="EventListItem">

			<div className='EventListItem__main'>

			{! isLoading &&
			<>
				<div onClick={(e) => {e.preventDefault()}} >
					<BTDMenu icon={<EllipsisIcon height="18px" />} style={{position: 'absolute', top: '12px', right: '10px'}}>
						<li onClick={() => {navigate('/events/' + id + '/tickets')}}>Ticket Types</li>
						<li onClick={() => {navigate('/events/' + id + '/site')}}>Microsite</li>
					</BTDMenu>
				</div>
			</>
			}
				
				<h3 className='black'>{! isLoading ? name : <BTDSkeleton height='20px' />}</h3>

				<p className='black opacity'>{! isLoading ? date(start_at) : <BTDSkeleton /> }</p>

				<p className='black'>{! isLoading ? venue ? venue.name : '' : <BTDSkeleton /> }</p>

			</div>

			<div className='EventListItem__stats light-grey'>

				<div className='EventListItem__stats__stat EventListItem__stats__stat-cpacity'>

					<label className='EventListItem__stats__stat__label'>Capacity</label>

					<h3 className='red'>
						{! isLoading ? capacity.toLocaleString('en-GB') : <BTDSkeleton height='20px' /> }
					</h3>

				</div>

				<div className='EventListItem__stats__stat EventListItem__stats__stat-available'>

					<label className='EventListItem__stats__stat__label'>Available</label>

					<h3 className='green'>
						{! isLoading ? available.toLocaleString('en-GB') : <BTDSkeleton height='20px' /> }
					</h3>

				</div>

				<div className='EventListItem__stats__stat EventListItem__stats__stat-sold'>

					<label className='EventListItem__stats__stat__label'>Sold</label>

					<h3 className='orange'>
						{! isLoading ? sold.toLocaleString('en-GB') : <BTDSkeleton height='20px' /> }
					</h3>

				</div>

			</div>

		</div>
	)
}

EventListItem.propTypes = {
	available: PropTypes.number,
	capacity: PropTypes.number,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	sold: PropTypes.number,
	start_at: PropTypes.string,
	venue: PropTypes.object,
	name: PropTypes.string
}

EventListItem.defaultProps = {
	available: null,
	capacity: null,
	id: null,
	isLoading: false,
	sold: null,
	start_at: '',
	venue: {},
	name: null
}

export default EventListItem