// Packages
import PropTypes from 'prop-types';

// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';

const SettingsLayout = ({ activeTab, children }) => {

      let tabs = []
      let menuItems = []
      
          tabs = [
            {label: 'General', url: '/settings'},
            {label: 'Branding', url: '/settings/branding'},
            {label: 'Financial', url: '/settings/financial'},
            {label: 'Tax Rates', url: '/settings/tax-rates'},
            {label: 'Email', url: '/settings/email'},
            {label: 'Email Templates', url: '/settings/email-templates'},
            {label: 'Custom Fields', url: '/settings/custom-fields'},
          ];

      let currentTab;

      if( activeTab ) {

        switch (activeTab) {
          case '/settings':
            currentTab = 'General';
            break;
          case '/settings/branding':
            currentTab = 'Branding';
            break;
          case '/settings/financial':
            currentTab = 'Financial';
            break;
          case '/settings/tax-rates':
            currentTab = 'Tax Rates';
            break;
            case '/settings/email':
              currentTab = 'Email';
              break;
          case '/settings/email-templates':
            currentTab = 'Email Templates';
            break;
          case '/settings/custom-fields':
            currentTab = 'Custom Fields';
            break;
        
          default:
            currentTab = 'General'
            break;
        }
      }

    
    return (
		<>
      <TitleBar title='Settings' menuItems={menuItems} tabs={tabs} activeTab={currentTab}></TitleBar>

      <Container>
        {children}
      </Container>

    </>
	)

}

SettingsLayout.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node,
}

SettingsLayout.defaultProps = {
  activeTab: '/settings', 
  children: null,
}

export default SettingsLayout