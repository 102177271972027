// Packages
import ListItem from 'components/TaxRate/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const TaxRateList = props => {

	const { isLoading, items, launchForm, onDelete } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='TaxRateList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No tax rates to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='TaxRateList'>

			{items.map((item) => {

				return (
					<ListItem 
						{...item} 
						key={item.id} 
						isLoading={false}
						launchForm={(id) => {launchForm(id)}}
						onDelete={onDelete} 
					/>
				)
				
			})}
	
		</div>
	)
}

TaxRateList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

TaxRateList.defaultProps = {
	isLoading: false,
	items: [],
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')}
}

export default TaxRateList;