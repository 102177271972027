// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import ImageUpload from 'components/BTDUI/fields/BTDImageUpload';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import useApi from 'hooks/useApi';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';

const VenueForm = ({ errors, onClose, onSubmit, type, venue }) => {

    const [data, setData] = useState({});
	const [changedData, setChangedData] = useState({});


    const { data: countries } = useApi('/countries');

    
    // Load Venue values into form
	useEffect(() => {

		if ( venue )
		{
            setData(venue);
		}

	}, [venue]);

    const submit = () => {

		onSubmit(changedData);
	}



    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create venue' : 'Edit venue'}</h3>
        
            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Venue name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextareaField
                error={errors && errors.address ? errors.address : null} 
                label='Street address'
                onChange={(value) => {
                    setData({...data, address: value});
                    setChangedData({...changedData, address: value});
                }} 
                rows={3}
                value={data.address ?? ''} 
            />

            <div className='form__grid-2'>

                <BTDTextField
                    error={errors && errors.city ? errors.city : null} 
                    label='City'
                    onChange={(value) => {
                        setData({...data, city: value});
                        setChangedData({...changedData, city: value});
                    }} 
                    required={true}
                    value={data.city ?? ''} 
                />

                <BTDTextField
                    error={errors && errors.postcode ? errors.postcode : null} 
                    label='Postcode'
                    onChange={(value) => {
                        setData({...data, postcode: value});
                        setChangedData({...changedData, postcode: value});
                    }} 
                    required={true}
                    value={data.postcode ?? ''} 
                />

            </div>

            <BTDAutoCompleteField
                    error={errors && errors.country_id ? errors.country_id : null} 
                    label="Country"
                    onChange={(value) => {
                        setData({...data, country_id: value});
                        setChangedData({...changedData, country_id: value});
                    }} 
                    options={countries}
                    value={data.country_id ?? ''} 
                /> 

            <BTDTextField
                error={errors && errors.website ? errors.website : null} 
                label='Website'
                onChange={(value) => {
                    setData({...data, website: value});
                    setChangedData({...changedData, website: value});
                }} 
                required={true}
                value={data.website ?? ''} 
            />

            <ImageUpload
                image_max_height={800} 
                image_max_width={600} 
                image_quality={0.8} 
                image_url={data && data.image_upload ? data.image_upload.url : null} 
                label='Photo'
                onChange={(id) => {
                    setData({...data, image_upload_id: id});
                    setChangedData({...changedData, image_upload_id: id});
                }} 
            />   

            <BTDButton
                formButton={true}
                label={type === 'create' ? 'Save venue' : 'Update venue'}
                onClick={submit} 
            />

        </FormTemplate>

    )

}

VenueForm.propTypes = {
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string,
	venue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),  
}

VenueForm.defaultProps = {
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    type: 'create',
    venue: null,
}

export default VenueForm;