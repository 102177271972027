// Packages
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

// Context
import { UIProvider } from 'context/UI';
import { NavProvider } from 'context/Nav';
import { SnackbarProvider } from 'context/Snackbar';
import { AppDataProvider } from 'context/AppData';

// Other 
import Auth from 'other/Auth';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      
        <BrowserRouter>
        <Auth>
          <UIProvider>
            <AppDataProvider>
                <NavProvider>
                  < App />
                </NavProvider>
            </AppDataProvider>
          </UIProvider>
          </Auth>
        </BrowserRouter>
      
    </SnackbarProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
