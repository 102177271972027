// Packages
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

// Components
import Container from 'components/BTDUI/Container'
import CheckedLink from 'components/BTDUI/CheckedLink';
import BTDMenu from 'components/BTDUI/BTDMenu';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDMenuDropdown from 'components/BTDUI/BTDMenuDropdown';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css'

const TitleBar = ({ activeTab, breadcrumb, children, isLoading, menuItems, title, tabs }) => {

	return (
		<div className="TitleBar">

			<Container>

				<Link to='/events' className="TitleBar__breadcrumb black opacity">{breadcrumb ?? ''}</Link>

				<div className="TitleBar__top">
					<div className="TitleBar__top__title medium">
					{isLoading ? <BTDSkeleton  sx={{width: '150px'}}/> : title}
					</div>
					<div className="TitleBar__top__action">
						{children}
					</div>
				</div>

				{ tabs &&
				<>
				<div className="TitleBar__tabs">
					<ul className="TitleBar__tabs__list">
						{tabs.map((item) => {
							return (
								<li key={item.url} className={`TitleBar__tabs__list__item ${activeTab===item.label? 'TitleBar__tabs__list__item--active' : ''}`}>
									<CheckedLink to={item.url} className='TitleBar__tabs__list__item__link' label={item.label} />
								</li>
							)

						})}
					{menuItems.length > 0 &&
						<BTDMenu icon={<EllipsisIcon height="20px" style={{transform: 'rotate(90deg'}}/>}>
							{menuItems.map((item) => {
								return(
									<li key={item.label}><CheckedLink to={item.url} label={item.label} /></li>
									)
								})
							}
						</BTDMenu>
					}
					</ul>
				</div>

				{menuItems.length !== 0 && tabs.length !== 0 &&
					<div className='TitleBar__select'>
						<BTDMenuDropdown
							current={activeTab}
							links={[...tabs, ...menuItems] ?? ''}
							style={{margin: '0 auto 30px auto', maxWidth: '300px'}}
						/>
					</div>
				}

				</>
				}

			</Container>

		</div>
	)
}

TitleBar.propTypes = {
	activeTab: PropTypes.string,
	breadcrumb: PropTypes.string,
	children: PropTypes.node,
	menuItems: PropTypes.array,
	title: PropTypes.string.isRequired,
	tabs: PropTypes.array
}

TitleBar.defaultProps = {
	activeTab: '',
	breadcrumb: '',
	children: null,
	menuItems: [],
	title: '',
	tabs: []
}


export default TitleBar;