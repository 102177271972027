// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';
import BTDButton from 'components/BTDUI/BTDButton';

const TaxRateForm = props => {

    const { errors, onClose, onSubmit, item, } = props;

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    const submit = () => {

		onSubmit(changedData);
	}

    // Load item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);
		}

	}, [item]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextField
                error={errors && errors.rate ? errors.rate : null} 
                label='Rate (%)'
                onChange={(value) => {
                    setData({...data, rate: value});
                    setChangedData({...changedData, rate: value});
                }} 
                required={true}
                value={data.rate ?? ''} 
            />

            <BTDSwitch
                checked={data.is_default}
                error={errors ? errors.is_default : null}
                label="Set as default" 
                onChange={(value) => {
                    setData({...data, is_default: value});
                    setChangedData({...changedData, is_default: value});
                }}
            />


            <BTDButton
                formButton={true}
                label='Save'
                onClick={submit} 
            />

        </FormTemplate>

    )

}

TaxRateForm.propTypes = {
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

TaxRateForm.defaultProps = {
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    item: {},
}

export default TaxRateForm;