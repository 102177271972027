// Packages
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from 'prop-types';

// Other
import './styles.css';

const SellingDates = props => {

    const { end_timestamp, start_timestamp, status } = props;

    const [ text, setText ] = useState('');

    useEffect(() => {

        let now = Date.now() / 1000;
        let start = dayjs(start_timestamp).unix();
        let end = dayjs(end_timestamp).unix();

        if( status === 'scheduled' && start > now && end > now ) {
            setText('Selling between ' + dayjs(start_timestamp).format('D MM YYYY') + ' - ' + dayjs(end_timestamp).format('D MM YYYY'));
        }
        if( status === 'scheduled' && start > now ) {
            setText('Available from ' + dayjs(start_timestamp).format('D MM YYYY'));
        }
        else if ( status === 'enabled' && (start <= now || start_timestamp === null) && end_timestamp > now ) {
            setText('On sale until ' + dayjs(end_timestamp).format('D MM YYYY'));
        }
        else if ( status === 'enabled' && (start <= now || start_timestamp === null) && end_timestamp === null ) {
            setText('On sale now');
        }
        else if ( status === 'disabled' && end > now ) {
            setText('Not yet available');
        }
        else if ( status === 'disabled' && end <= now ) {
            setText('No longer available');
        }
        else {
            setText('Not available');
        }


    }, [start_timestamp, end_timestamp, status]);

    return(
        <p className="SellingDates">{text}</p>
    )
}

SellingDates.propTypes = {
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    status: PropTypes.string
}

SellingDates.defaultProps = {
    start_timestamp: null,
    end_timestamp: null,
    status: 'disabled'
}

export default SellingDates;