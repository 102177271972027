
import './styles.css';

const Footer = props => {

	return (
		<footer className="Footer">

			&copy; 2023 Oakora

		</footer>
	)
}

export default Footer;