// Packages
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import MicrositeToolbar from 'components/Microsite/MicrositeToolbar';
import EditMicrositeSectionScreen from '../EditSection';
import MicrositeSettingsForm from 'components/Microsite/SettingsForm';
import MicrositeSectionList from 'components/Microsite/MicrositeSection/List';
import CloseIcon from 'components/BTDUI/icons/CloseIcon';
import CogIcon from 'components/BTDUI/icons/CogIcon';
import BTDButton from 'components/BTDUI/BTDButton';
import EyeIcon from 'components/BTDUI/icons/EyeIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const MicrositeDesigner = () => {

    const { eventId }  = useParams();

    const [section, setSection] = useState({});
    const [sectionFormActive, setSectionFormActive] = useState(false);
    const [settingsFormActive, setSettingsFormActive] = useState(false);

    const { data: event } = useApi('/events/' + eventId + '?with=microsite');
    const { data: settings, put: updateSettings } = useApi('/events/' + eventId + '/microsite');
    const { put: updateSeSectionOrder } = useApi('/events/' + eventId + '/microsite/sections/sequence', false);
    const { data: initialSections, onChange } = useApi('/events/' + eventId + '/microsite/sections?with=template');

    const [sections, setSections] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {

        if(initialSections) {
            setSections(initialSections)
        }
    }, [initialSections])

    const reorderSections = (currentIndex, direction) => {
        const newItems = [...sections];
        const movingItem = newItems[currentIndex];
    
        if (direction === 'up' && currentIndex > 0) {
            newItems[currentIndex] = newItems[currentIndex - 1];
            newItems[currentIndex - 1] = movingItem;
        } else if (direction === 'down' && currentIndex < newItems.length - 1) {
            newItems[currentIndex] = newItems[currentIndex + 1];
            newItems[currentIndex + 1] = movingItem;
        }
    
        setSections(newItems);

        const updatedItemIds = newItems.map(item => item.id);

        updateSeSectionOrder({sequence: updatedItemIds}, 'Section moved!');
        
    };

    const updateMicrositeSettings = async (values) => {

        await updateSettings(values, 'Settings updated!');

        setSettingsFormActive(false);
        onChange();
    }


    return(
        <div className="MicrositeDesigner">

            <div className="MicrositeDesigner__header">

                <Container>

                    <div className="MicrositeDesigner__header__title">
                        <Link 
                            sx={{color: '#253551'}}
                            to={'/events/'+ eventId}
                        >
                            <h1 className="MicrositeDesigner__header__title__name">{event ? event.name : ''}</h1>
                        </Link>
                        <p className="MicrositeDesigner__header__title__breadcrumb">{'>'} Microsite Designer</p>
                    </div>

                    <div className="MicrositeDesigner__header__close">
                        <CloseIcon
                            onClick={() => {navigate('/events/' + event.id + '/site')}} 
                            style={{
                                cursor: 'pointer',
                                height: '35px',
                                opacity: '0.4',
                                width: '35px',
                                }} 
                            />
                    </div>

                </Container>

            </div>

        <div className="MicrositeDesigner__main">
            <Container>

                <div className="MicrositeDesigner__main__buttons">

                    <BTDButton
                        label='Preview'
                        startIcon={<EyeIcon colour={'#1565c0'} style={{height: '24px', marginRight: '8px', width: '24px'}} />}
                        target='_blank'
                        type='outlined'
                        url={event && event.microsite ? event.microsite.url : ''}
                    />
                    <BTDButton
                        label='Settings'
                        onClick={() => {setSettingsFormActive(true)}}
                        startIcon={<CogIcon colour={'#1565c0'} style={{height: '22px', marginRight: '8px', width: '22px'}} />}
                        type='outlined'
                    />
                        

                </div>

                <div className="MicrositeDesigner__main__sections">

                    <MicrositeSectionList 
                        eventId={eventId}
                        onEdit={(section) => {
                            setSection(section)
                            setSectionFormActive(true);
                        }}
                        onDelete={() => {onChange()}}
                        onMove={reorderSections}
                        items={sections}  
                        max_width={settings.max_width}
                        show_edit={true}
                        start_date={event.start_at}
                    />
                    
                </div>

                <MicrositeToolbar eventId={eventId} onSubmit={() => {onChange()}}/>

            </Container>
        </div>

            {sectionFormActive &&
                <EditMicrositeSectionScreen 
                    eventId={eventId}
                    onClose={() => {setSectionFormActive(false)}}
                    onSubmit={() => {
                        onChange();
                        setSectionFormActive(false);
                    }}
                    section={section}
                />
            }

            {settingsFormActive &&
                <MicrositeSettingsForm
                    eventId={eventId}
                    onClose={() => {setSettingsFormActive(false)}}
                    onSubmit={updateMicrositeSettings}
                    settings={settings}
                />
            }

            </div>
    )
}

export default MicrositeDesigner;