// Packages
import { useState } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import BTDTab from 'components/BTDUI/BTDTab';

// Other
import './styles.css';
import FormTemplate from 'components/BTDUI/FormTemplate';
import QRProcessing from 'components/QRProcessing';
import DownloadPDF from 'components/BTDUI/DownloadPDF';
import TicketIcon from 'components/BTDUI/icons/TicketIcon';
import EyeIcon from 'components/BTDUI/icons/EyeIcon';
import DownloadIcon from 'components/BTDUI/icons/DownloadIcon';

const AttendeesListItem = ({ isLoading, order_item, order, ticket_type, } ) => {

	const [formActive, setFormActive] = useState(false);

	return (
		<div className='AttendeesListItem'>

			{isLoading ?
			
				<>
					<div className='AttendeesListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='AttendeesListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='AttendeesListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='AttendeesListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='AttendeesListItem__value'></div>
				</>
			:

				<>

					<div className='AttendeesListItem__value AttendeesListItem__value-name'>
						{order_item.guest_name ? 
							<p className='medium'>{order_item.guest_name}</p>
						:
							<p className='red medium'>Not Provided</p>
						}
					</div>
					<div className='AttendeesListItem__value'>
						<p className='medium'>{ticket_type ? ticket_type.name : ''}</p>
					</div>
					<div className='AttendeesListItem__value'>
						<p className='medium'>{order && order.contact ? order.contact.firstname + ' ' + order.contact.lastname : ''}</p>
					</div>
					<div className='AttendeesListItem__value'><BTDTab type={order_item.status ? order_item.status : 'unknown'} /></div>
					<div className='AttendeesListItem__value'>

						<BTDMenu icon={<EllipsisIcon height="18px"/>}>
							<li onClick={() => {setFormActive(true)}}><TicketIcon height='20px' style={{marginRight: '8px', transform: 'translateY(4px)'}} width='20px'/>Ticket Details</li>
							{! isLoading &&
								<DownloadPDF 
										default_filename={ticket_type.name.name + '_' + order.guest_name + '.pdf'}
										text='Download Ticket'
										url={'https://api.events.oakora.com/orders/' + order.id + '/items/' + order_item.id + '/ticket'}
									>
										<DownloadIcon height='20px' style={{marginRight: '8px', transform: 'translateY(4px)'}} width='20px'/>
								</DownloadPDF>
							}
							<Link 
								className='black' 
								style={{color: '#253551'}}
								to={'/orders/' + order.id} 
							>
								<EyeIcon colour='#253551' height='20px' style={{marginRight: '8px', transform: 'translateY(4px)'}} width='20px'/>
								View Order
							</Link>
						</BTDMenu>

					</div>

					{formActive &&
						<FormTemplate onClose={() => {setFormActive(false)}}>

							<h3 className="form__title medium">Ticket Details</h3>

							<div className='form__grid-2' style={{position: 'relative'}}>
								<div>

									<p className='semi-bold'>Ticket Type</p>
									<p className='medium'>{isLoading ? <BTDSkeleton width='100px' /> : ticket_type ? ticket_type.name : ''}</p>
									<br/>
									<p className='semi-bold'>Guest Name</p>
									<p className='medium'>{isLoading ? <BTDSkeleton width='100px' /> : order_item.guest_name ?? ''}</p>
									<br/>
									<p className='semi-bold'>Serial Number</p>
									<p className='medium'>{isLoading ? <BTDSkeleton width='100px' /> : order_item.serial ?? ''}</p>
									<br/>
									
								</div>
								<div>
									{! order_item.qr_code_url &&
										<QRProcessing style={{position: 'absolute', right: '0', top: '0'}}/>
									}
									<img 
										alt='QR code'
										src={order_item.qr_code_url} 
										style={{position: 'absolute', right: '0', top: '0'}}
										width='150' 
									/>
								</div>
							</div>

						</FormTemplate>
					}
				</>

			}

		</div>
	)
}

AttendeesListItem.propTypes = {
	event: PropTypes.object,
	guest_name: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	index: PropTypes.number,
	isLoading: PropTypes.bool,
	onSubmit: PropTypes.func,
	status: PropTypes.string,
	ticket_type: PropTypes.object,
	total: PropTypes.string,
}

AttendeesListItem.defaultProps = {
	event: {},
	guest_name: null,
	id: null,
	index: null,
	isLoading: false,
	fulfilment_status: '',
	onSubmit: () => {alert('onSubmit is not set!')},
	status: null,
	ticket_type: {},
	total: null
}

export default AttendeesListItem;