// Packages
import { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import Layout from 'layouts/OrderView';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import OrderRefundTicketList from 'components/Order/RefundTicketList';
import BTDButton from 'components/BTDUI/BTDButton';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const OrderRefundViewScreen = () => {

	const { orderId } = useParams();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const ticketId = queryParams.get('id');

	const navigate = useNavigate();

	const { currency } = useAppData();

	const { data: order, isLoading: orderLoading } = useApi('/orders/' + orderId + '/?with=event');
	const { data: tickets, isLoading: ticketsLoading } = useApi('/orders/' + orderId + '/items?with=ticket_type,event');
	const { onChange: onRefundsChange, post: createRefund} = useApi('/orders/' + orderId + '/refunds');

	const [ticketsToRefund, setTicketsToRefund] = useState(0);
	const [amountToRefund, setAmountToRefund] = useState(0);
	const [data, setData] = useState([]);
	

	useEffect(() => {

		var numTickets = 0;
		var amount = 0;

		for( let i = 0; i < data.length; i++ ) {

			if( data[i].value ) {

				numTickets++;
				amount += data[i].price;
			}
		}

		setTicketsToRefund(numTickets);
		setAmountToRefund(amount);


		console.log('in useEffect. data: ', data);

	}, [data])

	
	return (
		<Layout order={order} isLoading={orderLoading}>
			
			<div className='OrderRefundView'>

				<div className='OrderRefundView__main'>

					<Link className='OrderRefundView__main__backLink black' to={'/orders/' + orderId}>&#8592; Back to order</Link>

					<h3 className='black'>Refund Tickets</h3>
					
					<OrderRefundTicketList 
						items={tickets} 
						isLoading={ticketsLoading} 
						onSelect={(newObject) => {

							const indexToUpdate = data.findIndex(obj => obj.id === newObject.id);
							var updatedArray;

							if (indexToUpdate !== -1) {

								updatedArray = [...data];
								updatedArray[indexToUpdate] = newObject;
							}
							else {

								updatedArray = [...data, newObject];
							}

							setData(updatedArray);
						}} 
						ticketId={ticketId}
					/>

				</div>

				<div className='OrderRefundView__sidebar'>

					<div className='OrderRefundView__sidebar__block'>

						<div className='OrderRefundView__sidebar__block__header'>
							Refund Summary
						</div>

						<div className='OrderRefundView__sidebar__block__main'>

							<p className='OrderRefundView__sidebar__block__main__entry'>
								<span className='OrderRefundView__sidebar__block__main__entry__label'>Tickets to refund:</span>
								<span className='OrderRefundView__sidebar__block__main__entry__value semi-bold'>{ticketsToRefund}</span>
							</p>

							<p className='OrderRefundView__sidebar__block__main__entry'>
								<span className='OrderRefundView__sidebar__block__main__entry__label'>Amount to refund:</span>
								<span className='OrderRefundView__sidebar__block__main__entry__value semi-bold'>{currency(amountToRefund)}</span>
							</p>

							<BTDButton
								colour='red'
								disabled={ticketsToRefund === 0}
								formButton={true}
								label={`Refund ${ticketsToRefund} ticket${ticketsToRefund !== 1 ? 's' : ''}`}
								onClick={async () => {
									const arrayOfIds = data.filter(obj => obj.value === true).map(obj => obj.id);

									if( await createRefund({order_item_id: arrayOfIds}, 'Refund request submitted')) {

										onRefundsChange();
										navigate('/orders/' + orderId);
									}
								}}
								style={{padding: '18px 20px'}}
								size='large'
							/>

						</div>

					</div>
					
					<div className='OrderRefundView__sidebar__block'>

						<div className='OrderRefundView__sidebar__block__header'>
							Customer
						</div>

						<div className='OrderRefundView__sidebar__block__main'>

							<Link className='OrderRefundView__sidebar__block__main__link semi-bold light-blue' to={`/contacts/${order && order.contact ? order.contact.id : ''}`}>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.firstname + ' ' + order.contact.lastname : ''}</Link>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.organisation : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.address1 : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.address2 : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.city : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.postcode : ''}</p>
							<br/>
							<a href={'mailto:' + order.email} className='OrderRefundView__sidebar__block__main__link black medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.email : ''}</a>
							<br/>
							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.telephone : ''}</p>
							<br/>

						</div>

					</div>

				</div>

			</div>

		</Layout>
	)

}

export default OrderRefundViewScreen;