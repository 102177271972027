// Packages
import { useState, useEffect, useRef  } from 'react';
import PropTypes from 'prop-types';

// Components
import CheckedLink from 'components/BTDUI/CheckedLink';

// Other
import './styles.css';

const BTDMenuDropdown = ({ description, current, links, style }) => {

    const [active, setActive] = useState(false);
    const BTDMenuDropdownRef = useRef(null);

    const handleDocumentClick = (e) => {
        if (BTDMenuDropdownRef.current && ! BTDMenuDropdownRef.current.contains(e.target)) {
            setActive(false);
        }
    };

    useEffect(() => {
        if (active) {
            document.addEventListener('click', handleDocumentClick);
        } else {
            document.removeEventListener('click', handleDocumentClick);
        }
    
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [active]);

    return(
        <>
        {description &&
            <p className='BTDMenuDropdown__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div 
            className={`BTDMenuDropdown`} 
            onClick={() => {setActive(! active)}} 
            ref={BTDMenuDropdownRef} 
            style={style}
        >
            <label className="BTDMenuDropdown__label">{current ?? ''}</label>
            <span className={`BTDMenuDropdown__downArrow ${active ? 'active' : ''}`}></span>
            {active &&
                <div className="BTDMenuDropdown__dropdown" >
                    {links.length > 0 &&
                        links.map((link, index) => (
                            <li key={index}>
                                <CheckedLink to={link.url}>{link.label}</CheckedLink>
                            </li>
                        ))
                    }
                </div>
            }
        </div>
        </>
    )
}

BTDMenuDropdown.propTypes = {
    description: PropTypes.string,
    current: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    style: PropTypes.object,
}

BTDMenuDropdown.defaultProps = {
    description: null,
    current: '',
    links: [],
    style: {},
}

export default BTDMenuDropdown;                                         