// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs';

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import useApi from 'hooks/useApi';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import BTDAutoCompleteField from 'components/BTDUI/fields/BTDAutoCompleteField';

const EventForm = ({ errors, event, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [doorsOpenTime, setDoorsOpenTime] = useState(false)
    
    // Get Venues
    const { data: venues } = useApi('/venues');

    const submit = () => {

		onSubmit(changedData);
	}

    // Load Event values into form
	useEffect(() => {

		if ( event )
		{
            setData(event);

            if( event.doors_at != null ) {
                setDoorsOpenTime(true);
            }
		}

	}, [event]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create event' : 'Edit event'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Event name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDAutoCompleteField
                error={errors && errors.venue_id ? errors.venue_id : null} 
                label='Venue'
                onChange={(value) => {
                    setData({...data, venue_id: value});
                    setChangedData({...changedData, venue_id: value});
                }} 
                options={venues}
                value={data.venue_id ?? ''} 
            />

            <div className='form__grid-2'>
                <BTDDatepicker
                    error={errors && errors.start_at ? errors.start_at : null} 
                    label="Starts at"
                    onChange={(value) => {
                        setData({...data, start_at: value});
                        setChangedData({...changedData, start_at: value});
                    }}
                    value={data.start_at ? dayjs(data.start_at) : null}
                />

                <BTDDatepicker
                    error={errors && errors.end_at ? errors.end_at : null} 
                    label="Ends at"
                    onChange={(value) => {
                        setData({...data, end_at: value});
                        setChangedData({...changedData, end_at: value});
                    }}
                    value={data.end_at ? dayjs(data.end_at) : null}
                />
            </div>

            
            {! doorsOpenTime &&
                <p className='form__link' onClick={() => {setDoorsOpenTime(! doorsOpenTime)}}>Set doors open time</p>
            }

            {doorsOpenTime &&
                <div className='form__grid-2'>
                <BTDDatepicker
                    error={errors && errors.doors_at ? errors.doors_at : null} 
                    label="Doors Open"
                    onChange={(value) => {
                        setData({...data, doors_at: value});
                        setChangedData({...changedData, doors_at: value});
                    }}
                    value={data.doors_at ? dayjs(data.doors_at) : null}
                />

                    <ClearIcon colour='#BEBEBE' height='20px' onClick={() => {setDoorsOpenTime(! doorsOpenTime)}} style={{cursor: 'pointer', display: 'block', marginBottom: '28px'}}/>

                </div>
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save event' : 'Update event'}
                onClick={submit} 
                type="contained" 
            ></BTDButton>

        </FormTemplate>
    )

}

EventForm.propTypes = {
	errors: PropTypes.object,
	event: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

EventForm.defaultProps = {
    errors: {},
    event: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default EventForm;