// Packages
import ListItem from 'components/Attendees/ListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const AttendeesList = ({ isLoading, items, onSubmit, }) => {

	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress type='circular' height={100} style={{margin: '20px auto'}} width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
		<h3 className='italic'>No tickets to show here.</h3>
		)
	}
	else {
		return(
			<div className='AttendeesList'>

				<div className='AttendeesList__header'>

					<p className='medium'>Guest Details</p>
					<p className='medium'>Ticket Type</p>
					<p className='medium'>Ordered by</p>
					<p className='medium'></p>
					<p className='medium'></p>

				</div>
					
				{items.map((item, index) => {

					return(
						<div key={index}>
							<ListItem {...item} index={index} isLoading={false} onSubmit={onSubmit}/>
						</div>
						)
					}
				)}
			</div>
		)
	}

}

AttendeesList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onSubmit: PropTypes.func,
}

AttendeesList.defaultProps = {
	isLoading: false,
	items: {},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default AttendeesList;