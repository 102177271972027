// Packages
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

const RefundViewLayout = ({ children, refund, isLoading }) => {

    return (
		<>
      <TitleBar isLoading={isLoading} title={`${refund.reference} - ${refund.contact ? refund.contact.firstname + ' ' + refund.contact.lastname : ''}`}>

        {isLoading ? <BTDSkeleton style={{width: '150px'}}/> : dayjs(refund.created_at).format('D MMM YYYY HH:mm')}

      </TitleBar>

      <Container>
        {children}
      </Container>
    </>
	)

}

RefundViewLayout.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  refund: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

RefundViewLayout.defaultProps = {
  children: null,
  isLoading: false,
  refund: {}, 
}

export default RefundViewLayout;