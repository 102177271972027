// Packages
import ListItem from 'components/Order/ListItem';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Other
import './styles.css';

const OrderList = ({ isLoading, items }) => {

	return(
		<div className="OrderList">
			<div className="OrderList__header">

				<div className="OrderList__header__heading medium black"># / Date & Time</div>
				<div className="OrderList__header__heading medium black">Customer</div>
				<div className="OrderList__header__heading medium black">Event</div>
				<div className="OrderList__header__heading medium black">Total</div>
				<div className="OrderList__header__heading"></div>
				<div className="OrderList__header__heading"></div>

			</div>
		{isLoading &&
		
			<ListItem isLoading={true}/>
		}
		{items.length === 0 &&
		
			<h3 className='italic'>No orders to show here.</h3>
		}
		{!isLoading && items.length !== 0 &&
			items.map((item) => {

				return (
					<Link key={item.id} to={'/orders/' + item.id}>
						<ListItem  {...item} isLoading={false} />
					</Link>
						)
				
			})
		}
		</div>
	)

}

OrderList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

OrderList.defaultProps = {
	isLoading: false,
	items: {},
}

export default OrderList;