// Packages
import { useState } from 'react'

// Components
import EventsLayout from "layouts/Events"
import EventList from 'components/Event/List';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import EventCreateScreen from 'screens/Events/Create';
import EventEditScreen from 'screens/Events/Edit';

// Other
import useApi from 'hooks/useApi';

const EventListScreen = () => {

	const [formActive, setFormActive] = useState(false);
	const [eventId, setEventId] = useState('');
	const [formType, setFormtype] = useState('create');

	const { data: events, isLoading, onChange } = useApi('/events/archived?with=venue');
	
	return (
		<EventsLayout activeTab="/events/archived">

			<EventList 
				items={events} 
				isLoading={isLoading}
				onDelete={() => {onChange()}}
				launchForm={(id) => {
					setEventId(id);
					setFormtype('edit');
					setFormActive(true);
				}}
				/>

			<AddIcon onClick={() => {
				setFormtype('create');
				setFormActive(true);
			}} />

			{formActive &&
			<>
				{formType === 'create' &&
					<EventCreateScreen onClose={() => {
						onChange();
						setFormActive(false);
					}} />
				}
				{formType === 'edit' &&
					<EventEditScreen eventId={eventId} onClose={() => {
						onChange();
						setFormActive(false);
					}} />
				}
			</>
			}
	</EventsLayout>
	)

}

export default EventListScreen