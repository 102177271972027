// Packages
import PropTypes from 'prop-types';

// Component
import ContactForm from 'components/Contact/Form';

// Other
import useApi from 'hooks/useApi';

const ContactEditScreen = ({ contactId, onClose, onSubmit }) => {

    const { data: contact, isLoading, put: updateContact, errors } = useApi('/contacts/' + contactId);

    const submit = async (values) => {

        if( await updateContact(values, 'Contact updated!')){

            onSubmit();
        }
    }

    return(
        <ContactForm errors={errors} contact={contact} isLoading={isLoading} onClose={onClose} onSubmit={submit} type='edit' />
    )
}

ContactEditScreen.propTypes = {
    isLoading: PropTypes.bool,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

ContactEditScreen.defaultProps = {
    isLoading: false,
    contactId: null,
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default ContactEditScreen;