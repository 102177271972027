// Packages
import PropTypes from 'prop-types';

// Component
import SpeakersForm from 'components/Speaker/Form';

// Other
import useApi from 'hooks/useApi';

const EditSpeakerScreen = ({ eventId, onClose, onSubmit, speakerId }) => {

    const { data: speaker, put: updateSpeaker, errors } = useApi('/events/' + eventId + '/speakers/' + speakerId);

    const submit = async (values) => {

        if( await updateSpeaker(values, 'Accommodation updated!')){

            onSubmit();
        }
    }

    return(
        <SpeakersForm errors={errors} speaker={speaker} onClose={onClose} onSubmit={submit} type='edit' />
    )
}

EditSpeakerScreen.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    speakerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditSpeakerScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    speakerId: null
}

export default EditSpeakerScreen;