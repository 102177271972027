// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/EventsView';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import CreateWaitingListItemScreen from './Create';
import EditWaitingListItemScreen from './Edit';
import WaitingList from 'components/WaitingList/List';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';
import BTDPagination from 'components/BTDUI/BTDPagination';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const EventViewWaitingScreen = () => {

	const { eventId } = useParams();

	const [createFormActive, setCreateFormActive] = useState(false);
	const [editFormActive, setEditFormActive] = useState(false);
	const [itemId, setItemId] = useState(null);
	const [page, setPage] = useState(1);
	const [listEnabled, setListEnabled] = useState(false);
	
	const { data: event, isLoading, onChange: eventOnChange } = useApi('/events/' + eventId + '?with=waitinglist&page=' + page);
	const { put: updateEvent } = useApi('/events/' + eventId + '/waitinglist', false);
	const { data: items, isLoading: itemsLoading, meta, onChange } = useApi('/events/' + eventId + '/waitinglist/items?page=' + page);

	useEffect(() => {

		if( event && event.waitinglist ) {

			setListEnabled(event.waitinglist.enabled);
		}
	}, [event]);

	
	return (
		<>
		<Layout activeTab={`/events/${eventId}/waiting`} event={event} isLoading={isLoading} onSubmit={() => {eventOnChange()}}>

			<div className='WaitingList'>

				<div className='WaitingList__main'>

					<p className='WaitingList__main__text'>There {! itemsLoading && items.length === 1 ? 'is' : 'are'} {! itemsLoading ? items.length : 0} on the waiting list for this event</p>

					{items && items.length > 0 &&
						<>
						<div className='WaitingList__main__items'>

							<WaitingList 
								items={items} 
								eventId={eventId} 
								launchForm={(id) => {
									setItemId(id);
									setEditFormActive(true);
								}}
								onDelete={() => {
									setEditFormActive(false);
									onChange();
								}}
							/>
						</div>

						{meta && meta.total > meta.per_page &&
							<BTDPagination
								currentPage={meta.current_page} 
								totalPages={meta.last_page}
								onChange={(value) => {
									setPage(value);
									eventOnChange();
								}}
								style={{margin: '0 auto', width: 'max-content'}}
							/>
						}	

						</>
					}

				</div>

				<div className='WaitingList__sidebar'>

					<div className='WaitingList__sidebar__block'>

						<div className='WaitingList__sidebar__block__header'>Waiting List</div>

						<div className='WaitingList__sidebar__block__main'>

							<div className='WaitingList__sidebar__block__main__text'>
								<BTDSwitch
									checked={listEnabled}
									label="Enable waiting list" 
									onChange={(value) => {
										updateEvent({enabled: value }, value ? 'Waiting list enabled!' : 'Waiting list disabled', false);
										setListEnabled(value)
									}}
								/>
							</div>

						</div>

					</div>

					<div className='WaitingList__sidebar__block'>

						<div className='WaitingList__sidebar__block__header'>Future Waiting List Settings</div>

						<div className='WaitingList__sidebar__block__main'></div>

					</div>

				</div>

			</div>


		</Layout>

		<AddIcon onClick={() => {setCreateFormActive(true)}} />

		{createFormActive && 
		
			<CreateWaitingListItemScreen 
				eventId={eventId} 
				onClose={() => {setCreateFormActive(false)}} 
				onSubmit={() => {
					setCreateFormActive(false);
					onChange();
				}}
			/>
		}
		{editFormActive && 
		
			<EditWaitingListItemScreen 
				eventId={eventId} 
				itemId={itemId}
				onClose={() => {setEditFormActive(false)}} 
				onSubmit={() => {
					setEditFormActive(false);
					onChange();
				}}
			/>
		}
		</>
	)

}

export default EventViewWaitingScreen