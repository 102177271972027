// Packages
import PropTypes from 'prop-types';

// Component
import TicketTypeForm from 'components/TicketType/Form';

// Other
import useApi from 'hooks/useApi';

const EditTicketTypeScreen = ({ eventId, onClose, onSubmit, ticketTypeId }) => {

    const { data: ticketType, put: updateTicketType, errors } = useApi('/events/' + eventId + '/tickettypes/' + ticketTypeId);

    const submit = async (values) => {

        if( await updateTicketType(values, 'Ticket Type updated!')){

            onSubmit();
        }
    }

    return(
        <TicketTypeForm errors={errors} ticketType={ticketType} onClose={onClose} onSubmit={submit} type='edit' />
    )
}

EditTicketTypeScreen.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    ticketTypeId: PropTypes.number
}

EditTicketTypeScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    ticketTypeId: null
}

export default EditTicketTypeScreen;