// Packages
import PropTypes from 'prop-types';

// Component
import TicketFulfillForm from 'components/Order/TicketFulfillForm';

// Other
import useApi from 'hooks/useApi';

const FulfillOrderTicketScreen = ({ orderId, itemId, onClose, onSubmit }) => {

    const { data: order } = useApi('/orders/' + orderId)
    const { data: ticket, isLoading, } = useApi('/orders/' + orderId + '/items/' + itemId + '/?with=ticket_type');
    const { post: fulfill, errors } = useApi('/orders/' + orderId + '/items/' + itemId + '/fulfil', false);

    const submit = async (values) => {

        if( await fulfill(values, 'Ticket fulfilled!')){

            onSubmit();
        }
    }

    return(
        <TicketFulfillForm 
            email={order && order.contact ? order.contact.email : ''}
            errors={errors} 
            isLoading={isLoading}
            itemId={itemId} 
            onClose={onClose} 
            onSubmit={submit} type='edit'
            orderId={orderId} 
            ticket={ticket} 
        />
    )
}

FulfillOrderTicketScreen.propTypes = {
    isLoading: PropTypes.bool,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

FulfillOrderTicketScreen.defaultProps = {
    isLoading: false,
    itemId: null,
    orderId: '',
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default FulfillOrderTicketScreen;