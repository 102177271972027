// Components
import Layout from 'layouts/Dashboard';

// Other


const DashboardScreen = () => {

	
	return (
		<Layout>
		<div>Dashboard</div>

		</Layout>
	)

}

export default DashboardScreen