// Packages
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import SellingDates from 'components/SellingDates';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// Other
import useApi from 'hooks/useApi';
import './styles.css';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

const TicketTypeListItem = props => {

	const { available, capacity, end_at, eventId, id, isLoading, launchForm, name, onDelete, price, start_at, status } = props;

	const { currency } = useAppData();

	const { destroy: deleteTicketType } = useApi('/events/' + eventId + '/tickettypes/' + id, false);

	const deleteItem = async () => {

        await deleteTicketType('ticket type', 'Ticket Type deleted!');

		onDelete();
    }

	return (
		
		<div className="TicketTypeItem">

			<div className='TicketTypeItem__section TicketTypeItem__section-name'>

				<h3 className='blue'>{isLoading ? <BTDSkeleton height='20px' /> : name}</h3>

				<p className='black opacity'>{isLoading ? <BTDSkeleton /> : currency(price) }</p>

			</div>

			<div className='TicketTypeItem__section TicketTypeItem__section-date'>

				<label className='black medium opacity'>Status</label>

				<SellingDates start_timestamp={start_at} end_timestamp={end_at} status={status} />

			</div>

			<div className='TicketTypeItem__section TicketTypeItem__section-capacity centre'>

				<label className='black medium'>Capacity</label>

				<p className='red'>{! isLoading ? capacity ? capacity.toLocaleString('en-GB') : '' : <BTDSkeleton />}</p>

			</div>

			<div className='TicketTypeItem__section TicketTypeItem__section-available centre'>

				<label className='black medium'>Available</label>

				<p className='green'>{! isLoading ? available ? available.toLocaleString('en-GB') : '' : <BTDSkeleton />}</p>

			</div>

			<div className='TicketTypeItem__section TicketTypeItem__section-menu'>

				<BTDMenu icon={<EllipsisIcon height="18px"/>}>
					<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
					<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
				</BTDMenu>

			</div>

		</div>
	)
}

TicketTypeListItem.propTypes = {
	available: PropTypes.number,
	capacity: PropTypes.number,
	earlybird: PropTypes.bool,
	end_timestamp: PropTypes.string,
	eventId: PropTypes.number,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	start_timestamp: PropTypes.string, 
	status: PropTypes.string, 
}

TicketTypeListItem.defaultTypes = {
	available: null,
	capacity: null,
	earlybird: false,
	end_timestamp: '',
	eventId: null,
	id: null,
	isLoading: false,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set')},
	price: null,
	start_timestamp: '',
	status: 'disabled'
}

export default TicketTypeListItem;