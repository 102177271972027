// Packages
import { Routes, Route } from "react-router-dom"

// Routes
import EventRoutes from 'routes/events'
import OrderRoutes from 'routes/orders'
import RefundRoutes from 'routes/refunds'
import ContactRoutes from 'routes/contacts'
import VenueRoutes from 'routes/venues'
import SettingsRoutes from 'routes/settings'
import MiscRoutes from 'routes/misc'


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/events/*" element={<EventRoutes/>}/>
			<Route path="/orders/*" element={<OrderRoutes/>}/>
			<Route path="/refunds/*" element={<RefundRoutes/>}/>
			<Route path="/contacts/*" element={<ContactRoutes/>}/>
			<Route path="/venues/*" element={<VenueRoutes/>}/>
			<Route path="/settings/*" element={<SettingsRoutes/>}/>
			<Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	)
}


export default MainRoutes