import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';

const VenuesLayout = props => {

	return (
		<>
		<TitleBar title="Venues">
            
        </TitleBar>
        <Container>
        	{props.children}
        </Container>
        </>
	)
}

export default VenuesLayout