// Packages
import ListItem from 'components/Refund/ListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const RefundList = ({ isLoading, items }) => {


	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress type='circular' height={100} style={{margin: '20px auto'}} width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
		<h3 className='italic'>No refunds to show here.</h3>
		)
	}
	else {
		return (
			<div className='RefundList'>

				<div className='RefundList__header'>
					<p className='medium'># / Date & Time</p>
					<p className='medium'>Customer</p>
					<p className='medium'>Event</p>
					<p className='medium'>Total</p>
					<p className='medium'></p>
				</div>

				{isLoading ? 
				
					<ListItem isLoading={true}/>
				:
					items.map((item) => {

						return (
								<ListItem key={item.id} {...item} isLoading={false} />
								)
					
					})
				}
			</div>
		)
	}
}

RefundList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

RefundList.defaultProps = {
	isLoading: false,
	items: {},
}

export default RefundList;