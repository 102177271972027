// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const TicketIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="TicketIcon" onClick={onClick} style={style}>
            <svg height={height} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} width={width}>
                <g fill={colour}>
                    <g>
                        <path d="M466.089,139.805c-25.928,25.928-67.966,25.928-93.895,0c-25.929-25.928-25.928-67.967,0-93.895L326.284,0L0,326.284
                            l45.911,45.911c25.928-25.928,67.967-25.928,93.895,0c25.928,25.928,25.928,67.967,0,93.895l45.91,45.91L512,185.716
                            L466.089,139.805z M255.519,182.946l-21.298-21.298l21.213-21.213l21.298,21.298L255.519,182.946z M298.115,225.543
                            l-21.299-21.298l21.213-21.213l21.299,21.298L298.115,225.543z M340.711,268.14l-21.298-21.299l21.213-21.213l21.298,21.299
                            L340.711,268.14z"/>
                    </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
            </svg>
        </div>
    )
}

TicketIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TicketIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default TicketIcon;