// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDSelectField = ({ description, error, label, onChange, options, required, style, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value !== null && value !== '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <>
        {description &&
            <p className='BTDSelectField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDSelectField ${width} `} style={style}>
            <label className={`BTDSelectField__label ${active ? 'active' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'> *</span>
                }
            </label>
            <span className={`BTDSelectField__downArrow ${active ? 'active' : ''}`}></span>
            <select className={`BTDSelectField__input ${error ?? ''}`} onChange={(e) => {onChange(e.target.value)}} required={required} value={value}>
                <option value={null}></option>
                    {options.map((option, index) => (
                        <option key={index} value={option.id}>
                            {option.name}
                        </option>
                    ))}
            </select>
            {error &&
                <p className='BTDSelectField__error'>{error}</p>
            }
            
        </div>
        </>
    )
}

BTDSelectField.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string.isRequired,
        }),
    ),
    required: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string
}

BTDSelectField.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    options: [],
    required: false,
    style: {},
    value: null,
    width: 'large'
}

export default BTDSelectField;                                         