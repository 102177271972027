// Packages
import PropTypes from 'prop-types';
import { HexColorPicker, HexColorInput } from "react-colorful";

// Other
import './styles.css';

const BTDColourPicker = ({ description, error, label, onChange, required, value, width }) => {

    return(
        <>
        {description &&
            <p className='BTDColourPicker__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDColourPicker ${width} `}>

            <label className={`BTDColourPicker__label`}>{label ?? ''}
                {required &&
                    <span className='red'> *</span>
                }
            </label>

            <HexColorPicker
                color={value ?? '#000'} 
                onChange={(value) => {onChange(value)}} 
                style={{marginBottom: '8px'}}
            />
            <span className='BTDColourPicker__hash opacity'>#</span>
            <HexColorInput 
                color={value ?? '#000'} 
                onChange={(value) => {onChange(value)}} 
            />

            {error &&
                <p className='BTDColourPicker__error'>{error}</p>
            }
        </div>
        </>
    )
}

BTDColourPicker.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.string,
    width: PropTypes.string
}

BTDColourPicker.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    style: {},
    value: '#000',
    width: 'large'
}

export default BTDColourPicker;                                         