// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDTab from 'components/BTDUI/BTDTab';

// Other
import './styles.css';

const RefundListItem = ({ created_at, contact, event, id, isLoading, number_of_tickets, reference, status, total }) => {

	const { currency, datetime } = useAppData();

	return (
		<div className='RefundListItem'>

			<div className='RefundListItem__value'>
				<Link className='semi-bold light-blue' to={'/refunds/' + id}>{isLoading ? <BTDSkeleton /> : reference}</Link>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : datetime(created_at)}</p>
			</div>
			<div className='RefundListItem__value'>
				<Link className='semi-bold light-blue' to={'/customers/' + contact.id}>{isLoading ? <BTDSkeleton /> : contact ? contact.firstname + ' ' + contact.lastname : ''}</Link>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : contact ? contact.city : ''}</p>
			</div>
			<div className='RefundListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : event ? event.name : ''}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : number_of_tickets ?? '0'} ticket{number_of_tickets !== 1 ? 's': ''}</p>
			</div>
			<div className='RefundListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : currency(total)}</p>
			</div>

			<div className='RefundListItem__value'>
				<BTDTab type={status} />
			</div>

		</div>
	)
}

RefundListItem.propTypes = {
	created_at: PropTypes.string,
	contact: PropTypes.object,
	event: PropTypes.object,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	reference: PropTypes.string,
	status: PropTypes.string,
	total: PropTypes.string,
}

RefundListItem.defaultProps = {
	created_at: '',
	contact: {},
	event: {},
	id: null,
	isLoading: true,
	reference: '',
	status: '',
	total: ''
}

export default RefundListItem;