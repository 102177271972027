// Packages
import { useState } from 'react'

// Components
import VenueCreateScreen from 'screens/Venues/Create';
import VenueEditScreen from 'screens/Venues/Edit';
import VenueList from 'components/Venue/List';
import AddIcon from 'components/BTDUI/icons/BTDFAB';

// Other
import useApi from 'hooks/useApi'

const VenueListScreen = () => {

	const [formActive, setFormActive] = useState(false);
	const [venueId, setVenueId] = useState('');
	const [formType, setFormtype] = useState('create');

	const { data: venues, isLoading, onChange } = useApi('/venues');

	
	return (
		<>
		<VenueList items={venues} isLoading={isLoading} launchForm={(venueId) => {
			setVenueId(venueId);
			setFormtype('edit');
			setFormActive(true);
		}} onDelete={() => {onChange()}}/>

		<AddIcon onClick={() => {
			setFormtype('create');
			setFormActive(true);
		}} />

			{formActive &&
			<>

				{formType === 'create' &&
					<VenueCreateScreen
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}
				{formType === 'edit' &&
					<VenueEditScreen 
						venueId={venueId} 
						onClose={() => {
							setFormActive(false);
						}} 
						onSubmit={() => {
							onChange();
							setFormActive(false);
						}} 
					/>
				}
			</>
			}
		</>
	)

}

export default VenueListScreen;