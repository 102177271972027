// Packages
import PropTypes from 'prop-types'

// Assets
import './styles.css'


const Overlay = ({ colour, children, onClick, style }) => {

	function overlayClicked() {

		onClick(true);
	}

	return (
		<div 
			className={`Overlay Overlay-${colour ?? ''}`} 
			onClick={overlayClicked}
			style={style}
		>
			{children}
		</div>
	)
}

Overlay.propTypes = {
	children: PropTypes.node,
	colour: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object
}

Overlay.defaultProps = {
	children: null,
	colour: '',
	onClick: () => {alert('onClick is not set!')},
	style: {}
}

export default Overlay