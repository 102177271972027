// Packages
import PropTypes from 'prop-types';

// Component
import EmailTemplateForm from 'components/EmailTemplate/Form';

// Other
import useApi from 'hooks/useApi';

const EditEmailTemplateScreen = props => {

    const { itemId, onClose, onSubmit } = props;

    const { data: item, put: updateItem, errors } = useApi('/emailtemplates/' + itemId);

    const submit = async (values) => {

        if( await updateItem(values, 'Email template updated!')){

            onSubmit();
        }
    }

    return(
        <EmailTemplateForm errors={errors} item={item} onClose={onClose} onSubmit={submit} />
    )
}

EditEmailTemplateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditEmailTemplateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
    itemId: ''
}

export default EditEmailTemplateScreen;