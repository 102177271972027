// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons
import dashboardIcon from './dashboard-icon.svg';
import libraryIcon from './library-icon.svg';
import broadcastIcon from './broadcast-icon.svg';
import eventsIcon from './events-icon.svg';
import enrolmentIcon from './enrolment-icon.svg';
import academyIcon from './academy-icon.svg';

// components
import List from 'components/AppSwitcher/List';

// other
import './styles.css';

const AppSwitcher = props => {

	const { active, toggleOverlay } = props;

	const apps = [
		{id: 1, name: 'Broadcast', icon: broadcastIcon, url: 'https://admin.broadcast.oakora.com'},
		{id: 2, name: 'Library', icon: libraryIcon, url: 'https://admin.library.oakora.com'},
		{id: 3, name: 'Dashboard', icon: dashboardIcon, url: ''},
		{id: 4, name: 'Academy', icon: academyIcon, url: 'https://admin.academy.oakora.com'},
		{id: 5, name: 'Events', icon: eventsIcon, url: 'https://admin.events.oakora.com'},
		{id: 6, name: 'Enrolment', icon: enrolmentIcon, url: 'https://admin.enrolment.oakora.com'},
	];

	const [dropdownActive, setDropdownActive] = useState(false);
	
	useEffect(() => {

		setDropdownActive(active);
	}, [active]);

	return (
		<>
		<div className="AppSwitcher" onClick={() => {
			setDropdownActive(! dropdownActive);
			toggleOverlay();
			}}>

			<div className="AppSwitcher__current">

				<img src={eventsIcon} alt={'Events'} className="AppSwitcher__icon"/>
					Events
			</div>

			<div className="AppSwitcher__dropdownArrow"/>
				{dropdownActive && 
					<div className="AppSwitcher__dropdown">
						<div className="AppSwitcher__dropdown__triangle" />
						<List items={apps} />
					</div>
				}
		</div>

		<div className='AppSwitcher__mobile AppSwitcher'onClick={() => {
			setDropdownActive(! dropdownActive);
			toggleOverlay();
			}}>
			<img src={eventsIcon} alt={'Events'} className="AppSwitcher__icon"/>
			{dropdownActive && 
				<div className="AppSwitcher__dropdown">
					<div className="AppSwitcher__dropdown__triangle" />
					<List items={apps} />
				</div>
			}
		</div>

		</>
	)
}

AppSwitcher.propTypes = {
	active: PropTypes.bool,
	toggleOverlay: PropTypes.func
}

AppSwitcher.defaultProps = {
	active: false,
	toggleOverlay: () => {alert('toggleOverlay is not set!')}
}

export default AppSwitcher;