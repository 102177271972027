// Packages
import PropTypes from 'prop-types'

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDTab from 'components/BTDUI/BTDTab';

// Other
import './styles.css';

const RefundItemListItem = ({ event, index, isLoading, status, ticket_type, total } ) => {

	const { currency, date } = useAppData();


	return (
		<div className='RefundItemListItem'>

			{isLoading ?
			
				<>
					<div className='RefundItemListItem__value'>
						<p><BTDSkeleton /></p>
						<p><BTDSkeleton /></p>
					</div>
					<div className='RefundItemListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='RefundItemListItem__value'>
						<p><BTDSkeleton /></p>
						<p><BTDSkeleton /></p>
					</div>
					<div className='RefundItemListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='RefundItemListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='RefundItemListItem__value'></div>
				</>
			:

				<>

					<div className='RefundItemListItem__value'>
						<p className='medium opacity centre'>#{index + 1}</p>
					</div>
					<div className='RefundItemListItem__value RefundItemListItem__value-name'>
						<p className='medium'>{ticket_type ? ticket_type.name : ''}</p>
					</div>
					<div className='RefundItemListItem__value'>
						<p className='semi-bold'>{event ? event.name : ''}</p>
						<p className='medium'>{event ? date(event.start_at) : ''}</p>
					</div>
					<div className='RefundItemListItem__value'>{currency(total)}</div>
					<div className='RefundItemListItem__value'><BTDTab type={status ?? 'unknown'} /></div>

				</>

			}

		</div>
	)
}

RefundItemListItem.propTypes = {
	event: PropTypes.object,
	index: PropTypes.number,
	isLoading: PropTypes.bool,
	status: PropTypes.string,
	ticket_type: PropTypes.object,
	total: PropTypes.string,
}

RefundItemListItem.defaultProps = {
	event: {},
	index: null,
	isLoading: false,
	status: null,
	ticket_type: {},
	total: null
}

export default RefundItemListItem;