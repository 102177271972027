// Packages
import PropTypes from 'prop-types';

// Components
import ContactForm from 'components/Contact/Form';

// Other
import useApi from 'hooks/useApi'

const ContactCreateScreen = props => {

    const { onClose, onSubmit } = props;

    const { post: createContact, errors } = useApi('/contacts', false);

    const submit = async (values) => {

        if( await createContact(values, 'Contact created!') ) {

            onSubmit();
        }
    }

    return(
        <ContactForm onClose={onClose} onSubmit={submit} type='create' errors={errors} />
    )
}

ContactCreateScreen.propTypes = {
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

ContactCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default ContactCreateScreen;