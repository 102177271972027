// Packages
import ListItem from 'components/Microsite/MicrositeSection/ListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const MicrositeSectionList = ({ eventId, isLoading, items, max_width, onEdit, onDelete, onMove, show_edit, start_date }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='MicrositeSectionList'>
				<div 
					sx={{ 
						display: 'flex', 
						justifyContent: 'center'
						}}
				>
					<BTDProgress type='circular' style={{height: '50px', width: '50px'}} />
				</div>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No sections to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='MicrositeSectionList' style={{maxWidth: `${max_width}px`}}>

			{items.map((item, index) => {

				return (
						<ListItem
							key={item.id} 
							eventId={eventId} 
							index={index} 
							isLoading={false} 
							onDelete={onDelete}
							onEdit={onEdit}
							onMove={onMove}
							section={item}
							show_edit={show_edit}
							start_date={start_date}
						/>
						)
				
			})}

		</div>
	)
}

MicrositeSectionList.propTypes = {
	eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	max_width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	onMove: PropTypes.func,
	show_edit: PropTypes.bool,
	start_date: PropTypes.string
}

MicrositeSectionList.defaultProps = {
	eventId: '',
	isLoading: false,
	items: {},
	max_width: '2000',
	onDelete: () => {alert('onDelete is not set!')},
	onEdit: () => {alert('onEdit is not set!')},
	onMove: () => {alert('onMove is not set!')},
	show_edit: false,
	start_date: ''
}

export default MicrositeSectionList;