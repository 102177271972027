// Packages
import ListItem from 'components/CustomField/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const CustomFieldList = ({ isLoading, items, launchForm, onDelete }) => {


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='CustomFieldList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='italic opacity'>No custom fields to show here.</h3>
		)
	}


	// Data State
	return (
		<div className='CustomFieldList'>

			{items.map((item) => {

				return (
					<ListItem 
						{...item} 
						key={item.id} 
						isLoading={false}
						launchForm={(id) => {launchForm(id)}}
						onDelete={onDelete} 
					/>
				)
				
			})}
	
		</div>
	)
}

CustomFieldList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

CustomFieldList.defaultProps = {
	isLoading: false,
	items: [],
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')}
}

export default CustomFieldList;