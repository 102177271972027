// Packages
import PropTypes from 'prop-types';

// others
import './styles.css'

const AppSwitcherListItem = ({ name, icon, url }) => {

	return (
		<a href={url} className="AppSwitcherListItem">

			<img src={icon} alt={name} className="AppSwitcherListItem__icon"/>
			<p className='AppSwitcherListItem__name black'>{name}</p>
		
		</a>
	)
}

AppSwitcherListItem.propTypes = {
	name: PropTypes.string,
	icon: PropTypes.string,
}

AppSwitcherListItem.defaultProps = {
	name: '',
	icon: '',
}


export default AppSwitcherListItem;