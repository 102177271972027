// Packages
import { Link } from 'react-router-dom';
import ListItem from 'components/Event/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const EventList = props => {

	const { isLoading, items } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='EventList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No events to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='EventList'>

			{items.map((item) => {

				return (
						<Link key={item.id} to={`/events/${item.id}`} style={{appearance: 'none', outline: 'none'}}>
							<ListItem {...item} isLoading={false}/>
						</Link>
				)
				
			})}
	
		</div>
	)
}

EventList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

EventList.defaultProps = {
	isLoading: false,
	items: [],
}

export default EventList;