// Packages
import { useState } from 'react'
import { useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/EventsView';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import AccommodationList from 'components/Accommodation/List';
import AccommodationCreateScreen from 'screens/Events/View-Accommodation/Create';
import AccommodationEditScreen from 'screens/Events/View-Accommodation/Edit';

// Other
import useApi from 'hooks/useApi';

const EventAccommodationScreen = () => {

	const { eventId } = useParams();

	const [formActive, setFormActive] = useState(false);
	const [accommodationId, setAccommodationId] = useState('');
	const [formType, setFormType] = useState('create');

	const { data: event, isLoading } = useApi('/events/' + eventId);
	const { data: accommodation, isLoading: accommodationLoading, onChange } = useApi('/events/' + eventId + '/accommodation');

	
	return (
		<Layout activeTab={`/events/${eventId}/accommodation`} isLoading={isLoading} event={event} onSubmit={() => {onChange()}}>

			<AccommodationList 
				eventId={eventId} 
				items={accommodation} 
				isLoading={accommodationLoading} 
				launchForm={(id) => {
					setAccommodationId(id);
					setFormType('edit');
					setFormActive(true);
				}} 
				onDelete={() => {onChange()}}
			/>

			<AddIcon onClick={() => {
				setFormType('create');
				setFormActive(true);
			}} />

			{formActive &&
			<>
					{formType === 'create' &&
						<AccommodationCreateScreen 
							eventId={eventId} 
							onClose={() => {
								onChange();
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}}
						/>
					}
					{formType === 'edit' &&
						<AccommodationEditScreen 
							eventId={eventId} 
							accommodationId={accommodationId} 
							onClose={() => {
								onChange();
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}}
						/>
					}
				</>
			}

		</Layout>
	)

}

export default EventAccommodationScreen;