// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const VenueListItem = ({ address, city, id, isLoading, launchForm, name,  postcode, onDelete, website }) => {

	const [addressArray, setAddressArray] = useState([]);

	useEffect(() => {

		if(address) {
			setAddressArray(address.split("\n"))
		}
	}, [address])

	const { destroy: destroyVenue } = useApi('/venues/' + id, false);

	const deleteVenue = async () => {

		await destroyVenue('venue', 'Venue deleted!');
		onDelete();
	}


	return (
		<div className="VenueListItem">

			<BTDMenu icon={<EllipsisIcon height="18px" />} style={{position: 'absolute', top: '12px', right: '10px'}}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={deleteVenue}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>

			<h3 className='blue'>{isLoading ? <BTDSkeleton height='20px' /> : name }</h3>

			{addressArray.length !== 0 ?

				addressArray.map(line => {
					return(
						<p key={line} className='medium black'>{line }</p>
					)
				})

			:
			'' 
			}


			<p className='medium black'>{isLoading ? <BTDSkeleton /> : city }</p>

			<p className='VenueListItem__postcode medium black'>{isLoading ? <BTDSkeleton /> : postcode }</p>

			<Link to={website} target='_blank'>{website}</Link>

		</div>
	)
}

VenueListItem.propTypes = {
	address: PropTypes.string,
	city: PropTypes.string,
	id: PropTypes.number,
	isLoading: PropTypes.bool,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
	postcode: PropTypes.string,
}

VenueListItem.defaultProps = {
	address: '',
	city: '',
	id: null,
	isLoading: false,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set!')},
	postcode: ''
}

export default VenueListItem