// Packages
import PropTypes from 'prop-types'

// Components
import TicketForm from 'components/Order/TicketForm';

// Other
import useApi from 'hooks/useApi';

const CreateOrderTicketScreen = ({ orderId, onClose, onSubmit }) => {

    const { post: createItem, errors } = useApi('/orders/' + orderId, false);

	const submit = async (values) => {

        if( await createItem(values, 'Ticket created!')) {

            onSubmit();
        }
    }

    return(
        <TicketForm errors={errors} onClose={onClose} onSubmit={submit} />
    )
}

CreateOrderTicketScreen.propTypes = {
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

CreateOrderTicketScreen.defaultProps = {
    orderId: '',
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
}

export default CreateOrderTicketScreen;