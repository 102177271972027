// Packages
import ListItem from 'components/Venue/ListItem';
import PropTypes from 'prop-types'

// Other
import './styles.css';

const VenueList = props => {

	const { isLoading, items, launchForm, onDelete } = props;

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='VenueList'>
				<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No venues to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='VenueList'>

			{items.map((item) => {

				return (
						<ListItem key={item.id} {...item} launchForm={launchForm} isLoading={false} onDelete={onDelete}/>
						)
				
			})}

			

		</div>
	)
}

VenueList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

VenueList.defaultProps = {
	isLoading: false,
	items: {},
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')},
}

export default VenueList;