// Packages
import ListItem from 'components/Order/TransactionListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const OrderTransactionsList = ({ isLoading, items }) => {

	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress type='circular' height={100} style={{margin: '20px auto'}} width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
		<h3 className='italic'>No transactions to show here.</h3>
		)
	}
	else {
		return (
			<div className='OrderTransactionsList'>

				<div className='OrderTransactionsList__header'>
					<p className='medium'>Date & Time</p>
					<p className='medium'>Reference</p>
					<p className='medium'>Amount</p>
					<p className='medium'></p>
				</div>

				{isLoading ? 
				
					<ListItem isLoading={true}/>
				:
					items.map((item) => {

						return (
								<ListItem key={item.id} {...item} isLoading={false} />
								)
					
					})
				}
			</div>
		)
	}
}

OrderTransactionsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

OrderTransactionsList.defaultProps = {
	isLoading: false,
	items: {},
}

export default OrderTransactionsList;