// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDSwitch from 'components/BTDUI/fields/BTDSwitch';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

const CustomFieldForm = ({ errors, onClose, onSubmit, item, type }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [options, setOptions] = useState(['']);
    const [hasOptions, setHasOptions] = useState(false);

    const submit = () => {

		onSubmit(changedData);
	}

    // Load item values into form
	useEffect(() => {

		if ( item )
		{
            setData(item);

            if( item.options ) {

                setOptions(item.options)
            }

            if(item.type === 'select' || item.type === 'radios') {

                setHasOptions(true);
            }
		}

	}, [item]);

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...options];
        updatedOptions[index] = {id: index, name: value};
        setOptions(updatedOptions);
        setData({...data, options: updatedOptions});
        setChangedData({...changedData, options: updatedOptions});
    };

    const deleteOption = (index) => {
        const updatedOptions = options.filter((option) => option.id !== index);
        setOptions(updatedOptions);
    }


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className='form__title'>{type === 'edit' ? 'Edit Custom Field' : 'Create Custom Field'}</h3>

            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDSelectField
                error={errors && errors.type ? errors.type : null} 
                label='Type'
                onChange={(value) => {
                    setData({...data, type: value});
                    setChangedData({...changedData, type: value});
                    if( value === 'select' || value === 'radios') {
                        setHasOptions(true);
                    }
                    else {
                        setHasOptions(false);
                    }
                }} 
                options={[
                    {id: 'text', name: 'Text (Single Line)'},
                    {id: 'textarea', name: 'Text (Multiple Lines)'},
                    {id: 'select', name: 'Select'},
                    {id: 'radios', name: 'Radio Buttons'},
                    {id: 'number', name: 'Number'},
                    {id: 'checkbox', name: 'Checkbox'},
                    {id: 'datetime', name: 'Date/Time'},
                    {id: 'image', name: 'Image'},
                    {id: 'file', name: 'File Upload'},
                ]}
                required={true}
                value={data.type ?? ''} 
            />

            {hasOptions &&
            <div className='form__options'>
                <p className='form__label'>Enter options:</p>
                {options.map((option, index) => (
                    <div key={index} style={{position: 'relative'}}>
                        <BTDTextField
                            label={`Option ${index + 1}`}
                            onChange={(value) => {handleOptionChange(index, value)}}
                            value={option.name}
                        />
                        <ClearIcon 
                            colour='#ccc' 
                            height='23px' 
                            onClick={() => deleteOption(index)}
                            style={{cursor: 'pointer', position: 'absolute', right: '15px', top: '20px'}}
                        />
                    </div>
                ))}

                <BTDButton
                    label='Add Option' 
                    onClick={() => {setOptions([...options, ''])}}
                    size='small'
                    style={{margin: '-15px 0 0 auto'}}
                />
                    
            </div>
            }

            <BTDSelectField
                error={errors && errors.scope ? errors.scope : null} 
                label='Relates to'
                onChange={(value) => {
                    setData({...data, scope: value});
                    setChangedData({...changedData, scope: value});
                }} 
                options={[
                    {id: 'ticket', name: 'Ticket'},
                    {id: 'order', name: 'Order'},
                    {id: 'contact', name: 'Contact'},
                ]}
                required={true}
                value={data.scope ?? ''} 
            />

            <BTDSwitch
                checked={data.is_public}
                error={errors ? errors.is_public : null}
                label="Show on public site" 
                onChange={(value) => {
                    setData({...data, is_public: value});
                    setChangedData({...changedData, is_public: value});
                }}
            />


            <BTDButton
                formButton={true}
                label='Save'
                onClick={submit} 
            />

        </FormTemplate>

    )

}

CustomFieldForm.propTypes = {
	errors: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.string
}

CustomFieldForm.defaultProps = {
    errors: {},
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    item: {},
    type: 'create'
}

export default CustomFieldForm;