// Packages
import ListItem from 'components/EmailTemplate/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const EmailTemplateList = props => {

	const { isLoading, items, launchForm, onDelete } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<div className='EmailTemplateList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No email templates to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<div className='EmailTemplateList'>

			{items.map((item) => {

				return (
					<ListItem 
						{...item} 
						key={item.id} 
						isLoading={false}
						launchForm={(id) => {launchForm(id)}}
						onDelete={onDelete} 
					/>
				)
				
			})}
	
		</div>
	)
}

EmailTemplateList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

EmailTemplateList.defaultProps = {
	isLoading: false,
	items: [],
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')}
}

export default EmailTemplateList;