// Packages
import PropTypes from 'prop-types';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const SpeakersListItem = ({ eventId, id, image_upload, isLoading, launchForm, name, onDelete, }) => {

	const { destroy } = useApi('/events/' + eventId + '/speakers/' + id, false);

	const deleteSpeaker = async () => {

		await destroy('speaker', 'Speaker Deleted!');

		onDelete();
    }


	return (
		<div className="SpeakersListItem">

			<div className="SpeakersListItem__image" style={{backgroundImage: `url(${image_upload ? image_upload.url: ''})`}}></div>

			<h3 className='black'>{isLoading ? <BTDSkeleton height='20px' /> : name }</h3>

			<BTDMenu icon={<EllipsisIcon height="18px" />} style={{position: 'absolute', bottom: '20px', right: '10px'}}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteSpeaker()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>

		</div>
	)
}

SpeakersListItem.propTypes = {
	eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	image_upload: PropTypes.object,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
}

SpeakersListItem.defaultProps = {
	distance_from_venue: '',
	eventId: null,
	id: null,
	image_upload: {},
	launchForm: () => {alert('lauchForm is not set!')},
	onDelete: () => {'onDelete is not set!'},
	name: ''
}

export default SpeakersListItem;