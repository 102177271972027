// Packages
import PropTypes from 'prop-types'

// Components
import AccommodationForm from 'components/Accommodation/Form';

// Other
import useApi from 'hooks/useApi';

const CreateAccommodationScreen = ({ eventId, onClose, onSubmit }) => {

    const { post: createAccommodation, errors } = useApi('/events/' + eventId + '/accommodation', false);

	const submit = async (values) => {

        if( await createAccommodation(values, 'Accommodation created!')) {

            onSubmit();
        }
    }

    return(
        <AccommodationForm errors={errors} onClose={onClose} onSubmit={submit} type='create' />
    )
}

CreateAccommodationScreen.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

CreateAccommodationScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default CreateAccommodationScreen