// Packages
import { useState, useEffect } from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import UserInitials from 'components/BTDUI/UserInitials';
import CheckedLink from 'components/BTDUI/CheckedLink';
import PersonIcon from 'components/BTDUI/icons/PersonIcon';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import LogoutIcon from 'components/BTDUI/icons/LogoutIcon';

// other
import './styles.css';

const Avatar = ({ active, size, toggleOverlay, user, withDropDown, withName }) => {

	const [dropdownActive, setDropdownActive] = useState(false);

	useEffect(() => {

		setDropdownActive(active);
	}, [active]);

    return(
        <div className='Avatar'>
			{user ? 
				<div
					alt={user.name ?? ''} 
					className='Avatar__image'
					onClick={() => {
						setDropdownActive(! dropdownActive);
						toggleOverlay();
					}}
					style={{backgroundImage: `url('${user.avatar_url}')`}} 
					size={size} 
				>
					{! user.avatar_url && 
					
						<UserInitials name={user.name ?? ''} />
					}

				</div>
				:
				<BTDSkeleton  width="43px" height="43px" />
			}

			{withName &&
				<p className='Avatar__name semi-bold black'>{user.name ?? ''}</p>
			}

			{withDropDown && dropdownActive && 
				<>
				<div className="Avatar__dropdown">

					<div className="Avatar__dropdown__triangle" />

					{user ? 
						<div
							alt={user.name ?? ''} 
							className='Avatar__image'
							onClick={() => {
								setDropdownActive(! dropdownActive);
								toggleOverlay();
							}}
							style={{backgroundImage: `url('${user.avatar_url}')`}} 
							size={size} 
						>
							{! user.avatar_url && 
							
								<UserInitials name={user.name ?? ''} />
							}

						</div>
						:
						<BTDSkeleton width="43px" height="43px" />
					}

					<p className='Avatar__dropdown__name bold black'>{user.name ?? ''}</p>

					<CheckedLink
						className='Avatar__dropdown__link'
						label='Your profile'
						to='https://account.oakora.com/profile' 
						style={{
							alignItems: 'center',
							display: 'flex',
							fontWeight: '400',
							marginBottom: '30px'
						}} 
					>
						<PersonIcon style={{ marginRight: '5px' }}/>
					</CheckedLink>

					<BTDButton
						href='https://account.oakora.com/logout'
						label='Sign Out'
						startIcon={<LogoutIcon colour='#fff' height='22px' style={{marginRight: '8px'}} width='22px' />}
						style={{margin: '10px auto 0 auto'}}
					/>

						
				</div>
				</>
			}

        </div>
    )
}

Avatar.propTypes = {
	active: PropTypes.bool,
    className: PropTypes.string,
	toggleOverlay: PropTypes.func,
    user: PropTypes.object,
    withDropDown: PropTypes.bool,
    withName: PropTypes.bool
}

Avatar.defaultProps = {
	active: false,
    className: '',
	toggleOverlay: () => {alert('toggleOverlay is not set!')},
    user: null,
    withDropDown: true,
    withName: false
}

export default memo(Avatar);