// Packages
import { useState } from 'react'
import { useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/EventsView';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import TicketTypeList from 'components/TicketType/List';
import TicketTypeCreateScreen from 'screens/Events/View-TicketTypes/Create';
import TicketTypeEditScreen from 'screens/Events/View-TicketTypes/Edit';

// Other
import useApi from 'hooks/useApi';

const EventViewTicketTypesScreen = () => {

	const { eventId } = useParams();

	const [formActive, setFormActive] = useState(false);
	const [ticketTypeId, setTicketTypeId] = useState('');
	const [formType, setFormType] = useState('create');

	const { data: event, isLoading, onChange } = useApi('/events/' + eventId + '?with=tickettypes');
	
	return (
		<Layout activeTab={`/events/${eventId}/tickets`} event={event} isLoading={isLoading} onSubmit={() => {onChange()}}>

			<TicketTypeList 
				eventId={event ? event.id : null} 
				items={event ? event.tickettypes : null} 
				isLoading={isLoading} 
				launchForm={(id) => {
					setTicketTypeId(id);
					setFormType('edit');
					setFormActive(true);
				}} 
				onDelete={() => {onChange()}}
			/>

			<AddIcon onClick={() => {
				setFormType('create');
				setFormActive(true);
			}} />

			{formActive &&
			<>
					{formType === 'create' &&
						<TicketTypeCreateScreen 
							eventId={eventId} 
							onClose={() => {
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}} 
						/>
					}
					{formType === 'edit' &&
						<TicketTypeEditScreen 
							eventId={eventId} 
							ticketTypeId={ticketTypeId} 
							onClose={() => {
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}}
						/>
					}
				</>
			}

		</Layout>
	)

}

export default EventViewTicketTypesScreen;