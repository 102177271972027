// Packages
import { useState } from 'react';

// Components
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import Layout from 'layouts/Settings';
import CreateCustomFieldScreen from './Create';
import EditCustomFieldScreen from './Edit';
import CustomFieldList from 'components/CustomField/List';

// Other
import useApi from 'hooks/useApi';
import './styles.css';
import QuestionMarkIcon from 'components/BTDUI/icons/QuestionMarkIcon';

const SettingsCustomFieldScreen = () => {

	const { data: items, isLoading, onChange } = useApi('/customfields');

	const [createFormActive, setCreateFormActive] = useState(false);
	const [editFormActive, setEditFormActive] = useState(false);
	const [itemId, setItemId] = useState(null);
	
	return (
		<Layout activeTab='/settings/custom-fields'>

				<div className='SettingsCustomFieldScreen'>

					<div className='SettingsCustomFieldScreen__main'>

						<CustomFieldList
							items={items} 
							isLoading={isLoading}
							launchForm={(id) => {
								setItemId(id);
								setEditFormActive(true);
							}}
							onDelete={() => {
								setEditFormActive(false);
								onChange();
							}}
							/>

					</div>

					<div className='SettingsCustomFieldScreen__sidebar'>

						<div className='SettingsCustomFieldScreen__sidebar__block'>
							<div className='SettingsCustomFieldScreen__sidebar__block__header'>
								<QuestionMarkIcon colour='#253551' style={{display: 'block', marginBottom: '5px'}} />
								<p>Custom fields allow you to customise the information that you collect from your customers</p>
							</div>
						</div>

					</div>
				</div>

			<AddIcon onClick={() => {setCreateFormActive(true)}} />

			{createFormActive && 

				<CreateCustomFieldScreen
					onClose={() => {setCreateFormActive(false)}} 
					onSubmit={() => {
						setCreateFormActive(false);
						onChange();
					}}
				/>
			}
			{editFormActive && 

				<EditCustomFieldScreen
					itemId={itemId}
					onClose={() => {setEditFormActive(false)}} 
					onSubmit={() => {
						setEditFormActive(false);
						onChange();
					}}
				/>
			}

		</Layout>
	)

}

export default SettingsCustomFieldScreen;