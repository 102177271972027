// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDNumberField = ({ description, error, label, onChange, required, style, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value !== null && value !== '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <>
        {description &&
            <p className='BTDNumberField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDNumberField ${width} `} style={style}>
            <label className={`BTDNumberField__label ${active ? 'active' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'> *</span>
                }
            </label>
            <input className={`BTDNumberField__input ${error ?? ''}`} onChange={(e) => {onChange(e.target.value)}} type='number' required={required} value={value} />
            {error &&
                <p className='BTDNumberField__error'>{error}</p>
            }
        </div>
        </>
    )
}

BTDNumberField.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string
}

BTDNumberField.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    style: {},
    value: null,
    width: 'small'
}

export default BTDNumberField;                                         