// Packages
import ListItem from 'components/Refund/ItemListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const RefundItemList = ({ isLoading, items, onSubmit }) => {

	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress height={100} style={{margin: '20px auto'}} type='circular' width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
		<h3 className='italic'>No refunded tickets to show here.</h3>
		)
	}
	else {
		return(
			<div className='RefundItemList'>

				<div className='RefundItemList__header'>

					<p className='medium'></p>
					<p className='medium' style={{paddingLeft: '20px'}}>Ticket Type</p>
					<p className='medium'>Event</p>
					<p className='medium'>Total</p>
					<p className='medium'></p>
				</div>
					
				{items.map((item, index) => {

					return (
							<ListItem 
								event={item.order_item ? item.order_item.event : {}} 
								key={item.id} {...item} 
								index={index} 
								isLoading={false} 
								onSubmit={onSubmit} 
								status={item.order_item ? item.order_item.status : ''}
								ticket_type={item.order_item ? item.order_item.ticket_type : {}} 
							/>
							)
					
					})
				}
			</div>
		)
	}

}

RefundItemList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onSubmit: PropTypes.func,
}

RefundItemList.defaultProps = {
	isLoading: false,
	items: {},
	onSubmit: () => {alert('onSubmit is not set!')},
}

export default RefundItemList;