// Packages
import { useState } from "react";
import PropTypes from 'prop-types';

// Components
import BTDButton from "components/BTDUI/BTDButton";
import BTDTextareaField from "components/BTDUI/fields/BTDTextareaField";
import { Editor } from '@tinymce/tinymce-react';

// other
import Config from 'other/config';
import './styles.css';

const TextEditor = props => {

    const { description, error, label, onChange, required, value } = props;

    const [showCodeEditor, setShowCodeEditor] = useState(false);

    return(
        <div className="BTDEditor">

            <div className="BTDEditor__header">
        
                <p className='form__label black medium opacity'>{label}
                    {required &&
                        <span className='red'> *</span>
                    }
                </p>
                <BTDButton
                    label='Toggle Source Code'
                    onClick={() => {setShowCodeEditor(!showCodeEditor)}}
                    size="small"
                    type='outlined'
                    />

            </div>
            
            {description &&
                <p className='detail' dangerouslySetInnerHTML={{__html: description}}></p>
            }

            {showCodeEditor ?
                <BTDTextareaField
                    error={error ?? null} 
                    description={description ?? null } 
                    label={label}
                    onChange={(e) => onChange(e.target.value)} 
                    rows={10}
                    value={value ?? ''} 
                />
            :
            <>
                <Editor
                    apiKey={Config.EDITOR_API_KEY}
                    error={error ?? false} 
                    helperText={error ?? '' } 
                    init={{
                        menu: {
                            file: { title: 'File', items: '' }, // Disable File menu
                        },
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                            'insertdatetime', 'media'
                        ],
                        toolbar: 'undo redo ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | ',
                        content_style: 'body { font-family:Poppins,Arial,sans-serif; font-size:16px }'
                    }}
                    onEditorChange={onChange} 
                    value={value ?? ''}
                /> 
                {error &&
                    <p className='BTDTextareaField__error'>{error}</p>
                }
            </>
            }
        </div>
    )
}

TextEditor.propTypes = {
    description: PropTypes.string,
    errors: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string
}

TextEditor.defaultProps = {
    description: null,
    errors: null,
    label: '',
    onChange: () => {alert('onChange in not set!')},
    required: false,
    value: ''
}

export default TextEditor;