// Packages
import PropTypes from 'prop-types';

// components
import ListItem from 'components/TicketType/ListItem';

const TicketTypeList = props => {

	const { eventId, isLoading, items, launchForm, onDelete } = props;


	// Loading State
	if ( isLoading )
	{
		return (
			<ListItem isLoading={true}/>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<>No ticket types to show here. This state needs improving</>
		)
	}


	// Data State
	return (
		<>
			{items.map((item) => {

				return (
						<ListItem key={item.id} {...item} eventId={eventId} isLoading={false} launchForm={launchForm} onDelete={onDelete}/>
				)

			})}
		</>
	)
}

ListItem.proptypes = {
	eventId: PropTypes.number,
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	launchForm: PropTypes.func,
	onDelete: PropTypes.func
}

ListItem.defaultProps = {
	eventId: null,
	isLoading: false,
	items: [],
	launchForm: () => {alert('launchForm is not set!')},
	onDelete: () => {alert('onDelete is not set!')},
}

export default TicketTypeList;