// Components
import RefundList from 'components/Refund/List';

// Other
import useApi from 'hooks/useApi'
import RefundsLayout from 'layouts/Refunds';

const RefundListScreen = () => {

	const { data: refunds, isLoading } = useApi('/refunds?with=event,contact');

	
	return (
		<RefundsLayout>
			<RefundList items={refunds} isLoading={isLoading} />
		</RefundsLayout>
	)

}

export default RefundListScreen;