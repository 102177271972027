// Packages
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Refunds/List'
import ViewScreen from 'screens/Refunds/View'


const RefundRoutes = () => 
{
	return (
		<Routes>
			<Route exact path="/:refundId/*" element={<ViewScreen/>}/>
			<Route exact path="/*" element={<ListScreen/>}/>
		</Routes>
	);
}


export default RefundRoutes;