// Packages
import BTDProgress from "components/BTDUI/BTDProgress"
import PropTypes from 'prop-types'

// Other
import './styles.css';

const QRProcessing = ({ style }) => {

    return(
        <div className="QRProcessing" style={style}>
            <BTDProgress height={50} type="circular" width={50} style={{margin: '0 auto 20px auto'}}/>
            <p className="medium black centre">Generating QR code...</p>
        </div>
    )
}

QRProcessing.propTypes = {
    style: PropTypes.object
}

QRProcessing.defaultProps = {
    style: {}
}

export default QRProcessing;