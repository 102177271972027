// Packages
import PropTypes from 'prop-types';

// Components
import VenueForm from 'components/Venue/Form';

// Other
import useApi from 'hooks/useApi'

const VenueEditScreen = props => {

    const { onClose, onSubmit, venueId } = props;

    const { data: venue, errors, put: updateVenue } = useApi('/venues/' + venueId)
		
	const submit = async values => {

        if( await updateVenue(values, 'Venue updated!') ) {

            onSubmit();
        };
    }
    
    return(
        <VenueForm venue={venue} onClose={onClose} onSubmit={submit} type='edit' errors={errors} />
    )
}

VenueEditScreen.propTypes = {
    eventId: PropTypes.number,
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

VenueEditScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default VenueEditScreen;