// Packages
import ListItem from 'components/Refund/TransactionListItem';
import PropTypes from 'prop-types'

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import './styles.css';

const RefundTransactionsList = ({ isLoading, items }) => {

	// Empty State
	if (isLoading )
	{
		return (
			<BTDProgress type='circular' height={100} style={{margin: '20px auto'}} width={100} />
		)
	}
	else if( items.length === 0 ) {

		return (
		<h3 className='italic'>No transactions to show here.</h3>
		)
	}
	else {
		return (
			<div className='RefundTransactionsList'>

				<div className='RefundTransactionsList__header'>
					<p className='medium'></p>
					<p className='medium'>Date & Time</p>
					<p className='medium'>Reference</p>
					<p className='medium centre'>Amount</p>
					<p className='medium centre'>Gateway Fee</p>
					<p className='medium'></p>
				</div>

				{isLoading ? 
				
					<ListItem isLoading={true}/>
				:
					items.map((item, index) => {

						return (
								<ListItem key={item.id} {...item} index={index} isLoading={false} />
								)
					
					})
				}
			</div>
		)
	}
}

RefundTransactionsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

RefundTransactionsList.defaultProps = {
	isLoading: false,
	items: {},
}

export default RefundTransactionsList;