// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import ImageUpload from 'components/BTDUI/fields/BTDImageUpload';
import Layout from 'layouts/EventsView';
import BTDButton from 'components/BTDUI/BTDButton';

// Context
import { useNav } from 'context/Nav';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const EventSettingsScreen = () => {

	const { eventId } = useParams();

	const { setUnsavedChanges, unsavedChanges } = useNav();

	const { data: event, put: updateEvent, onChange } = useApi('/events/' + eventId + '?with=featured_image');

	const [changedData, setChangedData] = useState({})
	const [data, setData] = useState({})

	useEffect(() => {

		if( event ) {

			setData(event);
		}
	}, [event]);

	const submit = async () => {

		await updateEvent(changedData, 'Settings updated!');

		onChange();
		setChangedData({});
		setUnsavedChanges(false);
	}

	return (
		<Layout activeTab={'/events/' + eventId + '/settings'} event={event}>

			<div className='Settings'>

				<div className='Settings__main'>

					<h3 className='semi-bold'>Featured Image</h3>

					<p className='medium' style={{marginBottom: '10px'}}>This shows on the public site</p>

					<div style={{marginBottom: '40px', maxHeight: '185px'}}>
						<ImageUpload
							image_max_height={800} 
							image_max_width={600} 
							image_quality={0.8} 
							image_url={data && data.featured_image_upload ? data.featured_image_upload.url : null} 
							label='Photo'
							onChange={(id) => {
								setData({...data, featured_image_upload_id: id});
								setChangedData({...changedData, featured_image_upload_id: id});
								setUnsavedChanges(true);
							}} 
						/>   
					</div>

				</div>

				<div className='Settings__sidebar'>

					<div className='Settings__sidebar__button'>
						<BTDButton
							disabled={! unsavedChanges}
							onClick={submit} 
							label='Save Settings'
							size='large'
						/>
					</div>
				</div>
			</div>

		</Layout>
	)

}

export default EventSettingsScreen;