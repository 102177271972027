// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import DashboardScreen from 'screens/Dashboard'


const MiscRoutes = () => 
{
	return (
		<Routes>
			<Route exact path="/" element={<DashboardScreen/>}/>
		</Routes>
	);
}


export default MiscRoutes;