// Packages
import PropTypes from 'prop-types'

// Components
import TaxRateForm from 'components/TaxRate/Form';

// Other
import useApi from 'hooks/useApi';

const CreateTaxRateScreen = props => {

    const { onClose, onSubmit } = props;

    const { post: createItem, errors } = useApi('/taxrates', false);

	const submit = async (values) => {

        if( await createItem(values, 'Added to VAT rates!')) {

            onSubmit();
        }
    }

    return(
        <TaxRateForm errors={errors} onClose={onClose} onSubmit={submit} />
    )
}

CreateTaxRateScreen.propTypes = {
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

CreateTaxRateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
}

export default CreateTaxRateScreen;