// Packages
import { useState } from 'react';

// Components
import Layout from 'layouts/Settings';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import EditEmailTemplateScreen from './Edit';
import CreateEmailTemplateScreen from './Create';
import EmailTemplateList from 'components/EmailTemplate/List';

// Other
import useApi from 'hooks/useApi';

const SettingsEmailTemplatesScreen = () => {

	const { data: items, isLoading, onChange } = useApi('/emailtemplates');

	const [createFormActive, setCreateFormActive] = useState(false);
	const [editFormActive, setEditFormActive] = useState(false);
	const [itemId, setItemId] = useState(null);

	
	return (
		<Layout activeTab='/settings/email-templates'>

			<div className='EmailTemplates'>

				<EmailTemplateList
					isLoading={isLoading}
					items={items} 
					launchForm={(id) => {
						setItemId(id);
						setEditFormActive(true);
					}}
					onDelete={() => {
						setEditFormActive(false);
						onChange();
					}}
				/>
			</div>

			<AddIcon onClick={() => {setCreateFormActive(true)}} />

			{createFormActive && 

				<CreateEmailTemplateScreen
					onClose={() => {setCreateFormActive(false)}} 
					onSubmit={() => {
						onChange();
						setCreateFormActive(false);
					}}
				/>
			}
			{editFormActive && 

				<EditEmailTemplateScreen
					itemId={itemId}
					onClose={() => {setEditFormActive(false)}} 
					onSubmit={() => {
						onChange();
						setEditFormActive(false);
					}}
				/>
			}

		</Layout>
	)

}

export default SettingsEmailTemplatesScreen;