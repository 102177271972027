// Packages
import PropTypes from 'prop-types'

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const CustomFieldListItem = ({ id, is_default, isLoading, is_public, launchForm, name, onDelete, scope, type, }) => {

    const { destroy: destroyItem } = useApi('/customfields/' + id, false);

	const deleteItem = async () => {

		await destroyItem('custom field', 'Custom field deleted!');
		onDelete();
	}


    return(
        <div className='CustomFieldListItem'>

            <div>
				<p className='semi-bold'>{isLoading ? <BTDSkeleton /> : name}</p>
				<p className='medium'>{! isLoading ? type : <BTDSkeleton />}</p>
			</div>

            <p><span className='opacity'>for </span> <span className='semi-bold'>{! isLoading ? scope : <BTDSkeleton />}</span></p>

            <p className='red'>{! isLoading ? is_public ? 'public' : '' : <BTDSkeleton />}</p>

            <BTDMenu icon={<EllipsisIcon height="18px" />}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>
        </div>
    )
}

CustomFieldListItem.propTypes = {
	default: PropTypes.bool,
	id: PropTypes.number,
	is_public: PropTypes.bool,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
	scope: PropTypes.string,
	type: PropTypes.string,
}

CustomFieldListItem.defaultProps = {
    default: false,
	id: null,
	is_public: false,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set!')},
	scope: '',
	type: '',
}

export default CustomFieldListItem;