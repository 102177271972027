// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Context
import { useSnackbar } from 'context/Snackbar';

// Components
import FormTemplate from '../FormTemplate';
import BTDButton from '../BTDButton';
import BTDProgress from '../BTDProgress';
import DownloadIcon from '../icons/DownloadIcon';

const ExportFile = ({ dataset, filename, link, start_icon, subject_id, url }) => {

    const { showSnackbar } = useSnackbar();

	const [formActive, setFormActive] = useState(false);
	const [state, setState] = useState('generate');
	const [downloadURL, setDownloadURL] = useState('');
	const [isPolling, setIsPolling] = useState(false);
	const [exportId, setExportId] = useState(null);
	const [numRecords, setNumRecords] = useState();
	const [isDownloading, ] = useState(false);

    const generate = async () => {

		try {
			const response = await axios.post(url, {dataset: dataset, start_icon, subject_id});

			if( response.data.data.id ) {

				setExportId(response.data.data.id );
				setIsPolling(true);
			}
			else {
				showSnackbar('There was an error generating the file. Please try again');
				setState('generate');
			}

			
		} catch (error) {

			showSnackbar(error.message, 'error');
		}
	} 

	useEffect(() => {

		const fetchData = async () => {

			try {

				const response = await axios.get(url + '/' + exportId); 
				const data = response.data.data;
			
				if (data.url !== null) {

					setIsPolling(false); // Stop polling if the condition is met
					setDownloadURL(data.url);
					setNumRecords(data.number_of_records);
					setState('download');
				}

			} catch (error) {

				showSnackbar(error.message, 'error');
			}
		};

		const interval = setInterval(() => {

			if (isPolling) {

				fetchData();

			} else {

			  clearInterval(interval); // Stop polling when isPolling is false
			}
		}, 2000);

		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, [isPolling])



    return(
		<>	
        <div onClick={() => setFormActive(true)} style={{cursor: 'pointer', lineHeight: '1.5'}}>{start_icon}{link}</div>

		{formActive &&
			<FormTemplate 
				onClose={() => {
					setState('generate');
					setFormActive(false);
					}}
				size='small'
			>

				{state === 'generate' && 
					<>
					<h3 className='form__title centre'>Generate download for {filename ?? ''}</h3>
					<BTDButton 
						label='Generate' 
						onClick={() => {
							generate();
							setState('processing');
						}} 
						style={{margin: '0 auto'}}
					/>
					</>
				}
				{state === 'processing' && 
					<>
					<h3 className='form__title centre'>Generating records. Please wait...</h3>
					<BTDProgress type='circular' style={{margin: '30px auto'}}/>
					</>
				}
				{state === 'download' && 
					<>
					<h3 className='form__title centre'>{filename ?? ''} ({numRecords}) is ready to download</h3>

					<BTDButton 
						disabled={isDownloading}
						label='Download' 
						startIcon={<DownloadIcon style={{marginRight: '8px'}} />}
						style={{margin: '0 auto'}}
						url={downloadURL}
					/>
					</>
				}

			</FormTemplate>
		}
		</>
    )
}

ExportFile.propTypes = {
	dataset: PropTypes.string,
    filename: PropTypes.string,
    link: PropTypes.string,
    start_icon: PropTypes.node,
    subject_id: PropTypes.string,
    url: PropTypes.string,
}

ExportFile.defaultProps = {
	dataset: '',
    filename: 'your file',
    link: 'Export file',
    start_icon: null,
    subject_id: '',
    url: ''
}

export default ExportFile;