// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// other
import './styles.css';

const BTDTab = ({ style, type }) => {

    const [colour, setColour] = useState();

    useEffect(() => {

        switch (type) {
            case 'unfulfilled':
                setColour('red')
                break;
            case 'fulfilled':
                setColour('green')
                break;
            case 'refunded':
                setColour('grey')
                break;
            case 'pending':
                setColour('orange')
                break;
            case 'complete':
                setColour('green')
                break;
        
            default:
                setColour('grey')
                break;
        }

    }, [type])


    return(
        <div className={`BTDTab BTDTab--colour-${colour}`} style={style}>{type.charAt(0).toUpperCase() + type.slice(1)}</div>
    )
}

BTDTab.propTypes = {
    style: PropTypes.object,
    type: PropTypes.string,
}

BTDTab.defaultProps = {
    style: {},
    type: '',
}

export default BTDTab;