// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import FieldSelector from 'components/BTDUI/FieldSelector';
import BTDButton from 'components/BTDUI/BTDButton';

const MicrositeSectionForm = props => {

    const { errors, onClose, onSubmit, section, template, type, } = props;

	const [changedData, setChangedData] = useState({})
	const [data, setData] = useState(null)

    useEffect(() => {

        if( section && section.config ) {

            setData(section.config);
        }
    }, [section])

    const submit = () => {

		onSubmit(changedData);
	}


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create ' : 'Edit '}{template.name} Section</h3>

            <FieldSelector
                errors={errors}
                fields={template.config ? Object.values(template.config) : []}
                onChange={(newData) => {
                    setData({...data, ...newData});
                    setChangedData({...changedData, ...newData});
                }}
                values={data ?? null}
            />


            <BTDButton
                formButton={true}
                label={type === 'create' ? 'Save ' + template.name : 'Update ' + template.name }
                onClick={submit} 
            />

        </FormTemplate>

    )

}

MicrositeSectionForm.propTypes = {
	errors: PropTypes.object,
	event: PropTypes.object, 
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    section: PropTypes.object,
    template: PropTypes.object,
    type: PropTypes.string
}

MicrositeSectionForm.defaultProps = {
    errors: {},
    event: null,
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    section: {},
    template: {},
    type: 'create'
}

export default MicrositeSectionForm;