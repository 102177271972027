// Packages
import { useState } from 'react'
import { useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/EventsView';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import SpeakersList from 'components/Speaker/List';
import CreateSpeakerScreen from './Create';
import EditSpeakerScreen from './Edit';

// Other
import useApi from 'hooks/useApi';

const EventSpeakersScreen = () => {

	const { eventId } = useParams();

	const [formActive, setFormActive] = useState(false);
	const [speakerId, setSpeakerId] = useState('');
	const [formType, setFormType] = useState('create');

	const { data: event, isLoading } = useApi('/events/' + eventId);
	const { data: speakers, isLoading: speakersLoading, onChange } = useApi('/events/' + eventId + '/speakers');

	
	return (
		<Layout activeTab={`/events/${eventId}/speakers`} isLoading={isLoading} event={event} onSubmit={() => {onChange()}}>

			<SpeakersList
				eventId={eventId} 
				items={speakers} 
				isLoading={speakersLoading} 
				launchForm={(id) => {
					setSpeakerId(id);
					setFormType('edit');
					setFormActive(true);
				}} 
				onDelete={() => {onChange()}}
			/>

			<AddIcon onClick={() => {
				setFormType('create');
				setFormActive(true);
			}} />

			{formActive &&
			<>
					{formType === 'create' &&
						<CreateSpeakerScreen
							eventId={eventId} 
							onClose={() => {
								onChange();
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}}
						/>
					}
					{formType === 'edit' &&
						<EditSpeakerScreen
							eventId={eventId} 
							speakerId={speakerId} 
							onClose={() => {
								onChange();
								setFormActive(false);
							}} 
							onSubmit={() => {
								onChange();
								setFormActive(false);
							}}
						/>
					}
				</>
			}

		</Layout>
	)

}

export default EventSpeakersScreen;