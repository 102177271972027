// Packages
import PropTypes from 'prop-types'
import dayjs from 'dayjs';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// Other
import './styles.css';


const OrderListItem = ({ created_at, contact, event, fulfilment_status, isLoading, number, number_of_tickets, payment_status, total }) => {

	const { currency, orderNumber } = useAppData();

	return (
		
			<div className="OrderListItem">

				<div className="OrderListItem__value">
					<p className='semi-bold light-blue'>{isLoading ? <BTDSkeleton /> : orderNumber(number)}</p>
					<p className='medium black'>{created_at ? dayjs(created_at).format('D MMM YYYY HH:mm') : ''}</p>
				</div>
				<div className="OrderListItem__value">
					<p className='medium black' to={'/contacts/' + contact.id}>{isLoading ? <BTDSkeleton /> : contact ? contact.firstname + ' ' + contact.lastname : '' }</p>
					<p className='medium black'>{contact ? contact.city : '' }</p>
				</div>
				<div className="OrderListItem__value">
					<p className='medium black'>{isLoading ? <BTDSkeleton /> : event ? event.name : ''}</p>
					<p className='medium black'>{isLoading ? <BTDSkeleton /> : number_of_tickets ? number_of_tickets : '0'} tickets</p>
				</div>
				<div className="OrderListItem__value">
					<p className='medium black'>{isLoading ? <BTDSkeleton /> : currency(total)}</p>
				</div>
				<div className="OrderListItem__value">
					<p className='orange medium'>{isLoading ? <BTDSkeleton /> : payment_status}</p>
				</div>
				<div className="OrderListItem__value"><p className='orange medium'>{isLoading ? <BTDSkeleton /> : fulfilment_status}</p></div>

			</div>

	)
}

OrderListItem.propTypes = {
	created_at: PropTypes.string,
	contact: PropTypes.object,
	isLoading: PropTypes.bool,
	event: PropTypes.object,
	number: PropTypes.string,
	number_of_tickets: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	fulfilment_status: PropTypes.string,
	payment_status: PropTypes.string,
	total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

OrderListItem.defaultProps = {
	created_at: '',
	contact: {},
	isLoading: false,
	event: {},
	firstname: '',
	lastname: '',
	number: '',
	number_of_tickets: '',
	fulfilment_status: '',
	payment_status: '',
	total: null
}

export default OrderListItem;