// Components
import DefaultLayout from 'layouts/Default';
import BTDProgress from 'components/BTDUI/BTDProgress';
import Overlay from 'components/BTDUI/Overlay';

// Context
import { useUI } from 'context/UI';

// Other
import 'assets/styles.css';
import Config from 'other/config';


function App() {

	const { getLoading, overlayActive, setOverlayActive } = useUI();

	return (
		<>
		{getLoading() &&
		<>
			<div style={{ 
				left: '0',
				position: 'absolute', 
				top: '0',
				width: '100%', 
				}}
			>
				<BTDProgress />
			</div>
		</>
		}
		
		
		<DefaultLayout/>
		

		{overlayActive && 
			<Overlay onClick={() => {setOverlayActive(false)}}/>
		}

		<script src={"https://maps.googleapis.com/maps/api/js?key=" + Config.MAPS_API_KEY + "&callback=initMap"} async defer></script>

	</>
	);
}

export default App;
