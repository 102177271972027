// Packages
import { Routes, Route } from "react-router-dom"

// Components
import UnsavedChangesPrompt from 'components/BTDUI/UnsavedChangesPrompt';

// Screens
import GeneralScreen from 'screens/Settings/General';
import BrandingScreen from 'screens/Settings/Branding';
import FinancialScreen from 'screens/Settings/Financial';
import TaxRatesScreen from 'screens/Settings/TaxRates';
import EmailScreen from 'screens/Settings/Email';
import EmailTemplateScreen from 'screens/Settings/EmailTemplates';
import CustomFieldsScreen from 'screens/Settings/CustomFields';


const SettingsRoutes = () => 
{
	return (
		<UnsavedChangesPrompt>
			<Routes>
				<Route path="/branding" element={<BrandingScreen />} />
				<Route path="/financial" element={<FinancialScreen />} />
				<Route path="/tax-rates" element={<TaxRatesScreen />} />
				<Route path="/email" element={<EmailScreen />} />
				<Route path="/email-templates" element={<EmailTemplateScreen />} />
				<Route path="/custom-fields" element={<CustomFieldsScreen />} />
				<Route path="/*" element={(<GeneralScreen />)} />
			</Routes>
		</UnsavedChangesPrompt>
	);
}


export default SettingsRoutes;