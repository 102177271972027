// Packages
import PropTypes from 'prop-types'

// Components
import MicrositeSectionForm from 'components/Microsite/MicrositeSection/Form';

// Other
import useApi from 'hooks/useApi';

const CreateMicrositeSectionScreen = props => {

    const { eventId, onClose, onSubmit, template } = props;

    const { post: create, errors } = useApi('/events/' + eventId + '/microsite/sections', false);

	const submit = async (values) => {

        if( await create({section_template_id: template.id, config: values}, 'Section added!')) {

            onSubmit();
        }
    }

    return(
        <MicrositeSectionForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            template={template} type='create'
        />
    )
}

CreateMicrositeSectionScreen.propTypes = {
    eventId: PropTypes.string,
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    template: PropTypes.object
}

CreateMicrositeSectionScreen.defaultProps = {
    eventId: '',
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    template: {}
}

export default CreateMicrositeSectionScreen;