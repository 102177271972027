// Packages
import ListItem from 'components/BTDUI/Search/SearchResultsListItem';
import PropTypes from 'prop-types';

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

const SearchResultsList = ({ isLoading, items, onClose }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<BTDProgress type='circular' width={50} style={{ margin: '0 auto'}} />
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No results</h3>
		)
	}


	// Data State
	return (
		<>
			{items.map((item, index) => {

				return (
					<div key={index} >
						<ListItem 
							{...item} 
							onClose={onClose}
						/>
					</div>
				)
				
			})}
		</>
	)
}

SearchResultsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onClose: PropTypes.func,
}

SearchResultsList.defaultProps = {
	isLoading: false,
	items: [],
	onClose: () => {alert('onClose is not set!')}
}

export default SearchResultsList;