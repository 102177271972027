// Packages
import PropTypes from 'prop-types';

// Components
import Avatar from 'components/BTDUI/Avatar';
import PersonIcon from 'components/BTDUI/icons/PersonIcon';
import CheckedLink from 'components/BTDUI/CheckedLink';
import BTDButton from 'components/BTDUI/BTDButton';
import LogoutIcon from 'components/BTDUI/icons/LogoutIcon';

// other
import './styles.css';

const MobileMenu = ({ onClick, user }) => {

    return(
        <div className="MobileMenu">

            <div className='MobileMenu__links'>

                <CheckedLink to='/' className='MobileMenu__links__link black' onClick={onClick}>Dashboard</CheckedLink>
                <CheckedLink to='/events' className='MobileMenu__links__link black' onClick={onClick}>Events</CheckedLink>
                <CheckedLink to='/orders' className='MobileMenu__links__link black' onClick={onClick}>Orders</CheckedLink>
                <CheckedLink to='/reports' className='MobileMenu__links__link black' onClick={onClick}>Reports</CheckedLink>
                <CheckedLink to='/venues' className='MobileMenu__links__link black' onClick={onClick}>Venues</CheckedLink>

            </div>

            <Avatar user={user ?? {}} withName={true} withDropDown={false} />

            <CheckedLink 
                className='MobileMenu__links__link light-blue'
                label='Your profile'
                onClick={onClick}
                to='https://account.oakora.com/profile' 
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    fontWeight: '400',
                    margin: '15px auto 30px auto',
                    width: 'max-content'
                }} 
            >
                <PersonIcon style={{ marginRight: '5px' }}/>
            </CheckedLink>

            <BTDButton
                href='https://account.oakora.com/logout'
                label='Sign Out'
                onClick={onClick}
                startIcon={<LogoutIcon colour='#fff' height='22px' style={{marginRight: '8px'}} width='22px' />}
                style={{margin: '0 auto'}}
            />
        </div>
    )
}

MobileMenu.propTypes = {
    onClick: PropTypes.func,
    user: PropTypes.object
}

MobileMenu.defaultProps = {
    onClick: () => {alert('onClick is not set!')},
    user: {}
}

export default MobileMenu;