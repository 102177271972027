// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import BTDTab from 'components/BTDUI/BTDTab';

// Other
import './styles.css';

const OrderRefundListItem = ({ created_at, id, isLoading, reference, status, value }) => {

	const { currency, date, time } = useAppData();

	return (
		<div className='OrderRefundListItem'>

			<div className='OrderRefundListItem__value OrderRefundListItem__value-date'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : date(created_at)}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : time(created_at)}</p>
			</div>
			<div className='OrderRefundListItem__value'>
				<Link className='medium underline light-blue' to={'/refunds/' + id}>{isLoading ? <BTDSkeleton /> : reference}</Link>
			</div>
			<div className='OrderRefundListItem__value'>
				<p className='medium centre'>{isLoading ? <BTDSkeleton /> : currency(value)}</p>
			</div>
			<div className='OrderRefundListItem__value'>
				<BTDTab style={{margin: '0 auto'}}type={status ?? 'unknown'} />
			</div>

			<div className='OrderRefundListItem__value'>

				<BTDMenu icon={<EllipsisIcon height="18px"/>} style={{display: 'block', margin: '0 0 0 auto', width: '40px'}}>
					<Link className='black' to={'/refunds/' + id}>View Refund</Link>
				</BTDMenu>
			</div>

		</div>
	)
}

OrderRefundListItem.propTypes = {
	created_at: PropTypes.string,
	gateway_fee: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isLoading: PropTypes.bool,
	reference: PropTypes.string,
	status: PropTypes.string,
	value: PropTypes.string,
}

OrderRefundListItem.defaultProps = {
	created_at: '',
	gateway_fee: '',
	id: null,
	isLoading: true,
	reference: '',
	status: null,
	value: '',
}

export default OrderRefundListItem;