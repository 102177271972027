// Packages
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDSlider = ({ description, error, label, min, max, onChange, required, step, style, value }) => {

    const steps = [];
    for (let i = min; i <= max; i += step) {
        steps.push(i);
    }

    return(
        <>
        {label &&
            <div className='form__label'>
                <p className='BTDSlider__label black medium opacity'>{label}</p>
                {required &&
                    <div className='red'> *</div>
                }
            </div>
        }
        {description &&
            <p className='BTDSlider__description' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDSlider`} style={style}>
            <input 
                className={`BTDSlider__input ${error ?? ''}`} 
                min={min}
                max={max}
                onChange={(e) => {
                    onChange(e.target.value);
                }} 
                required={required} 
                step={step}
                type="range" 
                value={value} 
            />
            <div className="BTDSlider__labels">
                {steps.map((stepValue, index) => (
                    <span key={index} className="BTDSlider__labels__label">{stepValue}</span>
                ))}
            </div>
        </div>
        {error &&
            <p className='BTDSlider__error red'>{error}</p>
        }
        </>
    )
}

BTDSlider.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    step: PropTypes.number,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

BTDSlider.defaultProps = {
    description: null,
    error: null,
    label: null,
    min: 0,
    max: 200,
    onChange: () => {alert('onChange is not set!')},
    required: false,
    step: 50,
    style: {},
    value: 0
}

export default BTDSlider;                                         