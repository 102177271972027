// Packages
import PropTypes from 'prop-types'

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

const TaxRateListItem = props => {

    const { id, is_default, isLoading, name, rate, launchForm, onDelete } = props;

    const { destroy: destroyItem } = useApi('/taxrates/' + id, false);

	const deleteItem = async () => {

		await destroyItem('tax rate', 'Tax rate deleted!');
		onDelete();
	}

    function removeTrailingZeros(number) {

        const stringWithoutTrailingZeros = number.toString().replace(/(\.\d*?)0+$/, '$1');
        return parseFloat(stringWithoutTrailingZeros);
    }

    return(
        <div className='TaxRateListItem'>

            <p>{isLoading ? <BTDSkeleton /> : name}</p>

            <p>{! isLoading ? rate ? removeTrailingZeros(rate) + ' %' : '' : <BTDSkeleton />}</p>

            <p className='light-blue'>{! isLoading ? is_default ? 'Default' : '' : <BTDSkeleton />}</p>

            <BTDMenu icon={<EllipsisIcon height="18px" />}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>
        </div>
    )
}

TaxRateListItem.propTypes = {
	default: PropTypes.bool,
	id: PropTypes.number,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
	rate: PropTypes.string,
}

TaxRateListItem.defaultProps = {
    default: false,
	id: null,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set!')},
	rate: '',
}

export default TaxRateListItem;