// Packages
import { createContext, useContext } from 'react';
import dayjs from 'dayjs';

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

// Other
import useApi from 'hooks/useApi';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {

    const { data: appdata, onChange: refreshAppdata, isLoading } = useApi('/appdata');

    const currency = (value) => {

        if( appdata ) {

            const formattedValue = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: appdata.settings.currency,
            }).format(value);
        
            return formattedValue;
        }
    }

    const date = (value) => {

        if( appdata ) {

            return dayjs(value).format(appdata.settings.date_format);
        }
    }
    const datetime = (value) => {

        if( appdata ) {

            return dayjs(value).format(appdata.settings.date_format + ' ' + appdata.settings.time_format);
        }
    }
    const time = (value) => {

        if( appdata ) {

            return dayjs(value).format(appdata.settings.time_format);
        }
    }

    const orderNumber = (value) => {

        if( appdata ) {

            return appdata.settings.order_number_prefix + String(value).padStart(5, '0');
        }
    }

    if( isLoading ) {
        return(
            <div 
                style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        height: '100vh', 
                        justifyContent: 'center',
                        width: '100%'
                    }}
            >
                <BTDProgress type='circular' height={100} width={100} />
            </div>
        );
    }

    return(

    <AppDataContext.Provider value ={{
        appdata: appdata.tenant,
        currency,
        date,
        datetime,
        orderNumber,
        user: appdata.user,
        settings: appdata.settings,
        refreshAppdata,
        time
    }}>
        {children}
    </AppDataContext.Provider>
    )
}


export const useAppData = () => {
    return useContext(AppDataContext);
};