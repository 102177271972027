// Packages
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/OrderView';
import OrderTicketsList from 'components/Order/TicketList';
import OrderTransactionsList from 'components/Order/TransactionList';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import OrderRefundList from 'components/Order/RefundList';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import ContactEditScreen from 'screens/Contacts/Edit';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const OrderViewScreen = () => {

	const { orderId } = useParams();

	const { data: order, onChange, isLoading: orderLoading } = useApi('/orders/' + orderId + '/?with=event');
	const { data: tickets, onChange: onTicketChange, isLoading: ticketsLoading } = useApi('/orders/' + orderId + '/items?with=ticket_type,event');
	const { data: transactions, isLoading: transactionsLoading } = useApi('/orders/' + orderId + '/transactions?with=gateway');
	const { data: refunds, onChange: onRefundsChange, isLoading: refundsLoading} = useApi('/orders/' + orderId + '/refunds');

	const [formActive, setFormActive] = useState(false);
	const [contactId, setContactId] = useState();
	
	return (
		<>
		<Layout order={order} isLoading={orderLoading}>
			
			<div className='OrderView'>

				<div className='OrderView__main'>

					<h3 className='black'>Tickets</h3>
					
					<OrderTicketsList items={tickets} isLoading={ticketsLoading} onSubmit={() => {onTicketChange()}} />

					<h3 className='black'>Refunds</h3>

					<OrderRefundList items={refunds} isLoading={refundsLoading} onSubmit={() => {onRefundsChange()}} />

					<h3 className='black'>Transactions</h3>

					<OrderTransactionsList items={transactions} isLoading={transactionsLoading} />

				</div>

				<div className='OrderView__sidebar'>
					
					<div className='OrderView__sidebar__block'>

						<div className='OrderView__sidebar__block__header'>
							Customer
						</div>

						<div className='OrderView__sidebar__block__main'>

							<BTDMenu icon={<EllipsisIcon height="18px" />} style={{position: 'absolute', top: '12px', right: '10px'}}>
								<li 
									onClick={() => {
										setFormActive(true);
										setContactId(order ? order.contact.id : null);
										}}
								>
									<EditIcon style={{marginRight: '5px'}} /> Edit Customer
								</li>
							</BTDMenu>

							<Link className='OrderView__sidebar__block__main__link semi-bold light-blue' to={`/contacts/${order && order.contact ? order.contact.id : ''}`}>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.firstname + ' ' + order.contact.lastname : ''}</Link>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.organisation : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.address1 : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.address2 : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.city : ''}</p>

							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.postcode : ''}</p>
							<br/>
							<a href={'mailto:' + order.email} className='OrderView__sidebar__block__main__link black medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.email : ''}</a>
							<br/>
							<p className='medium'>{orderLoading ? <BTDSkeleton /> : order.contact ? order.contact.telephone : ''}</p>
							<br/>


						</div>

					</div>


					<div className='OrderView__sidebar__block'>

						<div className='OrderView__sidebar__block__header'>
							Actions
						</div>

						<div className='OrderView__sidebar__block__main'>

							<Link className='OrderView__sidebar__block__main__link red' to={'/orders/' + orderId + '/refund'}>Cancel & Refund Order</Link>

						</div>

					</div>

				</div>

			</div>

		</Layout>

		{formActive &&
			<ContactEditScreen
				contactId={contactId}
				onClose={() => {setFormActive(false)}}
				onSubmit={() => {
					setFormActive(false);
					onChange();
				}}
			/>
		}
		</>
	)

}

export default OrderViewScreen;