// Packages
import { Link, useParams } from 'react-router-dom';

// Components
import Layout from 'layouts/EventsView';
import CopyToClipboard from 'components/BTDUI/CopyToClipboard';
import MicrositeSectionList from 'components/Microsite/MicrositeSection/List';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const EventViewMicrositeScreen = () => {

	const { eventId } = useParams();

	const { data: event, isLoading, onChange } = useApi('/events/' + eventId + '?with=microsite');
	const { data: sections, isLoading: sectionsLoading } = useApi('/events/' + eventId + '/microsite/sections?with=template');

	return (
		<Layout activeTab={`/events/${eventId}/site`} event={event} isLoading={isLoading} onSubmit={() => {onChange()}}>

			<div className='Microsite__top'>

				<div className='Microsite__top__link'>
					<span>Public URL: </span>
					<Link className='light-blue' to={event && event.microsite ? event.microsite.url : '' } target='_blank'>{event && event.microsite ? event.microsite.url : ''}</Link>
					<CopyToClipboard text={event && event.microsite ? event.microsite.url : ''} />
				</div>

				<BTDButton url={'/events/' + eventId + '/site-designer'} label='Microsite Designer'/>

			</div>

			<div className='Microsite__site'>
				<MicrositeSectionList 
					eventId={eventId}
					isLoading={sectionsLoading}
					items={sections}  
					max_width={event && event.microsite ? event.microsite.max_width : '2000'}
					show_edit={false}
					start_date={event.start_at}
				/>
			</div>

		</Layout>
	)

}

export default EventViewMicrositeScreen;