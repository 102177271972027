// Packages
import { useState } from 'react';
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import CreateOrderTicketScreen from 'screens/Orders/Tickets/Create';
import EditOrderTicketScreen from 'screens/Orders/Tickets/Edit';
import BTDTab from 'components/BTDUI/BTDTab';
import FulfillOrderTicketScreen from 'screens/Orders/Tickets/Fulfill';
import GuestIcon from 'components/BTDUI/icons/GuestIcon';
import TicketIcon from 'components/BTDUI/icons/TicketIcon';
import RefundIcon from 'components/BTDUI/icons/RefundIcon';
import TickIcon from 'components/BTDUI/icons/TickIcon';
import DownloadPDF from 'components/BTDUI/DownloadPDF';

// Other
import './styles.css';

const OrderTicketListItem = ({ event, guest_name, id, index, isLoading, onSubmit, status, ticket_type, total } ) => {

	const { orderId } = useParams();

	const { currency, date } = useAppData();

	const [formActive, setFormActive] = useState(false);
	const [fulfillFormActive, setFulfillFormActive] = useState(false);


	return (
		<div className='OrderTicketListItem'>

			{isLoading ?
			
				<>
					<div className='OrderTicketListItem__value'>
						<p><BTDSkeleton /></p>
						<p><BTDSkeleton /></p>
					</div>
					<div className='OrderTicketListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='OrderTicketListItem__value'>
						<p><BTDSkeleton /></p>
						<p><BTDSkeleton /></p>
					</div>
					<div className='OrderTicketListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='OrderTicketListItem__value'>
						<p><BTDSkeleton /></p>
					</div>
					<div className='OrderTicketListItem__value'></div>
				</>
			:

				<>

					<div className='OrderTicketListItem__value OrderTicketListItem__value-index'>
						<p className='medium opacity'>#{isLoading ? <BTDSkeleton /> : index + 1}</p>
					</div>
					<div className='OrderTicketListItem__value OrderTicketListItem__value-name'>
						{guest_name ? 
							<p className='medium'>{guest_name}</p>
						:
							<p className='red medium'>Not Provided</p>
						}
					</div>
					<div className='OrderTicketListItem__value'>
						<p className='medium'>{ticket_type ? ticket_type.name : ''}</p>
					</div>
					<div className='OrderTicketListItem__value'>
						<p className='semi-bold'>{event ? event.name : ''}</p>
						<p className='medium'>{event ? date(event.start_at) : ''}</p>
					</div>
					<div className='OrderTicketListItem__value'>{currency(total)}</div>
					<div className='OrderTicketListItem__value'><BTDTab type={status ?? 'unknown'} /></div>
					<div className='OrderTicketListItem__value'>
						{status !== 'refunded' &&
							<BTDMenu icon={<EllipsisIcon height="18px"/>}>
								<li onClick={() => {setFormActive(true)}}><GuestIcon height='20px' style={{marginRight: '8px', transform: 'translateY(2px)'}} width='20px'/>Edit guest details</li>
								<li onClick={() => {setFulfillFormActive(true)}}><TickIcon height='18px' style={{marginRight: '8px', transform: 'translateY(2px)'}} width='20px'/>Fulfill Ticket</li>
								<DownloadPDF 
									default_filename={event.name + '_' + guest_name + '.pdf'}
									text='Download Ticket'
									url={'https://api.events.oakora.com/orders/' + orderId + '/items/' + id + '/ticket'}
								>
									<TicketIcon height='20px' style={{marginRight: '8px', transform: 'translateY(2px)'}} width='20px'/>
								</DownloadPDF>
								{status !== 'refunded' &&
									<Link 
										className='black' 
										style={{color: '#253551'}}
										to={'/orders/' + orderId + '/refund?id=' + id} 
									>
										<RefundIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Refund
									</Link>
								}
							</BTDMenu>
						}

					</div>

					{formActive && ! id &&
						<CreateOrderTicketScreen 
							onClose={() => {setFormActive(false)}}
							onSubmit={() => {setFormActive(false)}}
							orderId={orderId}
						/>
					}
					{formActive && id &&
						<EditOrderTicketScreen 
							itemId={id}
							onClose={() => {setFormActive(false)}}
							onSubmit={() => {
								setFormActive(false);
								onSubmit();
							}}
							orderId={orderId}
						/>
					}
					{fulfillFormActive && 
						<FulfillOrderTicketScreen 
							itemId={id}
							onClose={() => {setFulfillFormActive(false)}}
							onSubmit={() => {
								setFulfillFormActive(false);
								onSubmit();
							}}
							orderId={orderId}
						/>
					}
				</>

			}

		</div>
	)
}

OrderTicketListItem.propTypes = {
	event: PropTypes.object,
	guest_name: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	index: PropTypes.number,
	isLoading: PropTypes.bool,
	onSubmit: PropTypes.func,
	status: PropTypes.string,
	ticket_type: PropTypes.object,
	total: PropTypes.string,
}

OrderTicketListItem.defaultProps = {
	event: {},
	guest_name: null,
	id: null,
	index: null,
	isLoading: false,
	fulfilment_status: '',
	onSubmit: () => {alert('onSubmit is not set!')},
	status: null,
	ticket_type: {},
	total: null
}

export default OrderTicketListItem;