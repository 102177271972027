// Packages

import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Events/List'
import ArchivedScreen from 'screens/Events/Archived'
import ViewScreen from 'screens/Events/View'
import ViewTicketTypesScreen from 'screens/Events/View-TicketTypes'
import ViewWaitingScreen from 'screens/Events/View-Waiting'
import ViewSiteScreen from 'screens/Events/View-Site'
import ViewSiteDesignerScreen from 'screens/Events/View-Site/Designer'
import ViewAccommodationScreen from 'screens/Events/View-Accommodation'
import ViewSpeakersScreen from 'screens/Events/View-Speakers'
import ViewAttendeesScreen from 'screens/Events/View-Attendees'
import ViewSettingsScreen from 'screens/Events/View-Settings'


const EventRoutes = () => 
{
	return (
		<Routes>
			<Route exact path="/*" element={<ListScreen/>}/>
			<Route exact path="/archived" element={<ArchivedScreen/>}/>
			<Route exact path="/:eventId" element={<ViewScreen/>}/>
			<Route exact path="/:eventId/tickets" element={<ViewTicketTypesScreen/>}/>
			<Route exact path="/:eventId/waiting" element={<ViewWaitingScreen/>}/>
			<Route exact path="/:eventId/site" element={<ViewSiteScreen/>}/>
			<Route exact path="/:eventId/site-designer" element={<ViewSiteDesignerScreen/>}/>
			<Route exact path="/:eventId/accommodation" element={<ViewAccommodationScreen/>}/>
			<Route exact path="/:eventId/speakers" element={<ViewSpeakersScreen/>}/>
			<Route exact path="/:eventId/attendees" element={<ViewAttendeesScreen/>}/>
			<Route exact path="/:eventId/settings" element={<ViewSettingsScreen/>}/>
		</Routes>
	);
}


export default EventRoutes;