// Packages
import PropTypes from 'prop-types'

// Components
import EventForm from 'components/Event/Form';

// Other
import useApi from 'hooks/useApi';

const EventEditScreen = ({ eventId, onClose, onSubmit }) => {

    const { data: event, put: updateEvent, errors } = useApi('/events/' + eventId);

	const submit = async (values) => {

        if( await updateEvent(values, 'Event updated!')) {

            onSubmit();
        }
    }

    return(
        <EventForm event={event} onClose={onClose} onSubmit={submit} type='edit' errors={errors} />
    )
}

EventEditScreen.propTypes = {
    eventId: PropTypes.number,
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

EventEditScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default EventEditScreen;