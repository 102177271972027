// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const DownArrowIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="DownArrowIcon" onClick={onClick} style={style}>
            <svg enableBackground="new 0 0 32 32" height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                    <path d="m29.6043 10.528-12.0735 12.8281c-.83.8819-2.2315.8819-3.0615 0l-12.0736-12.8281c-.9071-.9639-.2238-2.5455 1.0998-2.5455h25.0089c1.3237 0 2.007 1.5816 1.0999 2.5455z" fill={colour}/>
                </g>
            </svg>
        </div>
    )
}

DownArrowIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DownArrowIcon.defaultProps = {
    colour: "currentColor",
    height: "17px",
    onClick: () => {},
    style: {},
    width: "17px", 
}

export default DownArrowIcon;