// Packages
import { useEffect, useState, useCallback } from 'react'
import axios from 'axios';

// Context
import { useUI } from 'context/UI'
import { useSnackbar } from 'context/Snackbar';

// Other
import Config from 'other/config';


const useApi = (url, runGet=true ) => {

	const [ data, setData ] = useState([])
	const [ meta, setMeta ] = useState([])
	const [ isLoading, setIsLoading ] = useState(runGet)
	const [ isError, setIsError ] = useState(true)
	const [ errors, setErrors ] = useState({})
	const [ refresh, setRefresh ] = useState(false)

	const { setLoading } = useUI();
    const { showSnackbar } = useSnackbar();


    const setToken = () => {
        const token = localStorage.getItem('access-token');   
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    

    setToken();

    

	useEffect(() => 
	{
        if( runGet ) {

            setLoading(true);

            const fetchData = async () => {

                try {

                    const response = await axios.get(Config.API_URL + url)
                        
                    setData(response.data.data);
                    setMeta(response.data.meta);
                    setIsError(false);
                    setIsLoading(false);

                } catch (error) {
                    setIsError(true);
                    setErrors(error.response.data.errors);
                    showSnackbar(error.response.data.message, 'error')
                }
                
                setLoading(false);
            }

            fetchData();
        }
    // eslint-disable-next-line
	}, [url, refresh])


    


    const get = async (successMessage) => 
	{
        setIsLoading(true);
        setLoading(true);


        try 
        {
            const response = await axios.get(Config.API_URL + url);

            setData(response.data.data);
            setIsError(false);
            setIsLoading(false);
            if( successMessage ) {

                showSnackbar(successMessage, 'success');
            }
            setIsLoading(false);
            setLoading(false);

            return true;
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error')
            setIsLoading(false);
            setLoading(false);

            return false;
        }
        
    };

	const put = async (postData, successMessage) => 
	{
        setIsLoading(true);
        setLoading(true);


        try 
        {
            const response = await axios.put(Config.API_URL + url, postData);

            setData(response.data.data);
            setIsError(false);
            setIsLoading(false)
            showSnackbar(successMessage, 'success');
            setIsLoading(false);
            setLoading(false);

            return true;
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error')
            setIsLoading(false);
            setLoading(false);

            return false;
        }
        
    };

    const post = async (postData, successMessage) => 
    {
        setIsLoading(true);
        setLoading(true);


        try 
        {
            const response = await axios.post(Config.API_URL + url, postData);

            if( successMessage ) {

                showSnackbar(successMessage, 'success');
            }
            
            setData(response.data.data);
            setIsError(false);
            setIsLoading(false);
            setLoading(false);

            return true;

        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error')
            setIsLoading(false);
            setLoading(false);

            return false;
        }
        
    };


    const destroy = async (itemType, successMessage) => 
    {
        if ( ! window.confirm('Are you sure that you want to delete this '+ itemType + '?') )
        {
            return false;
        }

        setIsLoading(true);
        setLoading(true);


        try 
        {
            const response = await axios.delete(Config.API_URL + url);

            setData(response.data.data);
            setIsError(false);
            setIsLoading(false);
            showSnackbar(successMessage, 'success');
            
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error')
        }

        setIsLoading(false);
        setLoading(false);
    };

    const onChange = useCallback(() => {
        setRefresh(prevRefresh => !prevRefresh);
    }, []);

	return { 
        data,
        meta,
		isLoading, 
		isError, 
        errors,
        get,
		put, 
		post,
        destroy,
        onChange,
	}
}

export default useApi