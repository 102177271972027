// PackagesS
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

// components
import Layout from 'layouts/EventsView';
import CopyToClipboard from 'components/BTDUI/CopyToClipboard';
import GoogleMap from 'components/BTDUI/GoogleMap';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ExportFile from 'components/BTDUI/ExportFile';

// other
import useApi from 'hooks/useApi';
import './styles.css';
import config from 'other/config';

const EventViewScreen = () => {

	const { eventId } = useParams();

	const [addressArray, setAddressArray] = useState([]);

	const { data: event, put: archiveEvent, onChange, isLoading } = useApi('/events/' + eventId + '?with=venue,microsite');

	useEffect(() => {
		
		if( event && event.venue && event.venue.address) {

			setAddressArray(event.venue.address.split("\n"))
		}
	}, [event])

	
	return (
		<Layout activeTab={`/events/${eventId}`} event={event} isLoading={isLoading} onSubmit={() => {onChange()}}>
			
			<div className='EventView'>
				<div className='EventView__main'></div>

				<div className='EventView__sidebar'>
					
					<div className='EventView__sidebar__block'>

						<div className='EventView__sidebar__block__header'>
							Venue
						</div>

						<div className='EventView__sidebar__block__main'>

							<p className='bold'>{isLoading ? <BTDSkeleton /> : event && event.venue ? event.venue.name : ''}</p>

							{addressArray.length !== 0 ?

								addressArray.map(line => {
									return(
										<p key={line} className='medium black'>{line }</p>
									)
								})

								:
								'' 
							}

								<p className='medium'>{isLoading ? <BTDSkeleton /> :event && event.venue ? event.venue.city : ''}</p>

								<p className='medium'>{isLoading ? <BTDSkeleton /> :event && event.venue ? event.venue.postcode : ''}</p>

								{ ! isLoading &&
									<GoogleMap address={event && event.venue ? event.venue.address + ', ' + event.venue.city + ', ' + event.venue.postcode: ''} />
								}

						</div>

					</div>

					<div className='EventView__sidebar__block'>

						<div className='EventView__sidebar__block__header'>
							Microsite URL
						</div>

						<div className='EventView__sidebar__block__main'>
							
							<div className='EventView__sidebar__block__main__url'>
								<a href={event && event.microsite ? event.microsite.url : ''} className='EventView__sidebar__block__main__url__text light-blue' rel="noreferrer" target='_blank'>{isLoading ? <BTDSkeleton /> : event && event.microsite ? event.microsite.url : ''}</a>
								<CopyToClipboard text={event && event.microsite ? event.microsite.url : ''} style={{margin: '0'}}/>
							</div>

						</div>

					</div>

					<div className='EventView__sidebar__block'>

						<div className='EventView__sidebar__block__header'>
							Actions
						</div>

						<div className='EventView__sidebar__block__main'>

							{! isLoading &&
							<>
								<p className='EventView__sidebar__block__main__link red'>Cancel & Refund Event</p>

								{ event && ! event.archived ?
								
									<p 
										className='EventView__sidebar__block__main__link yellow' 
										onClick={() => {

											if( window.confirm('Are you sure you want to archive this event?') ) {

												archiveEvent({archived: 1}, 'Event archived');
												onChange();
											}
										}}
									>
										Archive Event
									</p>
								:
									<p 
										className='EventView__sidebar__block__main__link yellow'
										onClick={() => {

											if( window.confirm('Are you sure you want to unarchive this event?') ) {

												archiveEvent({archived: 0}, 'Event unarchived');
												onChange();
											}
										}}
									>
										Unarchive Event
									</p>
								}
								<ExportFile
									dataset='event_attendees'
									link='Export Attendees List'
									filename='Attendees List'
									subject_id={eventId}
									url={config.API_URL + '/exports'}
								/>
							</>
							}
						</div>

					</div>

				</div>

			</div>

		</Layout>
	)

}

export default EventViewScreen