// PackagesS
import { useState } from 'react'
import { useParams } from 'react-router-dom';

// components
import Layout from 'layouts/EventsView';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDPagination from 'components/BTDUI/BTDPagination';
import BTDProgress from 'components/BTDUI/BTDProgress';
import AttendeesList from 'components/Attendees/List';
import ExportFile from 'components/BTDUI/ExportFile';

// other
import useApi from 'hooks/useApi';
import './styles.css';
import config from 'other/config';

const EventAttendeesViewScreen = () => {

	const { eventId } = useParams();

	const [page, setPage] = useState(1);

	const { data: event, eventLoading } = useApi('/events/' + eventId + '?with=tickettypes');
	const { data: attendees, attendeesLoading, meta, onChange } = useApi('/events/' + eventId + '/attendees?page=' + page);

	
	return (
		<Layout activeTab={`/events/${eventId}/attendees`} event={event} isLoading={eventLoading}>
			
			<div className='EventAttendeesView'>

				<div className='EventAttendeesView__main'>

					{meta && 
						<p className='black opacity'>Showing {meta.from} to {meta.to} of {meta.total} attendees</p>
					}

					<AttendeesList items={attendees} isLoading={attendeesLoading} />

					{! attendeesLoading && meta.total > meta.per_page &&
						<BTDPagination
							currentPage={meta.current_page} 
							totalPages={meta.last_page}
							onChange={(value) => {
								setPage(value);
								onChange();
							}}
							style={{margin: '0 auto', width: 'max-content'}}
						/>
					}	

				</div>

				<div className='EventAttendeesView__sidebar'>
					
					<div className='EventAttendeesView__sidebar__block'>

						<div className='EventAttendeesView__sidebar__block__header'>
							Summary
						</div>

						<div className='EventAttendeesView__sidebar__block__main EventAttendeesView__sidebar__block__main-summary'>

							<p className='EventAttendeesView__sidebar__block__main__total medium black'>{attendeesLoading ? <BTDSkeleton /> : meta && meta.total ? meta.total.toLocaleString() : ''}</p>

							<p className='medium black'>Total Attendees</p>

						</div>

					</div>

					<div className='EventAttendeesView__sidebar__block'>

						<div className='EventAttendeesView__sidebar__block__header'>
							By Ticket Type
						</div>

						<div className='EventAttendeesView__sidebar__block__main'>
							
							{eventLoading ? 
								
								<BTDProgress height={50} type='circular' width={50} />

								:

								event.tickettypes && event.tickettypes.map((ticket) => {

									return(
										<div className='EventAttendeesView__sidebar__block__main__ticket' key={ticket.id}>
											<span className='medium black'>{ticket.name}</span>
											<span className='semi-bold black'>{ticket.sold.toLocaleString()} 
												<span className='opacity black medium'> /{ticket.capacity.toLocaleString()}</span>
											</span>
										</div>
									)
								})
						}

						</div>

					</div>

					<div className='EventAttendeesView__sidebar__block'>

						<div className='EventAttendeesView__sidebar__block__header'>
							Actions
						</div>

						<div className='EventAttendeesView__sidebar__block__main-links'>

								<ExportFile
									dataset='event_attendees'
									link='Export Attendees List'
									filename='Attendees List'
									subject_id={eventId}
									url={config.API_URL + '/exports'}
								/>
						</div>

					</div>

				</div>

			</div>

			

		</Layout>
	)

}

export default EventAttendeesViewScreen;