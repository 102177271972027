// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import QRProcessing from 'components/QRProcessing';

const TicketForm = ({ errors, isLoading, itemId, onClose, onSubmit, orderId, ticket, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    // Load TicketType values into form
	useEffect(() => {

		if ( ticket )
		{
            setData(ticket);
		}

	}, [ticket]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create Ticket' : 'Edit Ticket Details'}</h3>

            <div className='form__grid-2' style={{position: 'relative'}}>
                <div>

                    <p className='semi-bold'>Ticket Type</p>
                    <p className='medium'>{isLoading ? <BTDSkeleton width='100px' /> : ticket.ticket_type ? ticket.ticket_type.name : ''}</p>
                    <br/>
                    <p className='semi-bold'>Serial Number</p>
                    <p className='medium'>{isLoading ? <BTDSkeleton width='100px' /> : ticket.serial}</p>
                    <br/>

                    <BTDTextField
                        error={errors && errors.guest_name ? errors.guest_name : null} 
                        label='Guest name'
                        onChange={(value) => {
                            setData({...data, guest_name: value});
                            setChangedData({...changedData, guest_name: value});
                        }} 
                        required={true}
                        value={data.guest_name ?? ''} 
                    />
                </div>
                <div>
                    {! ticket.qr_code_url &&
                        <QRProcessing style={{position: 'absolute', right: '0', top: '0'}}/>
                    }
                    <img 
                        alt='QR code'
                        src={ticket.qr_code_url} 
                        style={{position: 'absolute', right: '0', top: '0'}}
                        width='150' 
                    />
                </div>
            </div>

            <BTDButton
                formButton={true}
                label={type === 'create' ? 'Save ticket' : 'Update ticket'}
                onClick={() => {onSubmit(changedData)}} 
            />

        </FormTemplate>

    )

}

TicketForm.propTypes = {
	errors: PropTypes.object,
	event: PropTypes.object, 
    isLoading: PropTypes.bool,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ticketType: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.string
}

TicketForm.defaultProps = {
    errors: {},
    event: null,
    isLoading: false,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ticketType: {},
    type: 'create'
}

export default TicketForm;