// Packages
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

// Context

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

import Config from 'other/config'


const Auth = props => {

	const [, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [, setErrors] = useState();
    const [refreshAuth, ] = useState();

	const [searchParams, setSearchParams] = useSearchParams();

	if ( searchParams.get('_token') )
	{
		localStorage.setItem('access-token', searchParams.get('_token'))

		setSearchParams({})
	}



	useEffect(() => 
    {
        if ( localStorage.getItem('access-token') )
        {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

            setLoading(true);
            setErrors(false);

            axios.get(Config.AUTH_API_URL + '/user')
            .then(function (response) 
            {
                setUser(response.data.data);
                setAuthenticated(true);
            })
            .catch(function (error) 
            {
                setErrors(error.response.data.errors);
            })
            .finally(function () 
            {
                setLoading(false)
            });
        }
        else
        {
            redirectToAuth()
        }

    }, [refreshAuth])


    const redirectToAuth = (url) => {
    	window.location = Config.AUTH_URL + '/auth/app?return=' + window.location.href
    }




	/*
	Auth0 is processing - show full-screen loading spinner
	*/	
	if ( isLoading ) 
	{
		return (
			<div 
                style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        height: '100vh', 
                        justifyContent: 'center',
                        width: '100%'
                    }}
            >
                <BTDProgress type='circular' height={100} width={100} />
            </div>
		);
	}



	if ( isAuthenticated ) 
	{
		return props.children;
	}
	else 
	{
		redirectToAuth()
	}
}

export default Auth;