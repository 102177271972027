// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';

const DashboardLayout = ({children}) => {

	return (
		<>
		<TitleBar title="Dashboard"/>
        <Container>
			{children}
        </Container>
        </>
	)
}

export default DashboardLayout