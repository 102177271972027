// Packages
import { useState } from 'react'

// Components
import ContactList from 'components/Contact/List';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import ContactLayout from 'layouts/Contacts';
import ContactCreateScreen from '../Create';
import ExportFile from 'components/BTDUI/ExportFile';

// Other
import useApi from 'hooks/useApi'
import config from 'other/config';
import './styles.css';

const ContactsListScreen = () => {

	const { data: contacts, isLoading, onChange } = useApi('/contacts');

	const [formActive, setFormActive] = useState(false);

	
	return (
		<>

		<ContactLayout>
			<div className='ContactsListScreen'>

				<div className='ContactsListScreen__main'>

					<ContactList 
						items={contacts} 
						isLoading={isLoading} 
					/>

				</div>

				<div className='ContactsListScreen__sidebar'>
					
					<div className='ContactsListScreen__sidebar__block'>

						<div className='ContactsListScreen__sidebar__block__header'>
							Actions
						</div>

						<div className='ContactsListScreen__sidebar__block__main'>

								<ExportFile
									dataset='contacts'
									link='Export Contacts List'
									filename='Contacts List'
									url={config.API_URL + '/exports'}
								/>
						</div>

					</div>
				</div>
			</div>
		</ContactLayout>
		

		<AddIcon onClick={() => {
			setFormActive(true);
		}} />

		{formActive &&
			<ContactCreateScreen
				onClose={() => {
					setFormActive(false);
				}} 
				onSubmit={() => {
					onChange();
					setFormActive(false);
				}} 
			/>
			}
		</>
	)

}

export default ContactsListScreen;