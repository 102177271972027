// Components
import TitleBar from 'components/TitleBar';
import Container from 'components/BTDUI/Container';
import PropTypes from 'prop-types';

const EventsLayout = props => {

    const { activeTab } = props;

    let currentTab = '';

    if( activeTab ) {

      switch (activeTab) {
        case '/events':
          currentTab = 'Active Events';
          break;
        case '/events/archived':
          currentTab = 'Archived Events';
          break;
      
        default:
          currentTab = 'Active Events';
          break;
      }
    }

	return (
		<>
		<TitleBar 
      activeTab={currentTab}
      tabs={[
        {label: 'Active Events', url: '/events'},
        {label: 'Archived Events', url: '/events/archived'}
      ]} 
      title="Events" 
      >
      </TitleBar>
      
      <Container>
        {props.children}
      </Container>
      </>
	)
}

EventsLayout.propTypes = {
  activeTab: PropTypes.string,
}

EventsLayout.defaultProps = {
  activeTab: '/events', 
}

export default EventsLayout