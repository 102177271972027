// Packages
import PropTypes from 'prop-types'

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';

// Other
import './styles.css';
import useApi from 'hooks/useApi';

const WaitingListitem = ({ created_at, email, eventId, id, isLoading, name, launchForm, onDelete }) => {

    const { date } = useAppData();

    const { destroy: destroyItem } = useApi('/events/' + eventId + '/waitinglist/items/' + id, false);

	const deleteItem = async () => {

		await destroyItem('entry', 'Entry deleted!');
		onDelete();
	}

    return(
        <div className='WaitingListItem'>

            <p>{isLoading ? <BTDSkeleton /> : name}</p>

            <p>{isLoading ? <BTDSkeleton /> : email}</p>

            <p>{isLoading ? <BTDSkeleton /> : date(created_at) }</p>

            <BTDMenu icon={<EllipsisIcon height="18px" />}>
				<li onClick={() => {launchForm(id)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
				<li onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
			</BTDMenu>
        </div>
    )
}

WaitingListitem.propTypes = {
	date: PropTypes.string,
	email: PropTypes.string,
	id: PropTypes.number,
	launchForm: PropTypes.func,
	name: PropTypes.string,
	onDelete: PropTypes.func,
}

WaitingListitem.defaultProps = {
	date: '',
	email: '',
	id: null,
	launchForm: () => {alert('launchForm is not set!')},
	name: '',
	onDelete: () => {alert('onDelete is not set!')},
}

export default WaitingListitem;