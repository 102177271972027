import SearchIcon from 'components/BTDUI/icons/SearchIcon';
import './styles.css';

const SearchButton = () => {
	return (
		<div className="SearchButton">
			<SearchIcon colour='#fff' height="18px" width="18px"/>
		</div>
	)
}

export default SearchButton;