// Packages
import PropTypes from 'prop-types';

// Components
import MicrositeHeader from '../Header';
import MicrositeImage from '../Image';
import MicrositeText from '../Text';
import MicrositeCountdown from '../Countdown';
import MicrositeAccommodation from '../Accommodation';
import BTDMenu from 'components/BTDUI/BTDMenu';
import UpArrowIcon from 'components/BTDUI/icons/UpArrowIcon';
import DownArrowIcon from 'components/BTDUI/icons/DownArrowIcon';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import MicrositeVenue from '../Venue';
import MicrositeSpeakers from '../Speakers';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const MicrositeSectionListItem = ({ eventId, index, isLoading, onEdit, onDelete, onMove, section, show_edit, start_date }) => {

    const { destroy: destroySection } = useApi('/events/' + eventId + '/microsite/sections/' + section.id, false);

    const deleteSection = async () => {

		await destroySection('section', 'Section deleted!');
		onDelete();
	}

    return(
        <div key={section.id} className="MicrositeSectionListItem" id={'section' + section.id} style={{display: `${show_edit ? 'grid' : 'block'}`}}>

            {show_edit &&

                <div className="MicrositeSectionListItem__buttons">

                    <div style={{ 
                        alignItems: 'center',
                        backgroundColor: '#253551', 
                        borderRadius: '50px', 
                        color: '#fff', 
                        cursor: 'pointer',
                        display: 'flex',
                        height: '34px', 
                        justifyContent: 'center',
                        padding: '6px', 
                        width: '34px'
                    }}>
                    <BTDMenu icon={<EditIcon colour='#fff'/>}>
                        <li onClick={() => {onEdit(section)}}><EditIcon style={{marginRight: '5px'}} /> Edit</li>
                        <li onClick={() => {deleteSection()}}><ClearIcon colour='#e02323' height='20px' style={{margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /> Delete</li>
                        {section.template.name !== 'Header' &&
                        <>
                            <div style={{backgroundColor: '#ccc', height: '1px', margin: '14px 0'}} />
                            <li onClick={() => {onMove(index, 'up')}}><UpArrowIcon style={{marginRight: '10px'}} />Move Up</li>
                            <li onClick={() => {onMove(index, 'down')}}><DownArrowIcon style={{marginRight: '10px'}} />Move Down</li>
                        </>
                        }
                    </BTDMenu>
                    </div>

                </div>
            }

            {! isLoading && section.template &&

                <div className="MicrositeSectionListItem__content">
                    {section.template.name === 'Header' &&
                        <MicrositeHeader {...section.config} navigation={section.additional_data ?? []} />
                    }
                    {section.template.name === 'Image' &&
                        <MicrositeImage {...section.config} />
                    }
                    {section.template.name === 'Text' &&
                        <MicrositeText {...section.config} />
                    }
                    {section.template.name === 'Countdown' &&
                        <MicrositeCountdown {...section.config} start_date={start_date} />
                    }
                    {section.template.name === 'Accommodation' &&
                        <MicrositeAccommodation {...section.config} items={section.additional_data ?? []} />
                    }
                    {section.template.name === 'Speakers' &&
                        <MicrositeSpeakers {...section.config} items={section.additional_data ?? []} />
                    }
                    {section.template.name === 'Venue' &&
                        <MicrositeVenue {...section.config} {...section.additional_data} />
                    }
                </div>

            }

        </div>
    )
}

MicrositeSectionListItem.propTypes = {
    eventId: PropTypes.string,
    index: PropTypes.number,
    onEdit: PropTypes.func, 
    onDelete: PropTypes.func, 
    onMove: PropTypes.func, 
    section: PropTypes.object,
    show_edit: PropTypes.bool,
    start_date: PropTypes.string
}

MicrositeSectionListItem.defaultProps = {
    eventId: '', 
    index: null,
    onEdit: () => {alert('onEdit is not set!')},
    onDelete: () => {alert('onDelete is not set!')}, 
    onMove: () => {alert('onMove is not set!')}, 
    section: {},
    show_edit: false,
    start_date: ''
}

export default MicrositeSectionListItem;