// PackagesS
import { useParams } from 'react-router-dom';

// components
import Layout from 'layouts/RefundsView';
import RefundItemList from 'components/Refund/ItemList';
import RefundTransactionsList from 'components/Refund/TransactionList';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const RefundViewScreen = () => {

	const { refundId } = useParams();

	const { data: refund, isLoading } = useApi('/refunds/' + refundId + '/?with=event,contact');
	const { data: refundItems, onChange: onTicketChange, ticketsLoading } = useApi('/refunds/' + refundId + '/items?with=order_item.event,order_item.ticket_type');
	const { data: refundTransactions, transactionsLoading } = useApi('/refunds/' + refundId + '/transactions?with=gateway');

	
	return (
		<Layout refund={refund} isLoading={isLoading}>
			
			<div className='OrderView'>

				<div className='OrderView__main'>

					<h3 className='black'>Refunded Tickets</h3>
					
					<RefundItemList items={refundItems} isLoading={ticketsLoading} onSubmit={() => {onTicketChange()}} />

					<h3 className='black'>Transactions</h3>

					<RefundTransactionsList items={refundTransactions} isLoading={transactionsLoading} />

				</div>

				<div className='OrderView__sidebar'>
					
					<div className='OrderView__sidebar__block'>

						<div className='OrderView__sidebar__block__header'>
							Customer
						</div>

						<div className='OrderView__sidebar__block__main'>

							<p className='bold'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.firstname + ' ' + refund.contact.lastname : ''}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.organisation : ''}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.address1 : ''}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.address2 : ''}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.city : ''}</p>

							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.postcode : ''}</p>
							<br/>
							<a href={`mailto:${refund.contact ? refund.contact.email : ''}`} className='OrderView__sidebar__block__main__link black medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.email : ''}</a>
							<br/>
							<p className='medium'>{isLoading ? <BTDSkeleton /> : refund.contact ? refund.contact.telephone : ''}</p>
							<br/>

						</div>

					</div>

				</div>

			</div>

		</Layout>
	)

}

export default RefundViewScreen;