// Packages
import PropTypes from 'prop-types'

// Components
import TicketTypeForm from 'components/TicketType/Form';

// Other
import useApi from 'hooks/useApi';

const CreateTicketTypeScreen = ({ eventId, onClose, onSubmit }) => {

    const { post: createTicketType, errors } = useApi('/events/' + eventId + '/tickettypes', false);

	const submit = async (values) => {

        if( await createTicketType(values, 'Ticket Type created!')) {

            onSubmit();
        }
    }

    return(
        <TicketTypeForm errors={errors} onClose={onClose} onSubmit={submit} type='create' />
    )
}

CreateTicketTypeScreen.propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

CreateTicketTypeScreen.defaultProps = {
    eventId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default CreateTicketTypeScreen