// Packages
import { useState } from 'react';

// Components
import Layout from 'layouts/Settings';
import FieldSelector from 'components/BTDUI/FieldSelector';
import BTDButton from 'components/BTDUI/BTDButton';

// Context
import { useNav } from 'context/Nav';

// Other
import useApi from 'hooks/useApi';
import '../styles.css';

const SettingsFinancialScreen = () => {

	const { setUnsavedChanges, unsavedChanges } = useNav();

	const { data: settings, errors, post: updateSettings, onChange } = useApi('/settings?section=financial');
	const { data: values } = useApi('/appdata');

	const [changedData, setChangedData] = useState({})

	const submit = async () => {

		await updateSettings({settings: changedData}, 'Settings updated!');

		onChange();
		setChangedData({});
		setUnsavedChanges(false);
	}

	return (
		<Layout activeTab='/settings/financial'>

			<div className='Settings'>

				<div className='Settings__main'>

					<FieldSelector 
						errors={errors} 
						fields={settings}
						fullWidth={true}
						onChange={(newData) => {
							setUnsavedChanges(true);
							setChangedData({...changedData, ...newData})
						}}
						values={values.settings}
						withTitles={true}
					/>

				</div>

				<div className='Settings__sidebar'>

					<div className='Settings__sidebar__button'>
					<BTDButton
							disabled={! unsavedChanges}
							onClick={submit} 
							label='Save Settings'
							size='large'
						/>
					</div>
				</div>
			</div>

		</Layout>
	)

}

export default SettingsFinancialScreen;