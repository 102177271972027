// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// Context
import { useAppData } from 'context/AppData';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import './styles.css';

const RefundTransactionListItem = ({ created_at, gateway, gateway_fee, id, index, isLoading, reference, value }) => {

	const { currency, date, datetime, time } = useAppData();

	const [formActive, setFormActive] = useState(false);

	return (
		<>
		<div className='RefundTransactionListItem'>

			<div className='RefundTransactionListItem__value'>
				<p className='medium opacity centre'>#{isLoading ? <BTDSkeleton /> : index + 1}</p>
			</div>
			<div className='RefundTransactionListItem__value RefundTransactionListItem__value-date'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : date(created_at)}</p>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : time(created_at)}</p>
			</div>
			<div className='RefundTransactionListItem__value'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : reference}</p>
			</div>
			<div className='RefundTransactionListItem__value centre'>
				<p className='medium'>{isLoading ? <BTDSkeleton /> : currency(value)}</p>
			</div>
			<div className='RefundTransactionListItem__value'>
				<p className='medium centre'>{isLoading ? <BTDSkeleton /> :gateway_fee}</p>
			</div>
			<div className='RefundTransactionListItem__value'>

				<BTDMenu icon={<EllipsisIcon height="18px"/>} style={{display: 'block', margin: '0 0 0 auto', width: '40px'}}>
					<li onClick={() => {setFormActive(true)}}>Show details</li>
				</BTDMenu>
			</div>

		</div>

		{formActive &&
			<FormTemplate onClose={() => {setFormActive(false)}}>

				<h3 className='form__title'>Transaction Details</h3>

				<div className='form__grid-3'>
					<div>
						<p className='semi-bold black'>Gateway</p>
						<p className='medium black'>{gateway ? gateway.name : ''}</p>
					</div>
					<div>
						<p className='semi-bold black'>Amount</p>
						<p className='medium black'>{currency(value)}</p>
					</div>
					<div>
						<p className='semi-bold black'>Created</p>
						<p className='medium black'>{datetime(created_at)}</p>
					</div>
					<div>
						<p className='semi-bold black'>Reference</p>
						<p className='medium black'>{reference}</p>
					</div>
					<div>
						<p className='semi-bold black'>Gateway Fee</p>
						<p className='medium black'>{currency(gateway_fee)}</p>
					</div>
				</div>

				{gateway &&
					<BTDButton
						label={'View in ' + gateway.name}
					/>
				}

			</FormTemplate>
		}
		</>
	)
}

RefundTransactionListItem.propTypes = {
	created_at: PropTypes.string,
	gateway_fee: PropTypes.string,
	id: PropTypes.number,
	index: PropTypes.number,
	isLoading: PropTypes.bool,
	reference: PropTypes.string,
	value: PropTypes.string,
}

RefundTransactionListItem.defaultProps = {
	created_at: '',
	gateway_fee: '',
	id: null,
	index: 1,
	isLoading: true,
	reference: '',
	value: '',
}

export default RefundTransactionListItem;