// Packages
import PropTypes from 'prop-types';

// Component
import MicrositeSectionForm from 'components/Microsite/MicrositeSection/Form';

// Other
import useApi from 'hooks/useApi';

const EditMicrositeSectionScreen = props => {

    const { eventId, onClose, onSubmit, section } = props;

    const { put: update, errors } = useApi('/events/' + eventId + '/microsite/sections/' + section.id + '?with=template', false);

    const submit = async (values) => {

        if( await update({config: values}, 'Section updated!')) {

            onSubmit();
        }
    }

    return(
        <MicrositeSectionForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            section={section} 
            template={section.template} 
            type='edit'
        />
    )
}

EditMicrositeSectionScreen.propTypes = {
    eventId: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    section: PropTypes.object
}

EditMicrositeSectionScreen.defaultProps = {
    eventId: '',
	onClose: () => {alert('onClose is not set!')},
	onSubmit: () => {alert('onSubmit is not set!')},
    section: {}
}

export default EditMicrositeSectionScreen;