// Components
import OrderList from 'components/Order/List';

// Other
import useApi from 'hooks/useApi'
import OrdersLayout from 'layouts/Orders';

const OrderListScreen = () => {

	const { data: orders, isLoading } = useApi('/orders?with=event');

	
	return (
		<OrdersLayout>
			<OrderList items={orders} isLoading={isLoading} />
		</OrdersLayout>
	)

}

export default OrderListScreen;