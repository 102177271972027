// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// Componemts
import FormTemplate from 'components/BTDUI/FormTemplate';
import useApi from 'hooks/useApi';
import BTDRadioField from 'components/BTDUI/fields/BTDRadioField';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';

const TicketTypeForm = props => {

    const { errors, onClose, onSubmit, ticketType, type, } = props;

    const { data: taxRates } = useApi('/taxrates');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    const submit = () => {

		onSubmit(changedData);
	}

    // Load TicketType values into form
	useEffect(() => {

		if ( ticketType )
		{
            setData(ticketType);
		}

	}, [ticketType]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create Ticket Type' : 'Edit Ticket Type'}</h3>
        
            <BTDTextField
                error={errors && errors.name ? errors.name : null} 
                label='Ticket Type name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextareaField
                error={errors && errors.description ? errors.description : null} 
                label='Description'
                onChange={(value) => {
                    setData({...data, description: value});
                    setChangedData({...changedData, description: value});
                }} 
                rows={2}
                value={data.description ?? ''} 
            />

            <BTDTextField
                error={errors && errors.price ? errors.price : null} 
                label='Price (ex tax)'
                onChange={(value) => {
                    setData({...data, price: value});
                    setChangedData({...changedData, price: value});
                }} 
                required={true}
                value={data.price ?? ''} 
            />

            <div className='form__flexContainer'>
                <BTDSelectField
                    error={errors && errors.tax_rate_id ? errors.tax_rate_id : null} 
                    label="Tax rate"
                    onChange={(value) => {
                        setData({...data, tax_rate_id: value});
                        setChangedData({...changedData, tax_rate_id: value});
                    }} 
                    options={taxRates}
                    value={data.tax_rate_id ?? ''} 
                    width='medium'
                />          
                
                <BTDNumberField
                    error={errors && errors.capacity ? errors.capacity : null} 
                    label="Capacity" 
                    onChange={(value) => {
                        setData({...data, capacity: value});
                        setChangedData({...changedData, capacity: value});
                    }} 
                    value={data.capacity ?? ''} 
                    width='medium'
                />
            </div>
            
            <BTDRadioField
                error={errors.status ?? ''}
                label='Status'
                onChange={(value) => {
                    setData({...data, status: value});
                    setChangedData({...changedData, status: value});
                }}
                options={[
                    {value: 'disabled', label: 'Disabled', colour: 'red'},
                    {value: 'enabled', label: 'Enabled', colour: 'green'},
                    {value: 'scheduled', label: 'Scheduled', colour: 'orange'},
                ]}
                value={data.status ?? 'disabled'}
            />
            
            {data.status === 'scheduled' &&

                <div className='form__grid-2'>

                    <BTDDatepicker
                        error={errors && errors.start_timestamp ? errors.start_timestamp : null} 
                        label="Release Date/Time"
                        value={data.start_timestamp ? dayjs(data.start_timestamp) : null}
                        onChange={(value) => {
                            setData({...data, start_timestamp: value});
                            setChangedData({...changedData, start_timestamp: value});
                        }}

                    />
                    <BTDDatepicker
                        error={errors && errors.end_timestamp ? errors.end_timestamp : null} 
                        label="Withdraw Date/Time"
                        value={data.end_timestamp ? dayjs(data.end_timestamp) : null}
                        onChange={(value) => {
                            setData({...data, end_timestamp: value});
                            setChangedData({...changedData, end_timestamp: value});
                        }}
                    />

                </div>
            }


            <BTDButton
                formButton={true}
                label={type === 'create' ? 'Save ticket type' : 'Update ticket type'}
                onClick={submit} 
            />

        </FormTemplate>

    )

}

TicketTypeForm.propTypes = {
	errors: PropTypes.object,
	event: PropTypes.object, 
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    ticketType: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.string
}

TicketTypeForm.defaultProps = {
    errors: {},
    event: null,
	onClose: () => {alert('onClose is not set')},
	onSubmit: () => {alert('onSubmit is not set')},
    ticketType: {},
    type: 'create'
}

export default TicketTypeForm;