// Packages
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import SearchIcon from 'components/BTDUI/icons/SearchIcon';

// Other
import './styles.css';

const BTDAutoCompleteField = ({ description, error, label, onChange, options, required, style, value, width }) => {

    const [active, setActive] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [displayValue, setDisplayValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hasInputBeenFocused, setHasInputBeenFocused] = useState(false);
    const [noOptions, setNoOptions] = useState(false);

    const inputRef = useRef(null);
    const elementRef = useRef(null);

    useEffect(() => {

        if( value !== null && value !== '' ) {

            for( let i = 0; i < options.length; i++ ) {

                if( options[i].id === value ) {

                    setDisplayValue(options[i].name);
                }
            }
        }

    }, [options, value])

    const filteredOptions = options.filter((option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    useEffect(() => {

        if( filteredOptions.length === 0 ) {
            setNoOptions(true);
        }
        else {
            setNoOptions(false);
        }
        
    }, [filteredOptions])

    useEffect(() => {

        if( displayValue !== '') {

            setActive(true);
        }
        else {

            setActive(false);
        }
    }, [displayValue])

    useEffect(() => {

        if( inputRef && isDropdownOpen && ! hasInputBeenFocused) {

            inputRef.current.focus();
            setHasInputBeenFocused(true);
        }

        const handleClickOutside = (event) => {

            if (elementRef.current && ! elementRef.current.contains(event.target)) {

            setIsDropdownOpen(false);
        }
        };
    
        if ( isDropdownOpen ) {
        
        window.addEventListener('click', handleClickOutside);
        }
    
        return () => {
        window.removeEventListener('click', handleClickOutside);
        };
    // eslint-disable-next-line
    }, [isDropdownOpen])


    return(
        <>
        {description &&
            <p className='BTDAutoCompleteField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDAutoCompleteField ${width} `} ref={elementRef} style={style}>
            <label className={`BTDAutoCompleteField__label ${active ? 'active' : ''}`}>{label ?? ''}
                {required &&
                    <span className='red'> *</span>
                }
            </label>
            <div 
                className='BTDAutoCompleteField__selectInput' 
                onClick={() => {
                    setIsDropdownOpen(! isDropdownOpen);
                    if ( ! isDropdownOpen ) {
                        setHasInputBeenFocused(false); 
                    }
                }}
            >
                <span className='BTDAutoCompleteField__selectInput__value'>{displayValue}</span>
                <span className={`BTDAutoCompleteField__downArrow ${isDropdownOpen ? 'active' : ''}`}></span>
            </div>
            {isDropdownOpen && 

                <div className="BTDAutoCompleteField__dropdown">
                    <SearchIcon colour='#bbb' style={{left: '40px', position: 'absolute', top: '40px'}}/>
                    <input 
                        className={`BTDAutoCompleteField__input ${error ?? ''} ${noOptions ? 'noOptions' : ''}`} 
                        onChange={(e) => {setInputValue(e.target.value)}}
                        ref={inputRef}
                        required={required} 
                        value={inputValue ?? ''}
                    />
                    <ul className="BTDAutoCompleteField__options">
                        {filteredOptions.map((option, index) => (
                            <li
                                className="BTDAutoCompleteField__options__option"
                                key={index} 
                                onMouseDown={() => {
                                    setInputValue('');
                                    setIsDropdownOpen(false);
                                    onChange(option.id)
                                }} 
                            >
                                {option.name}
                            </li>
                        ))}
                    </ul>
                </div>
            }

            {error &&
                <p className='BTDAutoCompleteField__error'>{error}</p>
            }
        </div>
        </>
    )
}

BTDAutoCompleteField.propTypes = {
    description: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.string
}

BTDAutoCompleteField.defaultProps = {
    description: null,
    error: null,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    options: [],
    required: false,
    style: {},
    value: '',
    width: 'large'
}

export default BTDAutoCompleteField;                                         